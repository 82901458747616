import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

const DEMO_SEARCH_RESPONSE = {
  "uid": "600ee38b83844ba796768380de8d0a2b",
  "chipnumber": "1234567890",
  "created": "2020-09-05T01:26:56.703Z",
  "lastupdated": "2020-09-05T01:26:56.703Z",
  "count": 48,
  "remote": 45,
  "found": 3,
  "started": "2020-09-05T01:26:56.703Z",
  "ended": "2020-09-05T01:27:11.817Z",
  "duration": 15114,
  "continent": {
    "landmass": "https://cdn.chipndoodle.com/continents/unkown.png"
  },
  "country": {
    "flag": "https://cdn.chipndoodle.com/flags/unkown.png"
  },
  "region": {},
  "city": {},
  "source": null,
  "medium": "api",
  "contactable": false,
  "responses": [
    '3 (in this case) results will be shown here, see next code block for details'
  ],
  "contacts": []
};

const DEMO_SEARCH_ITEM_RESPONSE = {
  "uid": "460bbb0c694c45df916458ecc41db4cb",
  "chipnumber": "1234567890",
  "flagged": true,
  "message": null,
  "label": null,
  "species": null,
  "breed": null,
  "image": "https://api-pkuiatoimq-ew.a.run.app/preview?response=a2e4be1d0acf47a9914fe79fde8cada1&search=1116050c249843da8466d2b3f345d43a",
  "dob": "05-09-2020",
  "name": null,
  "email": null,
  "tel": null,
  "cel": null,
  "fax": null,
  "description": null,
  "created": "2020-09-05T01:27:11.873Z",
  "lastupdated": "2020-09-05T01:27:11.873Z",
  "database": {
    "uid": "3f5a8762bb6f4f2b8e3b98cf33ae59c1",
    "name": "Petlog",
    "slug": "petlog",
    "flag": null,
    "phonenumber": "+441296737600",
    "email": "info@petlog.org.uk",
    "website": "https://www.petlog.org.uk/",
    "summary": null,
    "found": 23,
    "members": 1,
    "records": 0,
    "lat": 51.5061559,
    "lng": -0.1440288,
    "continent": {
      "name": "Europe",
      "code": "eu"
    },
    "country": {
      "name": "United Kingdom",
      "code": "gb",
      "flag": "https://cdn.chipndoodle.com/flags/gb.png"
    },
    "region": {
      "name": "England",
      "code": "england"
    },
    "city": {
      "name": null,
      "code": null
    },
    "created": "2020-06-19T14:39:45.925Z",
    "lastupdated": "2020-06-19T14:39:51.940Z",
    "logo": "https://uploads.chipndoodle.com/2020/06/19/14/6e3175523a2042d6ab98cf0e45a33041/preview.png"
  }
};

const DEMO_PROVIDERS_LIST = [
  {
  "uid": "8452421c8bec423699303124d6d44062",
  "name": "24PetWatch",
  "slug": "24petwatch",
  "flag": null,
  "phonenumber": "+18663757387",
  "email": "info@24petwatch.com",
  "website": "https://www.24petwatch.com/",
  "summary": null,
  "found": 20,
  "members": 1,
  "records": 0,
  "lat": 43.4436067,
  "lng": -79.6978281,
  "continent": {
  "name": "North America",
  "code": "na"
  },
  "country": {
  "name": "Canada",
  "code": "ca",
  "flag": "https://cdn.chipndoodle.com/flags/ca.png"
  },
  "region": {
  "name": "Ontario",
  "code": "on"
  },
  "city": {
  "name": "Oakville",
  "code": "oakville"
  },
  "created": "2020-06-18T08:46:33.564Z",
  "lastupdated": "2020-06-18T08:46:52.225Z",
  "logo": "https://uploads.chipndoodle.com/2020/06/18/08/c708a201da5f4f32b9481fd46806e130/preview.png"
  }
];

const DEMO_UPLOADS_RESPONSE = {
  "uid": "0a3af54462754d0ebbf298c25bbf62b7",
  "chipnumbers": [],
  "url": "https://uploads.chipndoodle.com/2020/09/08/07/0a3af54462754d0ebbf298c25bbf62b7/preview.png",
  "thumbnail": "https://uploads.chipndoodle.com/2020/09/08/07/0a3af54462754d0ebbf298c25bbf62b7/thumbnail.png",
  "animals": [
    {
      "uid": "e9ff6a608982430594967a1f07c57ba4",
      "species": "cat",
      "breeds": [
        {
          "label": "tiger",
          "prob": 0.6682132482528687
        },
        {
          "label": "torbie",
          "prob": 0.10942858457565308
        },
        {
          "label": "domestic_medium_hair",
          "prob": 0.09714129567146301
        },
        {
          "label": "domestic_short_hair",
          "prob": 0.09595170617103577
        },
        {
          "label": "manx",
          "prob": 0.09479674696922302
        }
      ],
      "sex": "male",
      "age": 124,
      "image": "https://uploads.chipndoodle.com/2020/09/08/07/0a3af54462754d0ebbf298c25bbf62b7/e9ff6a608982430594967a1f07c57ba4.png"
    }
  ]
};

class View extends Component {
  constructor(params) {
    super(params);
    let parampage = (params.match.params || {}).page || null;
    this.state = {

      parampage:        parampage,
      locality:         params.locality,
      user:             params.user,
      page:             parampage || 'home'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = () => {
  }
  renderMenuItem = (opts) => {
    return (<Link className={
      [
        
        "details-box-sidebar-menuitem",
        this.state.page === opts.page ? ' active' : ''

      ].join(' ')
    } onClick={() => {
      this.props.history.push('/docs/' + (opts.path || opts.page));
      this.setState({
        page: opts.page
      })
    }} to={"/docs/" + (opts.path || opts.page)}>
      <span className={opts.icon}></span> {opts.label}
    </Link>);
  }
  renderMenuPage = () => {
    return (<div className="details-box-sidebar-menu">
      {this.renderMenuItem({
        label:  'Home',
        icon:   'fa fa-code',
        page:   'home',
        path:   ''
      })}
      {this.renderMenuItem({
        label:  'Authentication & Tokens',
        icon:   'fa fa-unlock-alt',
        page:   'tokens',
      })}
      {this.renderMenuItem({
        label:  "Pet Search API",
        icon:   'fa fa-search',
        page:   'searches',
      })}
      {this.renderMenuItem({
        label:  "Provider API",
        icon:   'fas fa-stream',
        page:   'providers',
      })}
      {this.renderMenuItem({
        label:  'Animal Image API',
        icon:   'fa fa-paw',
        page:   'uploads',
      })}
    </div>);
  }
  renderContentPage = () => {
    if(this.state.page === 'home') {
      document.title = 'Developer Docs';
      return (<div>
        <div className="docs-content">
          <h1 className="docs-heading">The ChipnDoodle Platform</h1>
          <p className="docs-text">Chipndoodle was built to solve the biggest problem related to microchip management and searching of pets microchip numbers to retrieve for safe return home/identification. </p>
          <p className="docs-text"></p>
          <h2 className="docs-subheading">Features</h2>
          <p className="docs-text">The platform offers various capabilities that can be used using our API:</p>
          <div className="docs-apis">

            <div className="docs-api">
              <Link onClick={() => {
                this.props.history.push('/docs/searches');
                this.setState({
                  page: 'searches'
                })
              }} to="/docs/searches" className="docs-api-inner">
                <h3>Pet Search API</h3>
                <p>Search our entire network of databases for a microchip.</p>
              </Link>
            </div>
            <div className="docs-api">
              <Link onClick={() => {
                this.props.history.push('/docs/providers');
                this.setState({
                  page: 'providers'
                })
              }} to="/docs/providers" className="docs-api-inner">
                <h3>Providers API</h3>
                <p>Search and pull an ever growing catalog of databases to get group/organization details who are managing these records.</p>
              </Link>
            </div>
            <div className="docs-api">
              <Link onClick={() => {
                this.props.history.push('/docs/uploads');
                this.setState({
                  page: 'uploads'
                })
              }} to="/docs/uploads" className="docs-api-inner">
                <h3>Animal Image API</h3>
                <p>Make use of our advance models built over the years and upload your images of pets/animals. We will return various features based on the image including sex, age, species and breed of each pet found. Currently supporting up to 600 breeds.</p>
              </Link>
            </div>
            <div className="clearfix"></div>

          </div>
          <h2 className="docs-subheading">First Contact</h2>
          <p className="docs-text">Run the following command to get the default response from our API showing the support email and homepage:</p>
        </div>
        <div className="docs-codeblock">{[

          'curl https://api.chipndoodle.com'

        ].join('\n')}</div>
        <div className="docs-content">
          <h2 className="docs-subheading">Getting Started</h2>
          <p className="docs-text">Once you've made first contact the next step is to register an user account, register your app and start developing with the given the API token.</p>

          <table className="docs-table">
            <tbody>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#1</td>
                <td><Link to="/register">Register</Link> or <Link to="/login">Login</Link> with your user.</td>
              </tr>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#2</td>
                <td><Link to="/apps">Register your app</Link> with the platform providing us with a few details such as name/description and a cheeky logo.</td>
              </tr>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#3</td>
                <td><Link to="/apps">Copy the token</Link> from your app page and get started with our APIs by reading how to <Link to="/docs/tokens">authenticate using your tokens</Link></td>
              </tr>
            </tbody>
          </table>

          <br />
          <p className="docs-text">See the sidebar for details on using your required specific functionality.</p>
          <h2 className="docs-subheading">Need help?</h2>
          <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
        </div>
      </div>);
    }
    if(this.state.page === 'tokens') {
      document.title = 'API Tokens';
      return (<div>
        <div className="docs-content">
          <h1 className="docs-heading">Authentication & Tokens</h1>
          <p className="docs-text">ChipnDoodle needs any clients to authenticate using tokens simply because of the data we handle. This allows us to control who is accessing the data and contact you in case something comes up.</p>
          <h2 className="docs-subheading">How to get a token?</h2>

          <p className="docs-text">Once you've made first contact the next step is to register an user account, register your app and start developing with the given the API token.</p>

          <table className="docs-table">
            <tbody>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#1</td>
                <td><Link to="/register">Register</Link> or <Link to="/login">Login</Link> with your user.</td>
              </tr>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#2</td>
                <td><Link to="/apps">Register your app</Link> with the platform providing us with a few details such as name/description and a cheeky logo.</td>
              </tr>
              <tr>
                <td style={{
                  width: '5%',
                  textAlign: 'center'
                }}>#3</td>
                <td><Link to="/apps">Copy the token</Link> from your app page and get started with our APIs.</td>
              </tr>
            </tbody>
          </table>

          <h2 className="docs-subheading">Authenticating</h2>
          <p className="docs-text">To authenticate you can supply the token in any one of these ways:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>For GET requests you can add a <code>{'?token=<your token>'}</code> to urls</td>
              </tr>
              <tr>
                <td>For POST requests you can add a <code>{'token'}</code> property in your requests</td>
              </tr>
              <tr>
                <td>For GET/POST requests you can add a <code>{'chipndoodle-token'}</code> to your headers</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-subheading">Verify your token</h2>
          <p className="docs-text">The <code>{'/me?token=<your token>'}</code> endpoint allow you to quickly request and check which app the token corresponds to (along with checking if it's working):</p>
        </div>
        <div className="docs-codeblock">{[

          'curl https://api.chipndoodle.com/me?token=<your token>'

        ].join('\n')}</div>
        <div className="docs-content">
          <p className="docs-text">The response would look something like this:</p>
        </div>
        <div className="docs-codeblock">{JSON.stringify({

          token:    {},
          app:      {},
          session:  {},

        }, null, 1)}</div>
        <div className="docs-content">
          <p className="docs-text">Which will return the following:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>token</td>
                <td>The token this key is linked to</td>
              </tr>
              <tr>
                <td>app</td>
                <td>The app this token is linked to</td>
              </tr>
              <tr>
                <td>session</td>
                <td>Your current session with location information</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p className="docs-text">If the endpoint returns anything else than <code>200</code> or <code>201</code> consider it as an invalid request or token.</p>
          <h2 className="docs-subheading">Need help?</h2>
          <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
        </div>
      </div>);
    }
    if(this.state.page === 'searches') {
      document.title = 'Integrating the searching';
      return (<div>
        <div className="docs-content">
          <h2 className="docs-heading">Pet Search API</h2>
          <p className="docs-text">The search API makes up one of the main purposes of the platform. Using this API you can perform the same searches globally that we do from the homepage of <Link to="/">chipndoodle.com</Link>; the homepage actually uses exactly the same API.</p>
          <br />
          <p className="docs-text">The idea is simple, you provide us with a FDX-A (10 characters) or FDX-B (15 characters) unique microchip code. We take this number and search all registered providers' databases for a response, some locally managed on Chipndoodle and some remotely hosted.</p>
          <h2 className="docs-heading">Limits to the API</h2>
          <p className="docs-text">The Search API imposes a few restrictions to keep our customers data safe:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>App tokens will only allow <code>100</code> searches every <code>5 minutes</code></td>
              </tr>
              <tr>
                <td>Cloudflare protects our API and site from DDOS attacks. You might be asked to perform a CAPTCHA if your app starts behaving oddly.</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">Making a Request</h2>
          <p className="docs-text">To call the API you will need a token and perform a <code>POST</code> to the following url:</p>
        </div>
        <div className="docs-codeblock">{[

          'https://api.chipndoodle.com/searches'

        ].join('\n')}</div>
        <div className="docs-content">
          <p className="docs-text">With the following body in either <code>JSON</code> or <code>FORM</code>:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>token</td>
                <td>(required) Valid token registered with a app</td>
              </tr>
              <tr>
                <td>uid</td>
                <td>(required) 10 or 15 character unique microchip code</td>
              </tr>
              <tr>
                <td>useragent</td>
                <td>(optional) When your client is not directly calling the API you can pass their user agent in the request for better tracking on our side. Not required but highly recommended.</td>
              </tr>
              <tr>
                <td>remoteip</td>
                <td>(optional) When your client is not directly calling the API you can pass their remote ip which allows us to coarsely show which city the search was from for owners to view.</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">The Response</h2>
          <p className="docs-text">The API will take about 9-15s (depending on the speed of external partners) and return all succesfull lookups from our providers. This means that if your microchip code was found in one or more of the providers these results will then contain various information that was available at the time on the animal/owner.</p>
          <h2 className="docs-heading">Sample Usage</h2>
        </div>
        <pre className="docs-codeblock">{[

          "curl --request POST --url https://api.chipndoodle.com/searches \\",
          "--data token=<token-here> \\",
          "--data uid=1234567890",

        ].join('\n')}</pre>
        <div className="docs-content">
          <p className="docs-text">The Response from the API will be something like the following:</p>
        </div>
        <pre className="docs-codeblock">{[

          JSON.stringify(DEMO_SEARCH_RESPONSE, null, 2)

        ].join('\n')}</pre>
        <div className="docs-content">
          <p className="docs-text">The <code>responses</code> property will be filled with objects like the following for each succesfull lookup:</p>
        </div>
        <pre className="docs-codeblock">{[

            JSON.stringify(DEMO_SEARCH_ITEM_RESPONSE, null, 2)

          ].join('\n')}</pre>
          <div className="docs-content">
            <h2 className="docs-subheading">Need help?</h2>
            <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
          </div>
      </div>);
    }
    if(this.state.page === 'providers') {
      document.title = 'Providers';
      return (<div>
        <div className="docs-content">
          <h1 className="docs-heading">Providers</h1>
          <p className="docs-text">Chipndoodle maintains a list of partners/databases over the world. These are either totally integrated, managing their records on ChipnDoodle or we just check them when searching. For example on our website we show all the providers on our <Link to="/providers">Providers</Link> page.</p>
          <br />
          <p className="docs-text">Exposing this in an API allows apps to quickly pull a list of providers for a given continent/country and use as they see fit.</p>
          <br />
          <p className="docs-text">Some details returned include:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>name</td>
                <td>Full name of the provider</td>
              </tr>
              <tr>
                <td>logo</td>
                <td>Easy to display provider logo</td>
              </tr>
              <tr>
                <td>email</td>
                <td>E-Mail to contact their support</td>
              </tr>
              <tr>
                <td>phonenumber</td>
                <td>Local phonenumber they can be reached at in ISO format.</td>
              </tr>
              <tr>
                <td>website</td>
                <td>Full URL link to their public website</td>
              </tr>
              <tr>
                <td>city/country/continent</td>
                <td>Information on which service areas and countries they operate in</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">Making a Request</h2>
          <p className="docs-text">To call the API you will need a token and perform a <code>GET</code> to the following url:</p>
        </div>
        <div className="docs-codeblock">{[

          'https://api.chipndoodle.com/providers'

        ].join('\n')}</div>
        <div className="docs-content">
          <p className="docs-text">The following filtering options can be given in the querystring (these are all optional):</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>continent</td>
                <td>(optional) filter providers by any ISO coded 2 character continent (example AF for Africa). See the <a href="https://en.wikipedia.org/wiki/List_of_sovereign_states_and_dependent_territories_by_continent_(data_file)">List of sovereign states and dependent territories by continent (data file)</a>. Just take note some of the continents will not have any providers, this just means none are registered yet for that continent. For example <code>api.chipndoodle.com/providers?continent=OC</code> would return all providers in that continent.</td>
              </tr>
              <tr>
                <td>country</td>
                <td>(optional) filter by the country ISO coded 2 characters (example AU for Australia). See the <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2">ISO 3166-1 alpha-2</a>. Just take note some of the countries will not have any providers, this just means none are registered yet for that country.</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">The Response</h2>
          <p className="docs-text">The API will return then (quite quickly) a list of providers which will be limited to max of 100.</p>
        </div>
        <pre className="docs-codeblock">{[

          "curl --url https://api.chipndoodle.com/uploads",

        ].join('\n')}</pre>
        <div className="docs-content">
          <p className="docs-text">The Response from the API will be something like the following:</p>
        </div>
        <pre className="docs-codeblock">{[

            JSON.stringify(DEMO_PROVIDERS_LIST, null, 2)

          ].join('\n')}</pre>
          <div className="docs-content">
            <h2 className="docs-subheading">Need help?</h2>
            <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
          </div>
      </div>);
    }
    if(this.state.page === 'uploads') {
      document.title = 'Animal Image API';
      return (<div>
        <div className="docs-content">
          <h1 className="docs-heading">Animal Image API</h1>
          <p className="docs-text">Using large datasets gathered over years ChipnDoodle was able to produce various machine learning models which are able to identify various characteristics of animals in photos. The idea is simple, you provide a image; we provide details on all the animals (plus where) that are in the photo with guesses on some aspects.</p>
          <br />
          <p className="docs-text">We currently support the following features:</p>
          <br />
          <p className="docs-text">Some details returned include:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>Amount of animals in the image + a cropped image of each animal</td>
              </tr>
              <tr>
                <td>Prediction of the species of the animal (<code>dog</code>/<code>cat</code>/<code>monkey</code>/<code>cow</code>/<code>snake</code>/<code>hamster</code>). When not totally sure we return <code>unknown</code></td>
              </tr>
              <tr>
                <td>Guesses of the breed for dogs (307 breeds supported) and cats (88 breeds supported) by returning the top 5 breed predictions with probability.</td>
              </tr>
              <tr>
                <td>Prediction of the sex of the animal (<code>male</code>/<code>female</code>)</td>
              </tr>
              <tr>
                <td>Predicted age of the animal in days</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">Supported Image Files</h2>
          <p className="docs-text">The API will accept any of the following formats <code>jpeg/jpg/png/gif</code>. Your image will need to be in one of the those formats and be no bigger than <code>40 MB</code>.</p>
          <h2 className="docs-heading">Making a Request</h2>
          <p className="docs-text">To call the API you will need a token + the image you want to process and perform a <code>POST</code> to the following url:</p>
        </div>
        <div className="docs-codeblock">{[

          "curl -F token=<your-token> -F file=@myimage.jpg https://api.chipndoodle.com/uploads"

        ].join('\n')}</div>
        <div className="docs-content">
          <p className="docs-text">The endpoint accepts the following parameters:</p>
          <table className="docs-table">
            <tbody>
              <tr>
                <td>file</td>
                <td>(required) The file you want to have processed. This should be sent in a <code>multipart/form-data</code>. For an example see <a href="https://ec.haxx.se/http/http-multipart">Multipart formposts</a> for an example.</td>
              </tr>
              <tr>
                <td>token</td>
                <td>(required) Your app token that was generated</td>
              </tr>
              <tr>
                <td>reason</td>
                <td>(optional) just for record, some text on why you are requesting this process. For example "my app animal upload"</td>
              </tr>
            </tbody>
          </table>
          <h2 className="docs-heading">The Response</h2>
          <p className="docs-text">The API will return a response like the following; take note when the API has not been called for a while processing might take a few seconds more on that first request:</p>
        </div>
        <pre className="docs-codeblock">{[

          "curl --url https://api.chipndoodle.com/providers",

        ].join('\n')}</pre>
        <div className="docs-content">
          <p className="docs-text">An example when we upload the following image:</p>
          <img className="docs-image-full" src="https://uploads.chipndoodle.com/2020/09/08/07/0a3af54462754d0ebbf298c25bbf62b7/preview.png" />
          <p className="docs-text">The Response from the API will be something like the following:</p>
        </div>
        <pre className="docs-codeblock">{[

            JSON.stringify(DEMO_UPLOADS_RESPONSE, null, 2)

          ].join('\n')}</pre>
        <div className="docs-content">
          <h2 className="docs-subheading">Need help?</h2>
          <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
        </div>
      </div>);
    }
    return (<div>
      <div className="docs-content">
        <h1 className="docs-heading">We have a problem ...</h1>
        <p className="docs-text">No such document page page</p>
          <h2 className="docs-subheading">Need help?</h2>
          <p className="docs-text">If you have any questions, please contact <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>
      </div>
    </div>);
  }
  render() {
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="database">
            <div className="hero-box">
              <h2 className="hero-heading">Developer Docs</h2>
              <h2 className="hero-slogan">Integrating to ChipnDoodle</h2>
            </div>
            <div className="container">
              <div className="profile-inner">

                <div className="details-box">
                  <div className="details-box-sidebar">
                    {this.renderMenuPage()}
                    <div className="clearfix"></div>
                  </div>
                  <div className="details-box-meta">
                    {this.renderContentPage()}
                    <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;