import React, { Component } from 'react';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';

import RecordPageOverview from './overview';
import RecordPageCertificates from './certificates';
import RecordPageSearches from './searches';
import RecordPageHistory from './history';
import RecordPageGallery from './gallery';
import RecordPageDead from './dead';
import RecordPageFlag from './flag';
import moment from 'moment';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item: null,
      group: null,
      locality: params.locality,
      user: params.user,
      recorduid: (params.match.params || {}).recorduid || null,
      groupuid: (params.match.params || {}).groupuid || null,
      section: 'loading',
      method: 'upload',
      species: [],
      upload: null,
      page: params.selectedSection || 'overview'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCopyPaste = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // the tasks
    let tasks = [];

    // push it
    tasks.push(APIClient.call({

      path: '/groups/' + this.state.groupuid,
      query: {
        token
      }

    }));

    // push it
    tasks.push(APIClient.call({

      path: '/species',
      query: {
        token
      }

    }));

    // set the call
    let results = [];

    // handle errors
    try {
      results = await Promise.all(tasks);
    } catch (err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    // let group item
    let groupresult = results[0];
    let speciesitems = results[1];

    if (groupresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    if (speciesitems.ok !== true) {
      this.setState({
        section: 'error'
      });
      return;
    }

    // set the groups
    document.title = groupresult.data.name;

    // the items
    let speciesoptions = [];

    // load species
    for (let i = 0; i < speciesitems.data.length; i++) {
      let speciesitem = speciesitems.data[i];
      speciesoptions.push({

        value: speciesitem.slug,
        label: speciesitem.name,

      });
    }

    ///// get the record details

    // set the call
    let importresult = null;

    // handle errors
    try {
      importresult = await APIClient.call({

        path: '/groups/' + this.state.groupuid + '/records/' + this.state.recorduid,
        query: {
          token
        }

      });
    } catch (err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if (importresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the import
    this.setState({

      section: 'view',
      group: groupresult.data,
      item: importresult.data,
      speciesoptions: speciesoptions,

    });

    // every 3 seconds, update
    // setTimeout(this.fetchImportUpdate, 1000 * 3);

  }
  fetchImportUpdate = async () => {

    // get the token
    let token = APIClient.getToken();

    // set the call
    let importresult = null;

    // handle errors
    try {
      importresult = await APIClient.call({

        path: '/groups/' + this.state.groupuid + '/imports/' + this.state.importuid,
        query: {
          token
        }

      });
    } catch (err) {
      return setTimeout(this.fetchImportUpdate, 1000 * 3);
    }

    if (importresult.ok !== true) {
      return setTimeout(this.fetchImportUpdate, 1000 * 3);
    }

    // set the import
    this.setState({

      item: importresult.data

    }, () => {

      return setTimeout(this.fetchImportUpdate, 1000 * 3);

    });

  }
  changeURLBar = (url) => {

    window.history.pushState(url, document.title, url)

  }
  renderSectionPage = () => {
    if (this.state.page === 'certificates') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/certificates');
      return (<RecordPageCertificates
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    if (this.state.page === 'flag') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/flag');
      return (<RecordPageFlag
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    if (this.state.page === 'gallery') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/gallery');
      return (<RecordPageGallery
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    if (this.state.page === 'dead') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/dead');
      return (<RecordPageDead
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    if (this.state.page === 'searches') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/searches');
      return (<RecordPageSearches
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    if (this.state.page === 'history') {
      this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/history');
      return (<RecordPageHistory
        group={this.state.group}
        record={this.state.item}
        locality={this.state.locality}
        user={this.state.user}
      />);
    }
    this.changeURLBar('/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '');
    return (<RecordPageOverview
      species={this.state.species}
      group={this.state.group}
      record={this.state.item}
      locality={this.state.locality}
      user={this.state.user}
    />);
  }
  render() {
    if (this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if (this.state.section === 'error') {
      return (<div className="wrapper">
        <div className="grouprecord">
          <div className="container">
            <div className="grouprecord-inner">
              <p>Something went wrong</p>
            </div>
          </div>
        </div>
      </div>);
    } else if (this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality} user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          <div className="grouprecord">
            <div className="container">
              <div className="grouprecord-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let banner = null;
    if (this.state.errorMessage) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    let recordFlagBanner = [];
    if (this.state.item.flagged === true) {
      recordFlagBanner.push(<div>
        <p>Comin soon...</p>
      </div>)
    }
    if (this.state.item.death) {
      recordFlagBanner.push(<div>
        <p>Coming soon...</p>
      </div>)
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          {banner}
          <div className="grouprecord">

            <div className="hero-box">
              <h2 className="hero-heading">{this.state.item.label || this.state.item.nickname || 'No Name'} (#{this.state.item.uid})</h2>
              <h2 className="hero-slogan">Created: {moment.utc(this.state.item.created).local().format('LLL')}</h2>
            </div>
            <div className="container">
              <div className="grouprecord-inner">

                <div className="details-box">
                  <div className="details-box-meta details-box-meta-full">

                    <div className="grouprecord-hero">
                      <div className="grouprecord-hero-inner">

                        <div className="grouprecord-hero-actions">
                          {

                            !this.state.item.death
                              ?
                              <a className={[

                                "grouprecord-hero-action",
                                this.state.page === 'dead' ? 'active' : ''

                              ].join(' ')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                                e.preventDefault();
                                this.setState({

                                  page: 'dead'

                                });
                              }}>
                                <span className="fas fa-skull-crossbones"></span> Mark as Dead
                              </a>
                              :
                              null

                          }
                          <a className={[

                            "grouprecord-hero-action",
                            this.state.page === 'flag' ? 'active' : ''

                          ].join(' ')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                            e.preventDefault();
                            this.setState({

                              page: 'flag'

                            });
                          }}>
                            <span className="fas fa-exclamation-triangle"></span> Flag the Pet
                          </a>
                          <div className="clearfix"></div>
                        </div>

                      </div>
                    </div>

                    <div className="grouprecord-actions">

                      <a className={"grouprecord-action" + (this.state.page === 'overview' ? ' active' : '')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          page: 'overview'
                        })
                      }}><span className="fas fa-paw"></span> Info</a>
                      {/* <a  className={"grouprecord-action" + (this.state.page === 'certificates' ? ' active' : '')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          page: 'certificates'
                        })
                      }}><span className="fas fa-certificate"></span> Certificates</a> */}
                      <a className={"grouprecord-action" + (this.state.page === 'gallery' ? ' active' : '')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          page: 'gallery'
                        })
                      }}><span className="fas fa-images"></span> Gallery</a>
                      <a className={"grouprecord-action" + (this.state.page === 'searches' ? ' active' : '')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          page: 'searches'
                        })
                      }}><span className="fas fa-search"></span> Searches</a>
                      <a className={"grouprecord-action" + (this.state.page === 'history' ? ' active' : '')} href={"/groups/" + this.state.group.slug + '/records/' + this.state.item.uid} onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          page: 'history'
                        })
                      }}><span className="fas fa-history"></span> History</a>
                      <div className="clearfix"></div>

                    </div>

                    {this.renderSectionPage()}

                  </div>
                  <div className="clearfix"></div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;