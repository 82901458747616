import React, { Component } from 'react';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      animaluid:        (params.match.params || {}).animaluid || null,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the animals
    document.title = 'Animal';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/animals/' + this.state.animaluid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the animals
    document.title = '#' + result.data;

    this.setState({

      section:      'view',
      item:         result.data

    });

  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="animal">
                  <div className="container">
                    <div className="animal-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="animal">
            <div className="container">
              <div className="animal-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  section="me" user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="animal">
            <div className="animal-hero">
              <div className="container">
                <div className="animal-inner">
                  <p>Animal Here</p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="animal-inner">

                <h1 style={{
                  fontSize: 28
                }}>{this.state.item.name}</h1>
                <p>Species: {this.state.item.species}</p>
                <p>Breed: {this.state.item.breed}</p>
                <p>Nickname: {this.state.item.nickname}</p>
                <p>DOB: {this.state.item.dob}</p>

                <img alt="Animal Preview" src={this.state.item.image} />

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;