import React, { Component } from 'react';
import './style.css';
import Select from 'react-select';
import CONSTANTS from '../../constants';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import moment from 'moment';

const CHIP_FORMAT_NUMBER  = 5;

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: 44, borderRadius: 1, cursor: 'pointer' }),
  option: styles => ({ ...styles, cursor: 'pointer' }),
};

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      group:        params.group,
      item:         params.record,
      user:         params.user,
      locality:     params.locality,
      ownership:    'group',

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentWillMount = () => {
    document.title = this.state.item.uid;
  }
  formatChipNumber = (text) => {
    if(!text) return '';
    let groups = Math.ceil(text.length / CHIP_FORMAT_NUMBER);
    let groupings = [];
    for(let i = 0 ; i < groups; i++) {
      groupings.push(text.slice(i * CHIP_FORMAT_NUMBER, (i+1) * CHIP_FORMAT_NUMBER));
    }
    return groupings.join('-');
  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.group.slug + '/records/' + this.state.item.uid,
        method:   'post',
        form:     {
  
          token,
          nickname: this.inputNickname.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.setState({
      item: result.data,
      submitting: false
    })    
  }
  render() {
    return (<div className="grouprecord-items-blank">
      <span className="fas fa-hard-hat"></span>
      <p>Under construction!</p>
      <div className="clearfix"></div>
    </div>);
  }
}

export default App;
