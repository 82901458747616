import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import GroupSettingsMenuView from '../../widgets/groupsettingsmenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputURL = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = 'Settings from ' + result.data.name;

    // handle the item
    this.setState({

      section:      'view',
      item:         result.data,
      remoteurl:    result.data.url,
      secret:       result.data.secret,

    });

  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.item.slug,
        method:   'post',
        form:     {
  
          token,
          url: this.inputURL.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    if(this.state.item.slug !== result.data.slug) {
      this.props.history.push('/groups/' + result.data.slug + '/settings');
      return;
    }

    this.setState({
      item: result.data,
      submitting: false
    })    
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupsettings">
                  <div className="container">
                    <div className="groupsettings-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupsettings">
            <div className="container">
              <div className="groupsettings-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item} section="settings"></GroupMenuView>
        <div className="wrapper">
          <div className="groupsettings groupsettings-alt">
            <div className="hero-box">
              <GroupSettingsMenuView user={this.state.user} section="integration" group={this.state.item}></GroupSettingsMenuView>
            </div>
            <div className="container">
              <div className="groupsettings-inner">

                <div className="details-box">
                  <div className="details-box-meta details-box-meta-full">

                    <form onSubmit={this.handleSubmit} className="details-box-controls">

                      <div className="details-box-control">
                        <p>Integrating with us a piece of cake, follow the documents listed at <Link to="/docs/integration">/docs/integration</Link> on how to integrate. Afterwards verify using this form and your details. Once our test passes your database will be live on the website</p>
                      </div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <p>URL to talk to</p>
                          <label htmlFor="nickname">URL</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.item.url} ref={this.inputURL} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">SECRET</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" readOnly={true} defaultValue={this.state.item.secret} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control">
                        <p>We will be testing with the following microchips and expect the following results:</p>
                        <ul>
                          <li>111111111111111 should be found</li>
                          <li>FE1234FE12 should be found</li>
                          <li>00000000000000 should not be found</li>
                        </ul>
                        <p>If all our test cases pass your database will be queried for searches going forward and you can view the results in our dashboard</p>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                    </form>
                    <div className="details-box-toolbar">
                      <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>VERIFY AND SAVE</Link>
                      <p className="details-box-toolbar-text">
                        <a href={'/groups/' + this.state.groupuid + '/integrations'}>DISABLE INTEGRATION</a>
                      </p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;