import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import UserMenuView from '../../widgets/usermenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      items:            [],
      locality:         params.locality,
      user:             params.user,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the animals
    document.title = 'Animals';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = await APIClient.call({

      path:   '/animals',
      query:  {
        token
      }

    });

    this.setState({

      section:      'loaded',
      items:        result.data

    });

  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    }
    let items = this.state.items || [];
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} section="me"  user={this.state.user} state={this.state} />
        <UserMenuView user={this.state.user} section="animals"></UserMenuView>
        <div className="wrapper">
          <div className="animals">
            <div className="container">
              <div className="animals-inner">

                <p>
                  <Link to={"/add"}>Add New Animal</Link>
                </p>
                <p>Your registered animals:</p>
                <div className="animals-listing-items">
                  {
                    items.map((item) => {
                      return <div className="animals-listing-item">
                        <Link className="animals-listing-item-inner" to={"/animals/" + item.uid}>
                          <div className='animals-listing-item-inner-header'></div>
                          <div className="animals-listing-item-preview">
                            <div className="animals-listing-item-image">
                              <img alt={'#' + item.microchip} src={item.image} />
                            </div>
                          </div>
                          <div className="animals-listing-item-meta">
                            <p>{item.uid}</p>
                            <p>{item.uid}</p>
                            <p>{item.uid}</p>
                            <p>{item.uid}</p>
                            <p>{item.uid}</p>
                            <p>{item.uid}</p>
                          </div>
                          <div className="clearfix"></div>
                        </Link>
                      </div>;
                    })
                  }
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;