import React, { Component } from 'react';
import './style.css';
import Select from 'react-select';
import CONSTANTS from '../../constants';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import moment from 'moment';
import S from 'string';

const CHIP_FORMAT_NUMBER = 5;

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: 44, borderRadius: 1, cursor: 'pointer' }),
  option: styles => ({ ...styles, cursor: 'pointer' }),
};

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {

      group: params.group,
      item: params.record,
      breeds: [],
      sex: params.record.sex,
      species: params.record.species || CONSTANTS.SPECIES_DEFAULT,
      defaultbreeditems: [],
      user: params.user,
      locality: params.locality,
      ownership: params.record.ownership,
      section: 'view'

    };
    for (let i = 0; i < (params.record.breeds || []).length; i++) {
      this.state.defaultbreeditems.push({
        value: S(params.record.breeds[i]).slugify().s,
        label: params.record.breeds[i]
      })
      this.state.breeds.push(params.record.breeds[i]);
    }
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputNickname = React.createRef();
    this.inputName = React.createRef();
    this.inputSpecies = React.createRef();
    this.inputBreeds = React.createRef();
    this.inputColor = React.createRef();
    this.inputDOB = React.createRef();
    this.inputDescription = React.createRef();
    this.inputLabel = React.createRef();
    this.inputWebsite = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPhoneNumber = React.createRef();
    this.inputAddress = React.createRef();
  }
  componentDidMount = () => {
    document.title = this.state.item.uid;
  }
  handleBreedChange = async (vals) => {
    if (!vals || !vals.length) return;
    let breedNames = [];
    for (let i = 0; i < vals.length; i++) {
      breedNames.push(vals[i].label)
    }
    this.setState({
      breeds: breedNames
    })
  }
  renderPrivatePerson = () => {
    if (this.state.ownership !== 'person') return;
    return (<div>

      <div className="details-box-control">
        <div className="details-box-control-label">
          <label htmlFor="nickname">Owner Name</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="text" defaultValue={this.state.item.name} ref={this.inputName} placeholder="Name of the owner" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>

      <div className="details-box-control details-box-control-half">
        <div className="details-box-control-label">
          <label htmlFor="nickname">EMail</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="email" defaultValue={this.state.item.email} ref={this.inputEmail} placeholder="E-Mail to contact" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="details-box-control details-box-control-half">
        <div className="details-box-control-label">
          <label htmlFor="nickname">Phonenumber</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="tel" defaultValue={this.state.item.phonenumber} ref={this.inputPhoneNumber} placeholder="Phonenumber to contact is searched" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>

      <div className="clearfix"></div>

      <div className="details-box-control">
        <div className="details-box-control-label">
          <p>We only ever show the city on our searches</p>
          <label htmlFor="nickname">Address</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="text" defaultValue={this.state.item.address} ref={this.inputAddress} placeholder="Where the pet calls home" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>
    </div>);
  }
  formatChipNumber = (text) => {
    if (!text) return '';
    let groups = Math.ceil(text.length / CHIP_FORMAT_NUMBER);
    let groupings = [];
    for (let i = 0; i < groups; i++) {
      groupings.push(text.slice(i * CHIP_FORMAT_NUMBER, (i + 1) * CHIP_FORMAT_NUMBER));
    }
    return groupings.join('-');
  }
  handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting: true,
      section: 'loading',
      submissions: this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path: '/groups/' + this.state.group.slug + '/records/' + this.state.item.uid,
        method: 'post',
        form: {

          token,
          uid: this.state.chipnumber,
          description: this.inputDescription.current.value,
          nickname: this.inputNickname.current.value,
          species: this.state.species,
          breeds: this.state.breeds.join(','),
          color: this.inputColor.current.value,
          dob: this.inputDOB.current.value,
          owner: this.state.ownership,
          sex: this.state.sex,
          name: ((this.inputName || {}).current || {}).value || null,
          email: ((this.inputEmail || {}).current || {}).value || null,
          phonenumber: ((this.inputPhoneNumber || {}).current || {}).value || null,
          address: ((this.inputAddress || {}).current || {}).value || null,

        }

      });
    } catch (err) {
      console.error(err)
      this.setState({
        submitting: false,
        section: 'view',
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if (result.ok === false) {
      this.setState({
        submitting: false,
        errorMessage: result.data.message
      })
      return;
    }

    this.setState({
      item: result.data,
      section: 'view',
      submitting: false
    })
  }
  fetchBreedsByFilter = async (inputValue, callback) => {

    // get the token
    let token = APIClient.getToken();

    let result = null;
    let entries = [];
    try {
      result = await APIClient.call({

        path: '/breeds',
        method: 'get',
        query: {
          q: inputValue,
          token: token,
          species: this.state.species,
        }

      });
    } catch (err) {
      console.error(err);
      this.setState({
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if (result.ok === false) {
      this.setState({
        submitting: false,
        errorMessage: result.data.message
      })
      return;
    }

    // loop and add
    for (let i = 0; i < result.data.length; i++) {
      entries.push({
        label: result.data[i].name,
        value: result.data[i].uid,
      })
    }

    callback(entries);

  }
  render() {
    if (this.state.section === 'loading') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-spinner"></span>
        <p>Updating...</p>
        <div className="clearfix"></div>
      </div>);
    }
    let banner = null;
    if (this.state.errorMessage) {
      banner = (<div className="banner banner-hero banner-hero-grouprecord-overview">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    return (<div>

      {banner}

      <form onSubmit={this.handleSubmit} className="details-box-controls">

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Microchip Number</label>
          </div>
          <div className="details-box-control-inputs">
            <input type="text" readOnly={true} placeholder="10/15 digit number" defaultValue={this.formatChipNumber(this.state.item.uid)} />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Gender of Animal</label>
          </div>
          <div className="details-box-control-inputs">
            <Select options={[
              {
                label: 'Male',
                value: 'male',
              },
              {
                label: 'Female',
                value: 'female',
              }
            ]} onChange={(selectItem) => {
              this.setState({
                sex: selectItem.value
              })
            }} styles={colourStyles} defaultValue={{ label: this.state.sex, value: 0 }} />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        <div className="details-box-control">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Name / Nickname</label>
          </div>
          <div className="details-box-control-inputs">
            <input type="text" defaultValue={this.state.item.nickname || this.state.item.label} ref={this.inputNickname} placeholder="Name of the Animal" />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Species</label>
          </div>
          <div className="details-box-control-inputs">
            <Select options={this.props.species} onChange={(selectItem) => {
              this.setState({
                species: selectItem.value
              })
            }} styles={colourStyles}

              defaultValue={{ label: this.state.species, value: 0 }}
            />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Breed(s)</label>
          </div>
          <div className="details-box-control-inputs">
            <AsyncSelect
              ref={this.inputBreeds}
              cacheOptions isMulti noOptionsMessage={() => 'Start typing to search breeds'}
              loadOptions={this.fetchBreedsByFilter}
              onChange={this.handleBreedChange}
              defaultOptions defaultValue={this.state.defaultbreeditems}
            />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Color </label>
          </div>
          <div className="details-box-control-inputs">
            <input type="text" placeholder="Color of the pet" defaultValue={this.state.item.color} ref={this.inputColor} />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="details-box-control details-box-control-half">
          <div className="details-box-control-label">
            <label htmlFor="nickname">DOB</label>
          </div>
          <div className="details-box-control-inputs">
            <input type="date" placeholder="YYYY-MM-DD" defaultValue={this.state.item.dob ? moment(this.state.item.dob).format('YYYY-MM-DD') : null} ref={this.inputDOB} />
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        <div className="details-box-control">
          <div className="details-box-control-label">
            <label htmlFor="nickname">Description / Comments</label>
          </div>
          <div className="details-box-control-inputs">
            <textarea placeholder="Description of pet or any comments about owner etc" ref={this.inputDescription} defaultValue={this.state.item.description}></textarea>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        <div className="add-details-box-control">
          <div className="add-details-box-control-label">
            <p>Will be shown as main contact person</p>
            <label htmlFor="nickname">Main Contact</label>
          </div>
          <div className="add-details-box-control-inputs">
            <div className="add-details-box-control-selectables">
              <a href={'/groups/' + this.state.item.slug + '/new#main'} onClick={(e) => {
                if (e) e.preventDefault();
                this.setState({
                  ownership: 'group'
                })
              }} className={"add-details-box-control-selectable" + (this.state.ownership === "group" ? " active" : "")}>THIS GROUP</a>
              <a href={'/groups/' + this.state.item.slug + '/new#main'} onClick={(e) => {
                if (e) e.preventDefault();
                this.setState({
                  ownership: 'person'
                })
              }} className={"add-details-box-control-selectable" + (this.state.ownership === "person" ? " active" : "")}>PRIVATE PERSON</a>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="clearfix"></div>

        {this.renderPrivatePerson()}

      </form>

      <div className="details-box-toolbar">
        <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>UPDATE</Link>
        <p className="details-box-toolbar-text"></p>
        <div className="clearfix"></div>
      </div>


    </div>);
  }
}

export default App;
