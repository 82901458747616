import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import S from 'string';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import Constants from '../../constants';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ProviderItem from '../../widgets/provideritem';
import './style.css';

const ProviderMarker = ({ item }) => <span title={item.name} className="fas fa-circle" style={{
  color: '#7F3584',
  // color: 'red',
  fontSize: 18,
  cursor: 'pointer',
  opacity: 1
}}>
</span>;
class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      items: [],
      continents: [],
      loading: false,
      locality: params.locality,
      user: params.user,
      section: 'loading',
      scontinents: [],
      query: ''

    };
    this.handleUserChange = params.handleUserChange;
    this.searchQueryInput = React.createRef();
  }
  fetchProviders = async (continent) => {

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // build up the params
    let queryOpts = {};

    // check for token
    if (token) queryOpts.token = token;

    // set the continents
    if (this.state.continents.length > 0) {
      queryOpts.continents = this.state.scontinents.join(',');
    }

    // add search
    if (S(this.state.query).isEmpty() === false) {
      queryOpts.q = this.state.query;
    }

    // handle errors
    try {
      result = await APIClient.call({

        path: '/providers',
        query: queryOpts

      });
    } catch (err) {
      this.setState({
        loading: false,
        section: 'error'
      });
      return;
    }

    if (result.ok !== true) {
      this.setState({
        loading: false,
        section: 'notfound'
      });
      return;
    }

    this.setState({

      loading: false,
      items: result.data

    });

  }
  componentDidMount = async () => {

    // set the databases
    document.title = 'Databases';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path: '/providers/continents',
        query: {
          token
        }

      });
    } catch (err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if (result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    let continents = result.data || [];

    // 
    let continent = null;

    // the code
    let countrycode = (this.state.locality.continent.code || '').toLowerCase();

    // set the stats
    let totalcount = 0;

    // try to find the local continent of the user first
    for (let i = 0; i < continents.length; i++) {
      let local_item = continents[i];
      if (local_item.code === countrycode) {
        continent = local_item.code;
      }
      totalcount = totalcount + local_item.count;
    }

    // did we find it?
    if (!continent) {
      continent = (continents[0] || {}).code || 'af';
    }

    this.setState({

      section: 'loaded',
      continents: _.sortBy(result.data, 'name'),
      scontinents: [],
      continent: continent,
      count: totalcount

    }, async () => {

      await this.fetchProviders(continent);

    });

  }
  formatDescription = (item) => {
    if (item.website) {
      let text = item.website;
      text = text.toLowerCase();
      text = text.replace(/http:\/\/www\./gi, '');
      text = text.replace(/https:\/\/www\./gi, '');
      text = text.replace(/http:\/\//gi, '');
      text = text.replace(/https:\/\//gi, '');
      text = text.split('/')[0];
      return S(text).truncate(18, '..').s;
    } else {
      return 'since ' + moment(item.created).format('LLL')
    }
  }
  render() {
    if (this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    }
    let items = this.state.items;
    let mapicon = 'https://cdn.chipndoodle.com/continents/all.svg';
    if (this.state.scontinents.length > 0) {
      mapicon = 'https://cdn.chipndoodle.com/continents/' + this.state.scontinents[0] + '.svg';
    }
    return (
      <div>
        <HeaderView {...this.props} section='providers' locality={this.state.locality} user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="databases">
            {/* <div className="databases-hero">
              <div className="container">
                <h2 className="databases-hero-heading">Our Network of Databases</h2>
                <img alt="World map" className="databases-hero-world" src={mapicon} />
                <h2 className="databases-hero-slogan">{this.state.count} databases, one search</h2>
              </div>
            </div> */}

            <div className="databases-map">
              <GoogleMapReact
                bootstrapURLKeys={{

                  key: Constants.GOOGLE_MAPS_API_KEY,
                  libraries: [],

                }}
                defaultCenter={{

                  lat: this.state.mapCenterLat || 0,
                  lng: this.state.mapCenterLng || 0

                }}
                options={{

                  panControl: false,
                  mapTypeControl: true,
                  disableDefaultUI: false,
                  disableZoomControl: false,
                  styles:             Constants.MAP_STYLES,
                  minZoom: 2,
                  maxZoom: 13,
                  backgroundColor: 'none',

                }}
                defaultZoom={2}
                draggable={true}

              >
                {
                  items.map((item) => {
                    return <ProviderMarker key={item.uid} lat={item.lat} lng={item.lng} item={item} />
                  })
                }
              </GoogleMapReact>
            </div>

            <div className="container">
              <div className="databases-inner">

                <h2 className="databases-listing-header">Browse all the providers:</h2>
                <h2 className="databases-listing-desc">Open to any microchip database, please get in touch!</h2>

                <div className="databases-listing-items">
                  {
                    items.map((item) => {
                      return <ProviderItem item={item} size="third" />
                    })
                  }
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;