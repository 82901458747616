import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import S from 'string';
import moment from 'moment';
import accounting from 'accounting';
import InfiniteScroll from 'react-infinite-scroll-component';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';

const PAGE_LIMIT = 10;

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading',
      items:            [],
      offset:           0,
      search:           '',
      hasMore:          true

    };
    this.handleUserChange = params.handleUserChange;
    this.inputSearchQuery = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = 'Searches / ' + result.data.name;

    // handle the item
    this.setState({

      section:      'view',
      item:         result.data

    }, async () => {

      this.handleResetSearch();

    });

  }
  fetchSearchItems = async () => {

    // get the token
    let token = APIClient.getToken();

    // get the items
    let items = [];

    // set the items
    let query = {
      token,
      limit: PAGE_LIMIT,
    };

    // add params
    if(S(this.state.search).isEmpty() === false) {
      query.q = this.state.search;
    }

    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/groups/' + this.state.item.slug + '/responses',
        query:  query
  
      });
      items = result.data || [];
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

    // set the item
    this.setState({
      items
    })

  }
  handleSearchQueryChange = () => {
    if(this.timer) clearTimeout(this.timer);
    this.setState({
      search: this.inputSearchQuery.current.value
    }, () => {
      this.timer = setTimeout(this.handleResetSearch, 100);
    });
  }
  handleResetSearch = () => {
    this.setState({
      
      limit:    PAGE_LIMIT,
      offset:   0,
      hasMore:  true

    }, this.fetchSearchItems);
  }
  handleNext = async () => {
    let offset = this.state.offset + PAGE_LIMIT;

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
      offset: offset,
      limit: PAGE_LIMIT,
    };

    // add params
    if(S(this.state.search).isEmpty() === false) {
      query.q = this.state.search;
    }

    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/groups/' + this.state.item.slug + '/responses',
        query:  query
  
      });
      pulleditems = result.data || [];
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

    let items = (this.state.items || []).concat(pulleditems || []);

    // set the item
    this.setState({
      items:    items,
      offset:   offset,
      hasMore:  pulleditems.length === PAGE_LIMIT
    })
  }
  renderItems = () => {

    if(this.state.item.found > 0 && this.state.items.length > 0) {

      return (<div className="groupsearches-listing-items">
        {
          this.state.items.map((item) => {
            return (<div key={item.uid} className="groupsearches-listing-item">
              <Link className="groupsearches-listing-item-inner" key={item.uid} to={'/groups/' + this.state.item.slug + '/searches/' + item.uid}>
                <div className="groupsearches-listing-item-preview">
                  <img alt="Map Preview" className="groupsearches-listing-item-image" src={'https://maps.googleapis.com/maps/api/staticmap?' + [

                    'center=' + item.lat + ', ' + item.lng,
                    'zoom=10',
                    'size=250x200',
                    'maptype=roadmap',
                    'markers=color:purple%7C' + item.lat + ', ' + item.lng,
                    'key=AIzaSyDuidBMefKFW1znPnAZIur5S64CA9SXhCY'

                  ].join('&')} />
                </div>
                <div className="groupsearches-listing-item-meta">
                  <div className="groupsearches-listing-item-meta-banner">
                    <p><span className="fas fa-search"></span>{item.chipnumber}</p>
                    <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
                  {
                      item.city && item.city.name
                    ?
                      <p>{item.city.name}</p>
                    :
                      null
                  }
                  <div className="clearfix"></div>
                  {
                      item.country && item.country.name
                    ?
                      <p>{item.country.name}</p>
                    :
                      null
                  }
                  <div className="clearfix"></div>
                  <p>{moment(item.created).format('LLL')}</p>
                  <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
              </Link>
            </div>);
          })
        }
      </div>);

    } else if(this.state.item.found === 0) {

      return (<div className="groupsearches-items-blank">
        <span className="fas fa-search"></span>
        <p>We show any successfull lookups made on your group data here, none so far unfortunately</p>
        <div className="clearfix"></div>
      </div>);

    } else {

      return (<div className="groupsearches-items-blank">
        <span className="fas fa-search-minus"></span>
        <p>No lookups found matching that search :(</p>
        <div className="clearfix"></div>
      </div>);

    }
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupsearches">
                  <div className="container">
                    <div className="groupsearches-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupsearches">
            <div className="container">
              <div className="groupsearches-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item} section="searches"></GroupMenuView>
        <div className="wrapper">
          <div className="groupsearches">
            <div className="container">
              <div className="groupsearches-inner">

                <InfiniteScroll 
                  dataLength={this.state.items.length} //This is important field to render the next data
                  next={this.handleNext}
                  hasMore={this.state.hasMore} 
                  style={{
                    overflow: 'visible'
                  }}
                  loader={<h4 style={{textAlign: 'center', marginTop: 25}}>Loading more searches...</h4>}
                  endMessage={
                      this.state.items.length > PAGE_LIMIT
                    ?
                      <p style={{textAlign: 'center', marginTop: 25}}>
                        <b>No more records found :)</b>
                      </p>
                    : 
                      null
                  }
                  // below props only if you need pull down functionality
                  refreshFunction={this.handleResetSearch} >

                    <h1 className="groupsearches-heading">Explore all successful searches on your records</h1>
                    <p className="groupsearches-heading-slogan">That's about {accounting.formatNumber(this.state.item.found)} searches in total ;)</p>

                    <div className="groupsearches-filter-form">
                      <input onChange={this.handleSearchQueryChange} defaultValue={this.state.search} ref={this.inputSearchQuery} type="text" placeholder="Filter searches using email, microchip number, city etc" />
                    </div>
                  
                  {this.renderItems()}
                  <div className="clearfix"></div>

                </InfiniteScroll>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;