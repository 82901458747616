import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import './style.css';
import APIClient from '../../client';
import S from 'string';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import './history.css';

const PAGE_LIMIT = 100;

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      group:        params.group,
      item:         params.record,
      user:         params.user,
      locality:     params.locality,
      search:       '',
      offset:       0,
      hasMore:      true,
      items:        [],
      section:      'loading'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputSearchQuery = React.createRef();
  }
  componentDidMount = async () => {

    document.title = 'History of ' + this.state.item.uid
    await this.fetchItems();

  }
  fetchItems = async () => {

    // get the token
    let token = APIClient.getToken();

    // get the items
    let items = [];

    // set the items
    let query = {
      token,
      record: this.state.item.uid,
      group: this.state.group.slug,
      limit: PAGE_LIMIT,
    };
    
    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/logs',
        query:  query
  
      });
      items = result.data || [];
    } catch(err) {
      this.setState({
        section:      'error',
        errorMessage: 'Problem getting the new items'
      })
      return;
    }

    // set the item
    this.setState({
      section: 'loaded',
      items,
      hasMore: items.length === PAGE_LIMIT
    })

  }
  getDownloadURL = () => {

    // get the token
    let token = APIClient.getToken();

    // set the query
    let query = [

      'token=' + token,
      'record=' + this.state.item.uid,
      'group=' + this.state.group.slug,
      'limit=' + PAGE_LIMIT,
      'format=csv',
      'download=yes',

    ];
    
    // move the document
    return APIClient.getBaseAPIURL() + '/logs?' + query.join('&');

  }
  handleSearchQueryChange = () => {
    if(this.timer) clearTimeout(this.timer);
    this.setState({
      search: this.inputSearchQuery.current.value
    }, () => {
      this.timer = setTimeout(this.handleResetSearch, 100);
    });
  }
  handleResetSearch = () => {
    this.setState({
      
      limit:    PAGE_LIMIT,
      offset:   0,
      hasMore:  true

    }, this.fetchItems);
  }
  handleNext = async () => {
    let offset = this.state.offset + PAGE_LIMIT;

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
      record: this.state.item.uid,
      group: this.state.group.slug,
      offset: offset,
      limit: PAGE_LIMIT,
    };

    // add params
    if(S(this.state.search).isEmpty() === false) {
      query.q = this.state.search;
    }

    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/logs',
        query:  query
  
      });
      if(Array.isArray(pulleditems) === false) {
        throw new Error('not an array')
      }
      pulleditems = result.data || [];
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

    let items = (this.state.items || []).concat(pulleditems || []);

    // set the item
    this.setState({
      items:    items,
      offset:   offset,
      hasMore:  pulleditems.length === PAGE_LIMIT
    })
  }
  renderItem = (item) => {
    return (<div key={item.uid} className="grouprecord-history-listing-item">
      <div className="grouprecord-history-listing-item-inner" key={item.uid}>
        <p className="grouprecord-history-listing-item-message">{item.message}</p>
        {
            item.user
          ? 
            <p className="grouprecord-history-listing-item-performedbyuser">Action performed by user - {item.user.name}</p>
          :
            null
        }
        <p className="grouprecord-history-listing-item-timestamp">{moment(item.created).format('LLL')}</p>
        <div className="clearfix"></div>
      </div>
    </div>);
  }
  renderItems = () => {
    if(this.state.items.length > 0) {

      return (<div className="grouprecord-history-listing-items">
        {
          this.state.items.map((item) => {
            return this.renderItem(item)
          })
        }
      </div>);

    } else {

      return (<div className="grouprecord-items-blank">
        <span className="fas fa-history"></span>
        <p>No history noted for the #{this.state.item.uid}</p>
        <div className="clearfix"></div>
      </div>);

    }
  }
  render() {
    if(this.state.section === 'loading') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-spinner"></span>
        <p>Loading...</p>
        <div className="clearfix"></div>
      </div>);
    }
    if(this.state.section === 'error') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-exclamation-triangle"></span>
        <p>Unable to pull logs, try again in a few</p>
        <div className="clearfix"></div>
      </div>);
    }
    const items = this.state.items || [];
    return (<div>

      <div className="grouprecord-history-ctas">
        <a href={this.getDownloadURL()} className="grouprecord-history-cta" target="_blank" rel="noopener noreferrer">Download as CSV</a>
        <div className="clearfix"></div>
      </div>

      <InfiniteScroll 
        dataLength={items.length} //This is important field to render the next data
        next={this.handleNext}
        hasMore={this.state.hasMore} 
        style={{
          overflow: 'visible'
        }}
        loader={<h4 style={{textAlign: 'center', marginTop: 25}}>Loading more searches...</h4>}
        endMessage={
            items.length > PAGE_LIMIT
          ?
            <p style={{textAlign: 'center', marginTop: 25}}>
              <b>No more searces found :|</b>
            </p>
          : 
            null
        }
        // below props only if you need pull down functionality
        refreshFunction={this.handleResetSearch} >

        <div className="groupsearches-filter-form">
          <input onChange={this.handleSearchQueryChange} defaultValue={this.state.search} ref={this.inputSearchQuery} type="text" placeholder="Filter searches using email, microchip number, city etc" />
        </div>
        
        {this.renderItems()}
        <div className="clearfix"></div>

      </InfiniteScroll>
    </div>);
  }
}

export default App;
