import React, { Component } from 'react';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      locality:         params.locality,
      user:             params.user

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = () => {
    document.title = 'Privacy Statement';
  }
  render() {
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="privacy">
            <div className="privacy-hero">
              <div className="container">
                <div className="privacy-inner">
                  <h1 className="privacy-hero-heading">Privacy Policy</h1>
                  <h3 className="privacy-hero-slogan">ChipnDoodle takes your privacy seriously. To better protect your privacy we provide this privacy policy notice explaining the way your personal information is collected and used.</h3>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="privacy-inner">

                <h2 className="privacy-heading">Collection of Routine Information</h2>

                <p>This service track basic information about their user. This information includes, but is not limited to, IP addresses, browser/app details, timestamps and referring pages. None of this information can personally identify specific user to this service. The information is tracked for routine administration and maintenance purposes.</p>

                <h2 className="privacy-heading">Integrations for information access</h2>

                <p>Some of the service relies on the use of web scraping to pull together all the data, our attempt is to onboard all services and give them full control as we continue expanding. The service itself pledges to never harm nor try to access unautherized data; using only data is publicly visible on the internet by any user not logged in.</p>

                <p>Keeping in mind various web scraping legalities the service tweaks how it handles data per region:</p>
                
                <p>Across Africa we aim to comply with regulations like POPI to clearly state information and where we got it. Only showing visible user data for a pmited time then pmiting access; keeping records for law enforcement and pet recovery agencies.</p>
                <p>In Europe we do simple flag checks to see if the microchip is registered with a certain database and don't actually exchange personal contact details</p>
                <p>In the US we do simple flag checks to see if the microchip is registered with a certain database and don't actually exchange personal contact details.</p>

                <h2 className="privacy-heading">Cookies</h2>

                <p>Where necessary, this service uses cookies to store information about a visitor’s preferences and history in order to better serve the visitor and/or present the visitor with customized content.</p>

                <h2 className="privacy-heading">Advertisement and Other Third Parties</h2>

                <p>Advertising partners and other third parties may use cookies, scripts and/or web beacons to track user activities on this service in order to display advertisements and other useful information. Such tracking is done directly by the third parties through their own servers and is subject to their own privacy policies. This service has no access or control over these cookies, scripts and/or web beacons that may be used by third parties. Learn how to [opt out of Google’s cookie usage](http://www.google.com/privacy_ads.html).</p>

                <h2 className="privacy-heading">Links to Third Party Websites</h2>

                <p>we have included links on this service for your use and reference. we are not responsible for the privacy policies on these websites. You should be aware that the privacy policies of these websites may differ from our own.</p>

                <h2 className="privacy-heading">Security</h2>

                <p>The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

                <h2 className="privacy-heading">Changes To This Privacy Policy</h2>

                <p>This Privacy Policy is effective as of 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

                <p>we reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our service.</p>

                <h2 className="privacy-heading">Contact Information</h2>

                <p>For any questions or concerns regarding the privacy policy, please send us an email to <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a>.</p>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;