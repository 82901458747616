import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      group:        params.group,
      user:         params.user,
      backToLogin:  false,
      menuSection:  params.section || '',
      section:      params.section || ''

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    let permissions = (this.state.user || {}).permissions || [];
    return (
      <div className="groupsettingsmenu">
        <div className="container">
          <div className="groupsettingsmenu-inner">
            <div className="groupsettingsmenu-items">
              <Link className={"groupsettingsmenu-item" + (this.state.menuSection === 'details' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/settings"}>
                <span className="fas fa-users-cog"></span>
                <p>Details</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"groupsettingsmenu-item" + (this.state.menuSection === 'members' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/members"}>
                <span className="fas fa-id-card-alt"></span>
                <p>Members</p>
                <div className="clearfix"></div>
              </Link>
              {
                  permissions.indexOf('admin') !== -1
                ? 
                  <Link className={"groupsettingsmenu-item" + (this.state.menuSection === 'integration' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/integration"}>
                    <span className="fas fa-laptop-code"></span>
                    <p>Integration</p>
                    <div className="clearfix"></div>
                  </Link>
                :
                  null
              }
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
