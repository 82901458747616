import React, { Component } from 'react';
import S from 'string';
import _ from 'underscore';
import accounting from 'accounting';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';


class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      group:            null,
      locality:         params.locality,
      user:             params.user,
      importuid:        (params.match.params || {}).importuid || null,
      groupuid:         (params.match.params || {}).groupuid || null,
      started:          new Date().getTime(),
      section:          'loading',
      submitting:       false,
      errorMessage:     null,
      submissions:      0,
      schema:           {},
      method:           'upload'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCopyPaste = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let groupresult = null;

    // handle errors
    try {
      groupresult = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(groupresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = groupresult.data.name;

    // set the call
    let importresult = null;

    // handle errors
    try {
      importresult = await APIClient.call({

        path:   '/groups/' + this.state.groupuid + '/imports/' + this.state.importuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(importresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set our schema
    let schema = {};

    // loop and set
    let columns = (importresult.data.sample || '').split(',');

    // loop and set
    for(let i = 0; i < columns.length; i++) {
      schema[i] = {
        index:    i,
        type:     'unknown',
        value:    columns[i],
      };
    }

    // set the import
    this.setState({

      section:      'view',
      schema:       schema,
      group:        groupresult.data,
      item:         importresult.data

    });

    // every 3 seconds, update
    setTimeout(this.fetchImportUpdate, 1000 * 3);

  }
  componentWillUnmount = () => {

    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

  }
  scheduleNextRequest = () => {

    if(new Date().getTime() - this.state.started > 1000 * 60 * 10 && 
        this.state.item.status !== 'done') {
      this.setState({
        section: 'givenup'
      });
    } else {
      this.timer = setTimeout(this.fetchImportUpdate, 1000 * 3);
    }

  }
  fetchImportUpdate = async () => {

    // get the token
    let token = APIClient.getToken();

    // set the call
    let importresult = null;

    // handle errors
    try {
      importresult = await APIClient.call({

        path:   '/groups/' + this.state.groupuid + '/imports/' + this.state.importuid,
        query:  {
          token
        }
  
      });
    } catch(err) { return this.scheduleNextRequest(); }

    if(importresult.ok !== true) { return this.scheduleNextRequest(); }

    // set the import
    this.setState({
      
      item:         importresult.data

    }, () => { return this.scheduleNextRequest(); });

  }
  handleSubmit = async (e) => {

    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.group.slug + '/imports/' + this.state.item.uid,
        method:   'post',
        form:     {
          token,
          schema: JSON.stringify(_.values(this.state.schema))
        }
  
      });
    } catch(err) {
      console.error(err);
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.setState({
      submitting:       false,
      item:             result.data,
      errorMessage:     null,
    })

  }
  renderSchemaField = (field, lastitem) => {
    return (<div className={"groupimport-schema-field" + (lastitem === true ? ' groupimport-schema-field-last' : '')}>
      <div  className="groupimport-schema-field-meta">
        <p>{S(field.value).chompLeft('"').chompRight('"').truncate(37, '...').s}</p>
      </div>
      <div  className="groupimport-schema-field-type">
        <select onChange={(e) => {
          let typekey = e.target.value;
          let index = field.index;
          let schema = this.state.schema;
          schema[index].type = typekey;
          this.setState({
            schema
          })
        }}>
          <option value="unknown">Not Specified</option>
          <option value="microchip">Microchip</option>
          <option value="nickname">Name/Nickname of Animal</option>
          <option value="gender">Gender of Animal</option>
          <option value="dob">Date of Birth</option>
          <option value="death">Date of Death (blank means alive)</option>
          <option value="created">Date Registered</option>
          <option value="description">Description (shown in searches)</option>
          <option value="comment">Comments (not shown in searches)</option>
          <option value="color">Color of Animal</option>
          <option value="species">Species</option>
          <option value="breed">Breed(s)</option>
          <option value="name">Name of Owner</option>
          <option value="email">E-Mail of Owner</option>
          <option value="phonenumber">Phonenumber of Owner</option>
          <option value="address">Address of pet home</option>
          <option value="ignore">Please Ignore The Field</option>
        </select>
      </div>
      <div className="clearfix"></div>
    </div>)
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupimport">
                  <div className="container">
                    <div className="groupimport-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          <div className="groupimport">
            <div className="container">
              <div className="groupimport-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let banner = null;
    if(this.state.errorMessage) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    let bodyContent = null;
    if(this.state.item.status === 'done') {
      bodyContent = (<div className="groupimport-content">

        <img  className="groupimport-content-img" alt="Dog loading animation" src="https://cdn.chipndoodle.com/animations/snackingdog.gif" />
        <h2 className="groupimport-content-heading">Import of {accounting.formatNumber(this.state.item.count)} records done</h2>
        <p className="groupimport-content-slogan">We are done here :)</p>
        <p className="groupimport-content-subtext">Check what they look like in records!</p>
        
      </div>);
    } else if(this.state.item.status === 'running' && 
        this.state.item.processed <= 0) {
      bodyContent = (<div className="groupimport-content">

        <img  className="groupimport-content-img" alt="Dog loading animation" src="https://cdn.chipndoodle.com/animations/taildog.gif" />
        <h2 className="groupimport-content-heading">WE ARE GETTING READY</h2>
        <p className="groupimport-content-slogan">Gives us a moment to start processing, usually takes about a minute or two to start.</p>
        <p className="groupimport-content-subtext">Keep watching this page for realtime updates, but we will email you when anything changes so grab some coffee :)</p>
        
      </div>);
    } else if(this.state.item.status === 'running') {
      bodyContent = (<div className="groupimport-content">

        <img  className="groupimport-content-img" alt="Dog loading animation" src="https://cdn.chipndoodle.com/animations/taildog.gif" />
        <h2 className="groupimport-content-heading">{accounting.formatNumber(this.state.item.processed)} / {accounting.formatNumber(this.state.item.count)} ({accounting.formatNumber((this.state.item.processed/this.state.item.count)*100)}%)</h2>
        <p className="groupimport-content-slogan">We will keep going till all the records are imported</p>
        <p className="groupimport-content-subtext">Keep watching this page for realtime updates, but we will email you when anything changes so grab some coffee :)</p>
        
      </div>);
    } else {

      let columns = _.values(this.state.schema);

      bodyContent = (<div className="groupimport-content">

        <img  className="groupimport-content-img" alt="Dog loading animation" src="https://cdn.chipndoodle.com/animations/coffeedog.gif" />
        <h2 className="groupimport-content-heading">{accounting.formatNumber(this.state.item.count)} potential record{this.state.item.count > 1 ? 's' : ''} found</h2>
        <p className="groupimport-content-slogan">Now to see what data you have :)</p>
        <p className="groupimport-content-subtext">Below is a form with a sample of the data from your import, please tell us next to each piece of data what is it. For example email, address etc etc</p>
        
        <form onSubmit={this.handleSubmit}>

          <div className="groupimport-schema-fields">
            {
              columns.map((column, index) => {
                return this.renderSchemaField(column, index === columns.length-1);
              })
            }
          </div>

          <div className="clearfix"></div>
          <button className="groupimport-cta" onClick={this.handleSubmit}>Start Processing my Data</button>
          <div className="clearfix"></div>

        </form>

        <p className="groupimport-content-subtext">By importing this data you agree to our terms of service, privacy policy and data policies. Please take a moment to review them, clicking to start binds you to these agreement.</p>

      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          {banner}
          <div className="groupimport">

            <div className="hero-box">
              <h2 className="hero-heading">Import #{S(this.state.item.uid).truncate(15, ' ').s}</h2>
              <h2 className="hero-slogan">Created: {this.state.item.created}</h2>
            </div>
            <div className="container">
              <div className="groupimport-inner">

                <div className="details-box">
                  <div className="details-box-meta details-box-meta-full">
                    {bodyContent}
                  </div>
                  <div className="clearfix"></div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;