import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import superagent from 'superagent';
import moment from 'moment';
import _ from 'underscore';
import S from 'string';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CONSTANTS from '../../constants';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';

const CHIP_FORMAT_NUMBER  = 5;

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: 44, borderRadius: 1, cursor: 'pointer' }),
  option: styles => ({ ...styles, cursor: 'pointer' }),
};

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading',
      species:          CONSTANTS.SPECIES_DEFAULT,
      breeds:           [],
      sex:              'male',
      speciesOptions:   [],
      ownership:        'group'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputChipNumber = React.createRef();
    this.inputName = React.createRef();
    this.inputSpecies = React.createRef();
    this.inputBreeds = React.createRef();
    this.inputColor = React.createRef();
    this.inputDOB = React.createRef();
    this.inputDescription = React.createRef();
    this.inputLabel = React.createRef();
    this.inputWebsite = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPhoneNumber = React.createRef();
    this.inputAddress = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // the tasks
    let tasks = [];

    // push it
    tasks.push(APIClient.call({

      path:   '/groups/' + this.state.groupuid,
      query:  {
        token
      }

    }));

    // push it
    tasks.push(APIClient.call({

      path:   '/species',
      query:  {
        token
      }

    }));

    // set the call
    let results = [];

    // handle errors
    try {
      results = await Promise.all(tasks);
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    // let group item
    let groupresult =   results[0];
    let speciesitems =  results[1];

    if(groupresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    if(speciesitems.ok !== true) {
      this.setState({
        section: 'error'
      });
      return;
    }

    // set the groups
    document.title = groupresult.data.name;

    // the items
    let speciesoptions = [];

    // load species
    for(let i = 0; i < speciesitems.data.length; i++) {
      let speciesitem = speciesitems.data[i];
      speciesoptions.push({
        
        value:  speciesitem.slug,
        label:  speciesitem.name,

      });
    }

    // set the view
    this.setState({

      section:          'view',
      item:             groupresult.data,
      speciesoptions:   speciesoptions,

    });

  }
  handleBreedChange = async (vals) => {
    if(!vals || !vals.length) return;
    let breedNames = [];
    for(let i = 0; i < vals.length; i++) {
      breedNames.push(vals[i].label)
    }
    this.setState({
      breeds: breedNames
    })
  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.item.slug + '/records',
        method:   'post',
        form:     {
  
          token,
          uid:            this.state.chipnumber,
          description:    this.inputDescription.current.value,
          label:          this.inputLabel.current.value,
          species:        this.state.species,
          breeds:         this.state.breeds.join(','),
          color:          this.inputColor.current.value,
          dob:            this.inputDOB.current.value,
          owner:          this.state.ownership,
          sex:            this.state.sex,
          name:           ((this.inputName || {}).current || {}).value || null,
          email:          ((this.inputEmail || {}).current || {}).value || null,
          phonenumber:    ((this.inputPhoneNumber || {}).current || {}).value || null,
          address:        ((this.inputAddress || {}).current || {}).value || null,
  
        }
  
      });
    } catch(err) {
      console.error(err);
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.props.history.push('/groups/' + this.state.item.slug + '/records');
  }
  fetchBreedsByFilter = async (inputValue, callback) => {

    // get the token
    let token = APIClient.getToken();

    let result = null;
    let entries = [];
    try {
      result = await APIClient.call({

        path:     '/breeds',
        method:   'get',
        query:    {
          q:        inputValue,
          token:    token,
          species:  this.state.species,
        }
  
      });
    } catch(err) {
      console.error(err);
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    // loop and add
    for(let i = 0; i < result.data.length; i++) {
      entries.push({
        label:    result.data[i].name,
        value:    result.data[i].uid,
      })
    }

    callback(entries);

  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      section:        'uploading',
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=records.new&token=' + token);
      req.attach('file', files[0]);
      req.end((err, res) => {
        let data = JSON.parse(res.text);
        let animals = (data || {}).animals || [];
        let errorMessage = null;

        let stateParams = {

          logo:           data.url,
          upload:         data.uid,
          errorMessage:   errorMessage,

        };

        if(animals.length > 1) {
          errorMessage = 'That image had more than one animal in it, we chose the first one we found.';
        }

        if(animals.length > 0) {
          let animal = animals[0];
          
          stateParams.species = animal.species || '';

          if(S(this.inputBreeds.current.value).isEmpty() === true) {

            let breedsTxt = [];
            for(let i = 0; i < (animal.breeds || []).length; i++) {
              let breed = animal.breeds[i];
              if(breed.prob > 0.9) {
                breedsTxt = [breed.label];
                break;
              }
              if(breed.prob < 0.6) {
                continue;
              }
              breedsTxt.push(breed.label);
            }
            if(breedsTxt.length === 0 && (animal.breeds || []).length > 0) {
              breedsTxt = [animal.breeds[0].label]
            }
            if(breedsTxt.length > 0) {
              this.inputBreeds.current.value = breedsTxt.join(', ');
            } else {
              this.inputBreeds.current.value = breedsTxt.join('');
            }

          }
          this.inputDOB.current.value = moment().subtract(animal.age || 0, 'day').toDate();
        }
        this.setState(stateParams);
      })
    });

  }
  renderPrivatePerson = () => {
    if(this.state.ownership !== 'person') return;
    return (<div>

      <div className="details-box-control">
        <div className="details-box-control-label">
          <label htmlFor="nickname">Owner Name</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="text" defaultValue={this.state.website} ref={this.inputName} placeholder="Name of the owner" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>

      <div className="details-box-control details-box-control-half">
        <div className="details-box-control-label">
          <label htmlFor="nickname">EMail</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="email" defaultValue={this.state.email} ref={this.inputEmail} placeholder="E-Mail to contact" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="details-box-control details-box-control-half">
        <div className="details-box-control-label">
          <label htmlFor="nickname">Phonenumber</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="tel" defaultValue={this.state.phonenumber} ref={this.inputPhoneNumber} placeholder="Phonenumber to contact is searched" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>

      <div className="clearfix"></div>

      <div className="details-box-control">
        <div className="details-box-control-label">
          <p>We only ever show the city on our searches</p>
          <label htmlFor="nickname">Address</label>
        </div>
        <div className="details-box-control-inputs">
          <input type="text" defaultValue={this.state.address} ref={this.inputAddress} placeholder="Where the pet calls home" />
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="clearfix"></div>
    </div>);
  }
  handleMicrochipTextUpdate = async (text) => {
    let chipnumber = (this.inputChipNumber.current.value || '').split('-').join('').slice(0, 15);
    this.setState({
      chipnumber: chipnumber,
      chipnumberfmt: this.formatChipNumber(chipnumber)
    });
  }
  formatChipNumber = (text) => {
    if(!text) return '';
    let groups = Math.ceil(text.length / CHIP_FORMAT_NUMBER);
    let groupings = [];
    for(let i = 0 ; i < groups; i++) {
      groupings.push(text.slice(i * CHIP_FORMAT_NUMBER, (i+1) * CHIP_FORMAT_NUMBER));
    }
    return groupings.join('-');
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupnew">
                  <div className="container">
                    <div className="groupnew-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupnew">
            <div className="container">
              <div className="groupnew-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let banner = null;
    if(this.state.errorMessage) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item}></GroupMenuView>
        <div className="wrapper">
          {banner}
          <div className="groupnew">
            <div className="hero-box">
              <h2 className="hero-heading">Add a new Record</h2>
              <h2 className="hero-slogan">We need a few details</h2>
            </div>
            <div className="container">
              <div className="groupnew-inner">

                <div className="details-box">
                    <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
                      {({getRootProps, getInputProps}) => (
                        <div onClick={(e) => {
                          e.stopPropagation();
                        }} className="details-box-preview">
                          <div {...getRootProps()}>
                          <p className="details-box-preview-text">Add a photo of the animal</p>
                          <img alt="Upload Preview" src={this.state.logo || 'https://cdn.chipndoodle.com/defaults/record.png'} className="details-box-preview-img" />
                          <input {...getInputProps()} />
                          <p className="details-box-preview-text">Click here to upload and we will also help you guess the breed/age and sex</p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="details-box-meta">

                    <form onSubmit={this.handleSubmit} className="details-box-controls">

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Microchip Number</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" ref={this.inputChipNumber} placeholder="10/15 digit number" onChange={this.handleMicrochipTextUpdate} value={this.state.chipnumberfmt} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Name / Nickname</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.name} ref={this.inputLabel} placeholder="Name of the Animal" />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control details-box-control-half">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Species</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <Select defaultValue={this.state.species} options={this.state.speciesoptions} onChange={(selectItem) => {
                            this.setState({
                              species: selectItem.value
                            })
                          }} styles={colourStyles} selectItem={this.state.species} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="details-box-control details-box-control-half">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Gender of Animal</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <Select options={[
                            {
                              label: 'Male',
                              value: 'male',
                            },
                            {
                              label: 'Female',
                              value: 'female',
                            }
                          ]} onChange={(selectItem) => {
                            this.setState({
                              sex: selectItem.value
                            })
                          }} styles={colourStyles} selectItem={this.state.sex} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <p>No sure? Just upload a picture and we will take a guess :)</p>
                          <label htmlFor="nickname">Breed(s)</label>
                        </div>
                        <div className="details-box-control-inputs">
                          
                          <AsyncSelect 
                            ref={this.inputBreeds}
                            cacheOptions isMulti noOptionsMessage={() => 'Start typing to search breeds, you can select multiple..'} 
                            loadOptions={this.fetchBreedsByFilter}
                            defaultOptions
                            onChange={this.handleBreedChange}
                          />

                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control details-box-control-half">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Color </label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" placeholder="Color of the pet" defaultValue={this.state.name} ref={this.inputColor} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="details-box-control details-box-control-half">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">DOB</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="date" placeholder="YYYY-MM-DD" defaultValue={this.state.dob || null} ref={this.inputDOB} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Description / Comments</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <textarea placeholder="Description of pet or any comments about owner etc" ref={this.inputDescription}></textarea>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control">
                        <div className="add-details-box-control-label">
                          <p>Will be shown as main contact person</p>
                          <label htmlFor="nickname">Main Contact</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <div className="add-details-box-control-selectables">
                            <a href={'/groups/' + this.state.item.slug + '/new#main'} onClick={(e) => {
                              if(e) e.preventDefault();
                              this.setState({
                                ownership: 'group'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.ownership === "group" ? " active" : "")}>THIS GROUP</a>
                            <a href={'/groups/' + this.state.item.slug + '/new#main'} onClick={(e) => {
                              if(e) e.preventDefault();
                              this.setState({
                                ownership: 'person'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.ownership === "person" ? " active" : "")}>PRIVATE PERSON</a>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      {this.renderPrivatePerson()}

                      </form>
                        
                      <div className="details-box-toolbar">
                        <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>CREATE</Link>
                        <p className="details-box-toolbar-text"></p>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;