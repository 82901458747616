import React, { Component } from 'react';
import S from 'string';
import './style.css';
import { Link } from 'react-router-dom';

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      group:        params.group,
      user:         params.user,
      backToLogin:  false,
      menuSection:  params.section || '',
      section:      params.section || ''

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    return (
      <div className="groupmenu">
        <div className="container">
          <div className="groupmenu-inner">
            <Link className="groupmenu-intro" to={"/groups/" + this.state.group.slug}>
              <h2 className="groupmenu-heading">{S(this.state.group.name).truncate(23, '...').s}</h2>
              <div className="clearfix"></div>
            </Link>
            <div className="groupmenu-items">
              <Link className={"groupmenu-item" + (this.state.menuSection === 'overview' ? ' active' : '')} to={"/groups/" + this.state.group.slug}>
                <span className="fas fa-tachometer-alt"></span>
                <p>Summary</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"groupmenu-item" + (this.state.menuSection === 'records' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/records"}>
                <span className="fas fa-paw"></span>
                <p>Records</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"groupmenu-item" + (this.state.menuSection === 'searches' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/searches"}>
                <span className="fas fa-search-location"></span>
                <p>Searches</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"groupmenu-item groupmenu-item-last" + (this.state.menuSection === 'settings' ? ' active' : '')} to={"/groups/" + this.state.group.slug + "/settings"}>
                <span className="fas fa-cogs"></span>
                <p>Settings</p>
                <div className="clearfix"></div>
              </Link>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
