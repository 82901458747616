import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
const CONSTANTS = require('../../constants');

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      user:           params.user,
      locality:       params.locality,
      errorMessage:   null,
      submitting:     false,
      gotoProfile:     false,
      submissions:    0

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPassword = React.createRef();
  }
  componentDidMount = () => {

    this.handleUserChange(null);
    this.setState({ user: null })
    document.title = "Login";

  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  startChallenge = (e) => {
    if(e) e.preventDefault();
    if(this.state.submissions > 0) {
      this.inputCaptcha.current.reset();
    }
    this.inputCaptcha.current.execute();
    // const value = this.inputCaptcha.current.getValue();
  }
  handleSubmit = async (value) => {
    if(this.state.submitting === true) return;
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/login',
        method:   'post',
        form:     {
  
          code: value,
          email: this.inputEmail.current.value,
          password: this.inputPassword.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }
    
    APIClient.setToken(result.data.token);
    this.handleUserChange(result.data.user);
    this.props.history.push('/searches');
  }
  render() {
    let banner = null;
    if(this.state.submitting === true) {
      banner = (<div>
        <div className="progress-overlay"></div>
        <div className="progress-wrapper">
          <div className="progress-box-container">
            <div className="progress-box"></div>
            <h3 className="progress-text">Logging you in...</h3>
          </div>
        </div>
      </div>);
    } else if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }

    return (

      <div>
        <HeaderView {...this.props} section='login' locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="">
          <div className="login">
            {banner}
            <div className="container">
              <div className="login-inner">

                <div className="login-dialog">

                <div className="cat">
                  <div className="body"></div>
                  <div className="head">
                    <div className="ear"></div>
                  <div className="ear"></div>
                  </div>
                  <div className="face">
                    <div className="nose"></div>
                  <div className="whisker-container">
                    <div className="whisker"></div>
                    <div className="whisker"></div>
                  </div>
                  <div className="whisker-container">
                    <div className="whisker"></div>
                    <div className="whisker"></div>
                  </div>
                  </div>
                  <div className="tail-container">
                    <div className="tail">
                      <div className="tail">
                        <div className="tail">
                          <div className="tail">
                            <div className="tail">
                              <div className="tail">
                                <div className="tail"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                  <form onSubmit={this.handleSubmit} className="container">
                    <div className="clearfix"></div>
                    <div className="login-form">
                      <div className="login-form-control">
                        <label htmlFor="email">Email</label>
                        <input ref={ this.inputEmail } name="email" type="text" placeholder="your email" />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <label htmlFor="password">Password</label>
                        <input ref={ this.inputPassword } name="password" type="password" placeholder="your password" />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <ReCAPTCHA
                          ref={this.inputCaptcha} 
                          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
                          theme="light" 
                          size="invisible"
                          onChange={this.handleSubmit}
                          onExpired={this.onChallengeExpired} 
                          onErrored={this.onChallengeErrored} 
                        />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <Link to="/register">Create an Account?</Link>
                        <button onClick={this.startChallenge}>Login</button>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </form>

                </div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>

    );
  }
}

export default View;