import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import S from 'string';
import superagent from 'superagent';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import Dropzone from 'react-dropzone';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      logo:             null,
      appslug:          (params.match.params || {}).appslug || null,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputName = React.createRef();
    this.inputDescription = React.createRef();
    this.inputWebsite = React.createRef();
  }
  componentDidMount = async () => {

    // set the animals
    document.title = 'App';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let itemdata = null;

    // handle errors
    try {
      itemdata = await APIClient.call({

        path:   '/apps/' + this.state.appslug,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(itemdata.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the animals
    document.title = itemdata.data.name;

    this.setState({

      section:      'view',
      item:         itemdata.data,
      logo:         itemdata.logo || null,

    });

  }
  formatURL = (text) => {
    if(S(text).isEmpty() === true) return null;
    text = text.toLowerCase();
    text = text.replace(/http:\/\/www\./gi, '');
    text = text.replace(/https:\/\/www\./gi, '');
    text = text.replace(/http:\/\//gi, '');
    text = text.replace(/https:\/\//gi, '');
    text = text.split('/')[0];
    return S(text).truncate(25, '..').s;
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      section:        'uploading',
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=apps.logo&token=' + token);
      req.attach('file', files[0]);
      req.end(async (err, res) => {
        let data = JSON.parse(res.text);

        await APIClient.call({

          path:     '/apps/' + this.state.item.slug,
          method:   'post',
          form:     {
    
            token,
            logo: data.uid || null
    
          }
    
        });

        this.setState({
          logo:     data.url,
          upload:   data.uid
        });
      })
    });

  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/apps/' + this.state.item.slug,
        method:   'post',
        form:     {
  
          token,
          name:         this.inputName.current.value,
          description:  this.inputDescription.current.value,
          logo:         this.state.upload || null,
          link:         this.inputWebsite.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    if(this.state.item.slug !== result.data.slug) {
      this.props.history.push('/groups/' + result.data.slug + '/settings');
      return;
    }

    this.setState({
      item: result.data,
      submitting: false
    })    
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="animal">
                  <div className="container">
                    <div className="animal-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="animal">
            <div className="container">
              <div className="animal-inner">
                <p>No such registered database</p>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="database">
            <div className="hero-box">
              <h2 className="hero-heading">{this.state.item.name}</h2>
              <h2 className="hero-slogan">Test</h2>
            </div>
            <div className="container">
              <div className="profile-inner">

                

              <div className="details-box">
                  <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
                    {({getRootProps, getInputProps}) => (
                      <div onClick={(e) => {
                        e.stopPropagation();
                      }} className="details-box-preview">
                        <div {...getRootProps()}>
                        <p className="details-box-preview-text">We need a logo for your app</p>
                        <img alt="Logo of Group" src={this.state.logo || 'https://cdn.chipndoodle.com/defaults/record.png'} className="details-box-preview-img" />
                        <input {...getInputProps()} />
                        <p className="details-box-preview-text">Click here to upload logo</p>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="details-box-meta">

                  <form onSubmit={this.handleSubmit} className="details-box-controls">

                    <div className="details-box-control">
                      <div className="details-box-control-label">
                        <label htmlFor="nickname">Name</label>
                      </div>
                      <div className="details-box-control-inputs">
                        <input type="text" defaultValue={this.state.item.name} ref={this.inputName} />
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="details-box-control">
                      <div className="details-box-control-label">
                        <p>Short summary of who you are and what your group does</p>
                        <label htmlFor="nickname">Description</label>
                      </div>
                      <div className="details-box-control-inputs">
                        <textarea defaultValue={this.state.item.description || ''} ref={this.inputDescription}></textarea>
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="details-box-control">
                      <div className="details-box-control-label">
                        <p>Link of the app</p>
                        <label htmlFor="nickname">Link</label>
                      </div>
                      <div className="details-box-control-inputs">
                        <input type="email" defaultValue={this.state.item.link} ref={this.inputEmail} />
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    <div className="clearfix"></div>

                    <div className="details-box-control">
                      <div className="details-box-control-label">
                        <p>Use to authenticate with <Link to="/docs">our api</Link></p>
                        <label htmlFor="nickname">Token</label>
                      </div>
                      <div className="details-box-control-inputs">
                        <input type="token" readOnly={true} defaultValue={this.state.item.token} ref={this.inputEmail} />
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    <div className="clearfix"></div>

                    </form>
                      
                    <div className="details-box-toolbar">
                      <button className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>SAVE CHANGES</button>
                      <p className="details-box-toolbar-text"></p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;