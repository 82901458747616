import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import GroupSettingsMenuView from '../../widgets/groupsettingsmenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      items:            [],
      submitting:       false,
      errorMessage:     null,
      role:             'admin',
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputEmail = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = 'Settings from ' + result.data.name;

    // handle the item
    this.setState({

      item:         result.data

    }, this.fetchItems);

  }
  fetchItems = async () => {

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups/' + this.state.item.slug + '/members',
        query:  {
          token
        }
  
      });
    } catch(err) {}

    // handle the item
    this.setState({

      section:      'view',
      items:        (result || {}).data || []

    });

  }
  renderItem = (item, index) => {
    return (<div key={item.uid} className={
      [

        'groupmembers-item',
        this.state.items.length-1 === index ? 'groupmembers-item-last' : ''

      ].join(' ')
    }>
      <div className="groupmembers-item-preview">
        <img alt="User Avatar" src={(item.user || {}).avatar || 'https://cdn.chipndoodle.com/defaults/record.png'} />
      </div>
      {
          item.user
        ? 
          <div className="groupmembers-item-meta">
            <h3>{item.user.name}</h3>
            <p>Invited {moment(item.created).format('LLL')}</p>
            <div className="clearfix"></div>
          </div>
        :
          <div className="groupmembers-item-meta">
            <h3>{item.email}</h3>
            <p>Invited {moment(item.created).format('LLL')}</p>
            <div className="clearfix"></div>
          </div>
      }
      <div className="groupmembers-item-role">

        {

            (item.user || {}).uid !== this.state.user.uid
          ?
            (<select defaultValue={item.role} onChange={(e) => {
              e.preventDefault();
              this.handleMemberUpdate({
    
                email:    item.email,
                role:     e.target.value,
    
              });
              let memberitems = [].concat(this.state.items || []);
              for(let i = 0 ;i < memberitems.length; i++) {
                if(memberitems[i].uid !== item.uid) continue;
                memberitems[i].role = e.target.value;
              }
              this.setState({
                items: memberitems
              })
            }}>
              <option value="owner">Owner (full access)</option>
              <option value="admin">Admin (can manage records)</option>
              <option value="viewer">Viewer (only view)</option>
            </select>)
          :
            <p>That's you :)</p>

        }

        <div className="clearfix"></div>
      </div>
      <div className="groupmembers-item-actions">
        {
            (item.user || {}).uid !== this.state.user.uid
          ?
            <a title="Remove member" href="/groups#" onClick={(e) => {
              e.preventDefault();
              let email = (item.user || {}).email || item.email;
              this.handleRemovalOfMember(email);
            }}>
              <span className="fas fa-trash"></span>
            </a>
          : 
            null
        }
        <div className="clearfix"></div>
      </div>
      <div className="clearfix"></div>
    </div>);
  }
  renderItems = () => {
    return (<div className="groupmembers-items">
      {this.state.items.map(this.renderItem)}
    </div>);
  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.item.slug + '/members',
        method:   'post',
        form:     {
  
          token,
          action:   'upsert',
          email:    this.inputEmail.current.value,
          role:     this.state.role || 'admin'
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    await this.fetchItems();
    this.setState({
      submitting:     false,
      section:        'loaded',
    })
  }
  handleRemovalOfMember = async (email) => {
    if(window.confirm([

      'Really remove the user? They will not be able to access any resources from this group anymore!'

    ].join(' ')) !== true) return;
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.item.slug + '/members',
        method:   'post',
        form:     {
  
          token,
          action:   'remove',
          email:    email,
          role:     'owner',
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    await this.fetchItems();
    this.setState({
      submitting:     false,
      section:        'loaded',
    })
  }
  handleMemberUpdate = async (opts) => {
    let token = APIClient.getToken();
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/groups/' + this.state.item.slug + '/members',
        method:   'post',
        form:     {
  
          token,
          action:   'upsert',
          email:    opts.email,
          role:     opts.role || 'admin',
  
        }
  
      });
    } catch(err) {
      this.setState({
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        errorMessage:   result.data.message
      })
      return;
    }
  }
  render() {
    let banner = null;
    if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'add') {
      return (<div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item} section="settings"></GroupMenuView>
        {banner}
        <div className="wrapper">
          <div className="groupmembers groupmembers-alt">
            <div className="hero-box">
              <GroupSettingsMenuView user={this.state.user} section="members" group={this.state.item}></GroupSettingsMenuView>
            </div>
            <div className="container">
              <div className="groupmembers-inner">

                <div className="details-box groupmembers-add-box">
                  <div className="details-box-meta details-box-meta-full">

                  <div className="details-box-meta-full">

                    <form onSubmit={this.handleSubmit} className="details-box-controls">

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">E-Mail</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" placeholder="Users' email here" defaultValue={''} ref={this.inputEmail} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control">
                        <div className="add-details-box-control-label">
                          <p>What role and level permissions?</p>
                          <label htmlFor="nickname">Permissions</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <div className="add-details-box-control-selectables">
                            <Link to={'/groups/' + this.state.item.slug + '/members#view'} onClick={() => {
                              this.setState({
                                role: 'owner'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.role === "owner" ? " active" : "")}>OWNER</Link>
                            <Link to={'/groups/' + this.state.item.slug + '/members#view'} onClick={() => {
                              this.setState({
                                role: 'admin'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.role === "admin" ? " active" : "")}>ADMIN</Link>
                            <Link to={'/groups/' + this.state.item.slug + '/members#view'} onClick={() => {
                              this.setState({
                                role: 'viewer'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.role === "viewer" ? " active" : "")}>VIEWER</Link>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      </form>
                      <div className="details-box-toolbar">
                      <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>CREATE</Link>
                      <a href={'/groups/' + this.state.item.slug + '/members#cancel'} className="details-box-toolbar-action details-box-toolbar-action-bad details-box-toolbar-action-right" onClick={() => {
                        this.setState({
                          section: 'view'
                        })
                      }}>CANCEL</a>
                      <p className="details-box-toolbar-text"></p>
                      <div className="clearfix"></div>
                      </div>

                    </div>

                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
      );
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupmembers">
                  <div className="container">
                    <div className="groupmembers-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupmembers">
            <div className="container">
              <div className="groupmembers-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item} section="settings"></GroupMenuView>
        <div className="wrapper">
          <div className="groupmembers groupmembers-alt">
            <div className="hero-box">
              <GroupSettingsMenuView user={this.state.user} section="members" group={this.state.item}></GroupSettingsMenuView>
            </div>
            <div className="container">
              <div className="groupmembers-inner">

                <div className="details-box">
                  <div className="details-box-meta-full">

                    <div className="groupmembers-wrapper">
                    
                      <div className="groupmembers-actions">
                        <a href={'/groups/' + this.state.item.slug + '/members#add'} onClick={() => {
                          this.setState({
                            section: 'add'
                          })
                        }} className="groupmembers-action">Invite Another Member</a>
                        <div className="clearfix"></div>
                      </div>
                      <h2 className="groupmembers-heading">Users who have access to this group:</h2>
                      <div className="clearfix"></div>

                      {this.renderItems()}
                      <div className="clearfix"></div>

                    </div>
                    
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;