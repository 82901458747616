import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import superagent from 'superagent';
import Dropzone from 'react-dropzone'
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
const CONSTANTS = require('../../constants');

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      user:           params.user,
      locality:       params.locality,
      errorMessage:   null,
      submitting:     false,
      gotoProfile:    false,
      submissions:    0,
      visibility:     'public'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputName = React.createRef();
    this.inputDescription = React.createRef();
    this.inputWebsite = React.createRef();
  }
  componentDidMount = () => {

    document.title = "Congregate new Group";

  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  startChallenge = (e) => {
    if(e) e.preventDefault();
    if(this.state.submissions > 0) {
      this.inputCaptcha.current.reset();
    }
    this.inputCaptcha.current.execute();
  }
  performCaptha = async (e) => {
    if(e) e.preventDefault();
    this.inputCaptcha.current.execute();
    let value = null;
    let attempts = 0;
    while(!value) {
      attempts++;
      value = this.inputCaptcha.current.getValue();
      if(attempts > 10) {
        return false;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    this.inputCaptcha.current.reset();
    return value;
  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let value = await this.performCaptha();
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/apps',
        method:   'post',
        form:     {
  
          token,
          code: value,
          name: this.inputName.current.value,
          description: this.inputDescription.current.value,
          logo: this.state.upload || null,
          website: this.inputWebsite.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.props.history.push('/apps/' + result.data.slug);
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      section:        'uploading',
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=apps.logo&token=' + token);
      req.attach('file', files[0]);
      req.end((err, res) => {
        let data = JSON.parse(res.text);
        this.setState({
          logo:     data.url,
          upload:   data.uid
        });
      })
    });

  }
  render() {
    let banner = null;
    if(this.state.submitting === true) {
      banner = (<div>
        <div className="progress-overlay"></div>
        <div className="progress-wrapper">
          <div className="progress-box-container">
            <div className="progress-box"></div>
            <h3 className="progress-text">Create the group</h3>
          </div>
        </div>
      </div>);
    } else if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }

    return (

      <div>
        <HeaderView {...this.props} section='congregate' locality={this.state.locality}  user={this.state.user} state={this.state} />
        {banner}
        <div className="wrapper">
          <div className="congregate congregate-alt">
            <div className="hero-box">
              <h2 className="hero-heading">Create a new App</h2>
              <h2 className="hero-slogan">We need a bit of information to get you started</h2>
            </div>
            <div className="container">
              <div className="congregate-inner">

                <div className="details-box">
                    <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
                      {({getRootProps, getInputProps}) => (
                        <div onClick={(e) => {
                          e.stopPropagation();
                        }} className="details-box-preview">
                          <div {...getRootProps()}>
                          <p className="details-box-preview-text">We need a logo for your app</p>
                          <img alt="Preview of logo" src={this.state.logo || 'https://cdn.chipndoodle.com/defaults/record.png'} className="details-box-preview-img" />
                          <input {...getInputProps()} />
                          <p className="details-box-preview-text">Click here to upload logo</p>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  <div className="details-box-meta">

                    <form onSubmit={this.handleSubmit} className="details-box-controls">

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Name</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.name} ref={this.inputName} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <p>Short summary of who you are and what your app does</p>
                          <label htmlFor="nickname">Description</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <textarea ref={this.inputDescription}>{this.state.description}</textarea>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Website / Link</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.website} ref={this.inputWebsite} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                    </form>
                    <div className="details-box-toolbar">
                      <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.handleSubmit}>CREATE</Link>
                      <p className="details-box-toolbar-text"></p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={this.inputCaptcha} 
          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
          theme="light" 
          size="invisible"
          onExpired={this.onChallengeExpired} 
          onErrored={this.onChallengeErrored} 
        />
        <FooterView locality={this.state.locality} state={this.state} />
      </div>

    );
  }
}

export default View;