import React, { Component } from 'react';
import S from 'string';
import moment from 'moment';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import GoogleMapReact from 'google-map-react';
import './style.css';

const AnyReactComponent = ({ text }) => <span className="fas fa-circle" style={{
  color: '#7F3584',
  fontSize: 46
}}></span>;

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      group:            null,
      locality:         params.locality,
      user:             params.user,
      responseuid:      (params.match.params || {}).responseuid || null,
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading',
      method:           'upload'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCopyPaste = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let groupresult = null;

    // handle errors
    try {
      groupresult = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(groupresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = groupresult.data.name;

    // set the call
    let responseresult = null;

    // handle errors
    try {
      responseresult = await APIClient.call({

        path:   '/groups/' + groupresult.data.slug + '/responses/' + this.state.responseuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(responseresult.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the import
    this.setState({

      section:      'view',
      group:        groupresult.data,
      item:         responseresult.data

    });

  }
  getOperatingSystemIcon = (os) => {
    let ostxt = S(os).slugify().s;
    if(ostxt.indexOf('mac') !== -1) {
      return 'fab fa-apple';
    } else if(ostxt.indexOf('ios') !== -1) {
      return 'fab fa-apple';
    } else if(ostxt.indexOf('ipad') !== -1) {
      return 'fab fa-apple';
    } else if(ostxt.indexOf('windows') !== -1) {
      return 'fab fa-windows';
    } else if(ostxt.indexOf('ubuntu') !== -1) {
      return 'fab fa-ubuntu';
    } else if(ostxt.indexOf('suse') !== -1) {
      return 'fab fa-suse';
    } else if(ostxt.indexOf('redhat') !== -1) {
      return 'fab fa-redhat';
    } else if(ostxt.indexOf('fedora') !== -1) {
      return 'fab fa-fedora';
    } else if(ostxt.indexOf('centos') !== -1) {
      return 'fab fa-centos';
    } else if(ostxt.indexOf('linux') !== -1) {
      return 'fab fa-linux';
    } else {
      return 'fas fa-ghost';
    }
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupsearch">
                  <div className="container">
                    <div className="groupsearch-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          <div className="groupsearch">
            <div className="container">
              <div className="groupsearch-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let banner = null;
    if(this.state.errorMessage) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.group}></GroupMenuView>
        <div className="wrapper">
          {banner}
          <div className="groupsearch">

            <div className="hero-box">

              <h2 className="hero-heading">Search for #{this.state.item.chipnumber}</h2>
              <h2 className="hero-slogan">Created: {this.state.item.created}</h2>

            </div>
            <div className="container">
              <div className="groupsearch-inner">
                

                <div className="details-box details-box-first">
                  <div className="details-box-meta details-box-meta-full">

                    <div style={{ height: '35vh', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyDuidBMefKFW1znPnAZIur5S64CA9SXhCY' }}
                        defaultCenter={{
                          lat: this.state.item.lat,
                          lng: this.state.item.lng
                        }} 
                        options={{
                          mapTypeControl: false,
                          disableDefaultUI: true
                        }} 
                        defaultZoom={11} draggable={false}
                      >
                        <AnyReactComponent
                          lat={this.state.item.lat}
                          lng={this.state.item.lng}
                          text="My Marker"
                        />
                      </GoogleMapReact>
                    </div>

                    <div className="groupsearch-information-banner">
                      <p>Location is approximated from the users IP address</p>
                    </div>

                    <div className="groupsearch-columns">

                      <div className="groupsearch-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupsearch-column-icon">
                          <span className={"fas fa-city"}></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.city.name}</h4>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>City searched from</p>
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupsearch-column-icon">
                          <span className="fas fa-map-signs"></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.region.name}</h4>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Region they searched from</p>
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-bottom">
                        <div className="groupsearch-column-icon">
                          <img alt={this.state.item.country.name + ' flag'} src={this.state.item.country.flag}></img>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.country.name}</h4>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Country they searched from</p>
                        </div>
                      </div>

                      <div className="groupsearch-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupsearch-column-icon">
                          <span className={"fas fa-" + this.state.item.device}></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{S(this.state.item.device || '').trim().s.toUpperCase()}</h4>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Device used to search</p>
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupsearch-column-icon">
                          <span className={this.getOperatingSystemIcon(this.state.item.operatingsystem)}></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.operatingsystem}</h4>
                          <p>{this.state.item.release}</p>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Operating System they were running on their device</p>
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-bottom">
                        <div className="groupsearch-column-icon">
                          <span className={"fab fa-" + S(this.state.item.browser).slugify().s.split('-')[0]}></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.browser}</h4>
                          <p>{this.state.item.version}</p>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Browser</p>
                        </div>
                      </div>

                      <div className="groupsearch-column groupsearch-column-right groupsearch-column-padding-top">
                        <div className="groupsearch-column-icon">
                          <span className="fas fa-wifi"></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.remoteip}</h4>
                        </div>
                        <div className="groupsearch-column-desc">
                          <p>Remote IP Address</p>
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-right groupsearch-column-padding-top">
                        <div className="groupsearch-column-icon">
                          <span className="fas fa-clock"></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{moment(this.state.item.started).format('LLL')}</h4>
                          <p>Processed for {this.state.item.duration}ms</p>
                        </div>
                        <div className="groupsearch-column-desc">
                        </div>
                      </div>
                      <div className="groupsearch-column groupsearch-column-padding-top">
                        <div className="groupsearch-column-icon">
                          <span className="fas fa-network-wired"></span>
                        </div>
                        <div className="groupsearch-column-label">
                          <h4>{this.state.item.source ? this.state.item.source : 'chipndoodle.com'}</h4>
                          <p>Using the <strong>{this.state.item.medium}</strong></p>
                        </div>
                        <div className="groupsearch-column-desc">
                        </div>
                      </div>

                      <div className="clearfix"></div>
                    
                    </div>
                    <div className="clearfix"></div>

                  </div>
                  <div className="clearfix"></div>
                </div>

              </div>
            </div>

          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;