import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import S from 'string';
import './style.css';
import APIClient from '../../client';
import { slide as Menu } from 'react-burger-menu'

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      user:         params.user,
      backToLogin:  false,
      menuSection:  params.section || '',
      section:      params.section || ''

    };
    this.handleUserChange = params.handleUserChange;
  }
  handleLogout = (e) => {

    if(e) e.preventDefault();
    if(window.confirm('Really logout?') === false) return;
    APIClient.setToken(null);
    document.location = '/login';

  }
  render() {

    let menuItems = null;
    let mobileNavItems = null;
    let permissions = (this.state.user || {}).permissions || [];

    if(this.state.user && 
        this.state.user.uid) {
      menuItems = (<div className="header-nav">
        <Link to="/providers" className={"header-nav-item" + (this.state.section === 'providers' ? ' active' : '')}>Providers</Link>
        <Link to="/searches" className={"header-nav-item" + (this.state.section === 'me' ? ' active' : '')}>{S(this.state.user.name).truncate(19, '...').s}</Link>
        <a href="/" onClick={this.handleLogout} className={"header-nav-item" + (this.state.section === 'login' ? ' active' : '')}>
          <span className="fas fa-sign-out-alt"></span>
        </a>
        <div className="clearfix"></div>
      </div>);
      mobileNavItems = (<div className="header-nav-mobile-items">
        <Link to="/" className={"header-nav-mobile-item" + (this.state.menuSection === 'home' ? ' active' : '')}>
          <span className="fas fa-home"></span>
          <p>Home</p>
          <div className="clearfix"></div>
        </Link>
        {
            permissions.indexOf('admin') !== -1 || permissions.indexOf('features.animals') !== -1
          ?
            <Link to="/animals" className={"header-nav-mobile-item" + (this.state.menuSection === 'animals' ? ' active' : '')}>
              <span className="fas fa-paw"></span>
              <p>Animals</p>
              <div className="clearfix"></div>
            </Link>
          : 
            null
        }
        <Link to="/searches" className={"header-nav-mobile-item" + (this.state.menuSection === 'searches' ? ' active' : '')}>
          <span className="fas fa-search"></span>
          <p>Searches</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/groups" className={"header-nav-mobile-item" + (this.state.menuSection === 'groups' ? ' active' : '')}>
          <span className="fas fa-user-friends"></span>
          <p>Groups</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/providers" className={"header-nav-mobile-item" + (this.state.menuSection === 'providers' ? ' active' : '')}>
          <span className="fas fa-globe-africa"></span>
          <p>Explore Providers</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/profile" className={"header-nav-mobile-item" + (this.state.menuSection === 'profile' ? ' active' : '')}>
          <span className="fas fa-user"></span>
          <p>Profile</p>
          <div className="clearfix"></div>
        </Link>
        <a href="/" onClick={this.handleLogout} className={"header-nav-mobile-item" + (this.state.menuSection === 'login' ? ' active' : '')}>
          <span className="fas fa-sign-out-alt"></span>
          <p>Logout</p>
          <div className="clearfix"></div>
        </a>
        <div className="clearfix"></div>
      </div>);
    } else {
      menuItems = (<div className="header-nav">
        <Link to="/providers" className={"header-nav-item" + (this.state.section === 'providers' ? ' active' : '')}>Providers</Link>
        <Link to="/login" className={"header-nav-item" + (this.state.section === 'login' ? ' active' : '')}>Login</Link>
        <div className="clearfix"></div>
      </div>);
      mobileNavItems = (<div>
        <Link to="/" className={"header-nav-mobile-item" + (this.state.menuSection === 'home' ? ' active' : '')}>
          <span className="fas fa-search"></span>
          <p>Search</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/providers" className={"header-nav-mobile-item" + (this.state.menuSection === 'providers' ? ' active' : '')}>
          <span className="fas fa-globe-africa"></span>
          <p>Explore Providers</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/login" className={"header-nav-mobile-item" + (this.state.menuSection === 'login' ? ' active' : '')}>
          <span className="fas fa-sign-in-alt"></span>
          <p>Login</p>
          <div className="clearfix"></div>
        </Link>
        <Link to="/register" className={"header-nav-mobile-item" + (this.state.menuSection === 'register' ? ' active' : '')}>
          <span className="fas fa-user-plus"></span>
          <p>Create Account</p>
          <div className="clearfix"></div>
        </Link>
        <div className="clearfix"></div>
      </div>);
    }

    return (
      <div>
          <Menu right>
            {mobileNavItems}
          </Menu>
          <header className="header">
            <div className="container">
            {menuItems}
            <Link to="/" className="header-logo">
              <img alt="Logo shape" className="header-logo-img" src="https://cdn.chipndoodle.com/logo.light512.png" />
              <h2 className="header-logo-text">ChipnDoodle</h2>
              <div className="clearfix"></div>
            </Link>
            <div className="clearfix"></div>
          </div>
        </header>
      </div>
      
    );
  }
}

export default App;
