import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import querystring from 'querystring';
import './style.css';
const CONSTANTS = require('../../constants');

class View extends Component {
  constructor(params) {
    super(params);
    const parsedquery = querystring.parse(
      document.location.search.slice(1, document.location.search.length)
    );
    let chipnumber = parsedquery.chipnumber || parsedquery.uid || null;
    this.state = {

      upload:           null,
      locality:         params.locality,
      user:             params.user,
      errorMessage:     null,
      section:          'view',
      submitting:       false,
      chipnumber:       chipnumber,
      submissions:      0,
      page:             params.page || 'home'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputChipNumber = React.createRef();
    this.inputCaptcha = React.createRef();
    this.inputVideo = React.createRef();
  }
  componentDidMount = () => {
    document.title = 'Microchip Capture';
    if (!navigator.mediaDevices || 
          !navigator.mediaDevices.getUserMedia) {
      return this.setState({
        errorMessage: 'Camera not available',
        section: 'view'
      })
    }
    this.setState({
      section: 'camera'
    }, async () => {
      let stream = await navigator.mediaDevices.getUserMedia({ 
        video: { exact: "environment" }
      });
      this.inputVideo.current.srcObject = stream;
      this.inputVideo.current.play();
    });
  }
  render() {
    let content = null;
    content = (<div className="wrapper">
        <div className="capture">
          <div className="capture-hero">
            <div className="capture-inner">
              <div className="container">
                <p>TESTING</p>
                <h2>Read your reader</h2>

                <video ref={this.inputVideo} className="add-microchip-barcode-video"></video>
                <p>Capture a image of your microchip reader output</p>

                <Link to="/add" onClick={() => {
                  this.setState({
                    section: 'new'
                  }, this.renderBarCode);
                }} className="add-microchip-cta-button">SNAP THE PHOTO</Link>

              </div>
            </div>
          </div>
        </div>
      </div>);
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        {content}
        <ReCAPTCHA
          ref={this.inputCaptcha} 
          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
          theme="light" 
          size="invisible"
          onExpired={this.onChallengeExpired} 
          onErrored={this.onChallengeErrored} 
        />
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;