import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import accounting from 'accounting';
import Constants from '../../constants';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';

const AnyReactComponent = ({ text }) => <span className="fas fa-map-marker" style={{
  color: '#7F3584',
  fontSize: 24
}}></span>;

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item: null,
      locality: params.locality,
      user: params.user,
      loading: true,
      groupuid: (params.match.params || {}).groupuid || null,
      section: 'loading',
      dataset: null,

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path: '/groups/' + this.state.groupuid,
        query: {
          token
        }

      });
    } catch (err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if (result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = result.data.name;

    // default to a dataset that makes sense
    let defaultDataset = 'records';

    // check the items
    if (result.data.records === 0 && result.data.found > 0) {
      defaultDataset = 'searches';
    } else if (result.data.found === 0 && result.data.records > 0) {
      defaultDataset = 'records';
    }

    // set the view state
    this.setState({

      section: 'view',
      item: result.data,
      loading: true

    }, async () => {

      await this.handleDatasetSelect(defaultDataset);

    });

  }
  handleDatasetSelect = async (dataset) => {

    let stateparams = {

      dataset: dataset,
      loading: false

    };

    if (dataset === 'searches') {

      let results = await Promise.all([
        this.fetchSearchMarkers()
      ]);

      stateparams.markers = results[0] || [];

    } else if (dataset === 'records') {

      let results = await Promise.all([
        this.fetchRecordsMarkers(),
        this.fetchRecordsStats(),
      ]);

      stateparams.markers = results[0] || [];
      stateparams.recordstats = results[1] || [];

    }

    this.setState(stateparams)

  }
  fetchRecordsStats = async () => {

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
    };

    // handle errors
    try {

      let result = await APIClient.call({

        path: '/groups/' + this.state.item.slug + '/records/stats',
        query: query

      });
      pulleditems = result.data || [];

    } catch (err) { }

    // done
    return pulleditems;

  }
  fetchRecordsMarkers = async () => {

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
    };

    // handle errors
    try {

      let result = await APIClient.call({

        path: '/groups/' + this.state.item.slug + '/records/markers',
        query: query

      });
      pulleditems = result.data || [];

    } catch (err) { }

    // done
    return pulleditems;

  }
  fetchSearchMarkers = async () => {

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
    };

    // handle errors
    try {

      let result = await APIClient.call({

        path: '/groups/' + this.state.item.slug + '/responses/markers',
        query: query

      });
      pulleditems = result.data || [];

    } catch (err) { }

    // done
    return pulleditems;

  }
  renderSearchesOverview = () => {
    if (this.state.dataset !== 'searches') return null;
    if (this.state.item.found <= 0) {
      return (<div className="groupoverview-items-blank">
        <span className="fas fa-search"></span>
        <p>Once you get a few successfull lookups on your data we'll show a summary of your search audience</p>
        <div className="clearfix"></div>
      </div>);
    }
    return (<div className="groupoverview-items-blank">
      <span className="fas fa-search"></span>
      <p>Currently building out, check back soon for some stats on your group searches :)</p>
      <div className="clearfix"></div>
    </div>);
  }
  renderRecordSexColumn = (items, opts) => {
    return (<div className="groupoverview-records-grounping-column">
      <p>Column here</p>
      <p><span className="fas fa-male"></span></p>
    </div>)
  }
  renderRecordsOverview = () => {
    if (this.state.dataset !== 'records') return null;
    if (this.state.item.records <= 0) {
      return (<div className="groupoverview-items-blank">
        <span className="fas fa-paw"></span>
        <p>Once records are created we'll start showing a summary of the records here, and the map record addresses</p>
        <div className="clearfix"></div>
      </div>);
    }
    let species = [];
    for (let i = 0; i < this.state.recordstats.length; i++) {
      let recordstat = this.state.recordstats[i];
      if (species.indexOf(recordstat.species) !== -1) continue;
      species.push(recordstat.species);
    }
    return (<div>

      <p>species:</p>
      {
        species.map((speciesName) => {
          return (<Link>
            <span className={"fas fa-" + speciesName}></span>
            {speciesName}
          </Link>)
        })
      }
      <p>items</p>

    </div>);
  }
  render() {
    if (this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if (this.state.section === 'error') {
      return (<div className="wrapper">
        <div className="groupoverview">
          <div className="container">
            <div className="groupoverview-inner">
              <p>Something went wrong</p>
            </div>
          </div>
        </div>
      </div>);
    } else if (this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality} user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupoverview">
            <div className="container">
              <div className="groupoverview-inner">

                <p>No such pet or you are not allowed to access</p>
                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let heatmapPositions = [];
    let markerPositions = [];

    if (this.state.dataset === 'searches') {

      for (let i = 0; i < (this.state.markers || []).length; i++) {

        let marker = this.state.markers[i];
        heatmapPositions.push({

          lat: marker.lat,
          lng: marker.lng,
          weight: parseInt(marker.count)

        });

      }

    } else if (this.state.dataset === 'records') {

      for (let i = 0; i < (this.state.markers || []).length; i++) {
        let marker = this.state.markers[i];
        let lat = marker.lat;
        if (lat > 0) {
          lat = lat - 0.0025;
        } else if (lat < 0) {
          lat = lat + 0.0025;
        }
        markerPositions.push(
          <AnyReactComponent
            lat={lat}
            lng={marker.lng}
            text="My Marker"
          />);
      }

    }

    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item} section="overview"></GroupMenuView>
        <div className="wrapper">
          <div className="groupoverview">

            <div style={{ height: '60vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: Constants.GOOGLE_MAPS_API_KEY }}
                defaultCenter={{
                  lat: this.state.item.lat || 0,
                  lng: this.state.item.lng || 0
                }}
                options={{
                  styles: Constants.MAP_STYLES,
                  mapTypeControl: false,
                  disableDefaultUI: false,
                }}
                defaultZoom={5}
                draggable={true}
                heatmapLibrary={true}
                heatmap={{
                  positions: heatmapPositions,
                  options: {
                    radius: 20,
                    opacity: 0.6
                  }
                }}
              >
                {markerPositions}
              </GoogleMapReact>
            </div>

            <div className="container">
              <div className="groupoverview-inner">

                <div className="details-box">
                  <div className="details-box-meta details-box-meta-full">

                    <div className="groupoverview-columns">

                      <div className="groupoverview-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupoverview-column-icon">
                          <span className={"fas fa-paw"}></span>
                        </div>
                        <div className="groupoverview-column-label">
                          <h4>{accounting.formatNumber(this.state.item.records)}</h4>
                          <p>Records</p>
                        </div>
                        <div className="groupoverview-column-desc">
                          <p>Managed on ChipnDoodle</p>
                        </div>
                      </div>
                      <div className="groupoverview-column groupsearch-column-bottom groupsearch-column-right">
                        <div className="groupoverview-column-icon">
                          <span className={"fas fa-search"}></span>
                        </div>
                        <div className="groupoverview-column-label">
                          <h4>{accounting.formatNumber(this.state.item.found)}</h4>
                          <p>Searches</p>
                        </div>
                        <div className="groupoverview-column-desc">
                          <p>Successful searches from us</p>
                        </div>
                      </div>
                      <div className="groupoverview-column groupsearch-column-bottom">
                        <div className="groupoverview-column-icon">
                          <span className={"fas fa-users"}></span>
                        </div>
                        <div className="groupoverview-column-label">
                          <h4>{accounting.formatNumber(this.state.item.members)}</h4>
                          <p>Members</p>
                        </div>
                        <div className="groupoverview-column-desc">
                          <p>Have access to group data</p>
                        </div>
                      </div>

                      <div className="clearfix"></div>

                    </div>
                    <div className="clearfix"></div>

                    {/* <div className="groupoverview-datasets">
                      <a href={'/groups/' + this.state.item.slug + '#records'} className={[
                        "groupoverview-dataset",
                        this.state.dataset === 'records' ? 'active' : ''
                      ].join(' ')} onClick={(e) => {
                        if(e) e.preventDefault();
                        this.handleDatasetSelect('records');
                      }}>Information about Records</a>
                      <a href={'/groups/' + this.state.item.slug + '#searches'} className={[
                        "groupoverview-dataset",
                        this.state.dataset === 'searches' ? 'active' : ''
                      ].join(' ')} onClick={(e) => {
                        if(e) e.preventDefault();
                        this.handleDatasetSelect('searches');
                      }}>Information about Searches</a>
                      <div className="clearfix"></div>
                    </div>

                    {this.renderSearchesOverview()}
                    {this.renderRecordsOverview()} */}

                  </div>
                  <div className="clearfix"></div>
                </div>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;