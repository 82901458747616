import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import superagent from 'superagent';
import Dropzone from 'react-dropzone';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import GroupMenuView from '../../widgets/groupmenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item:             null,
      locality:         params.locality,
      user:             params.user,
      groupuid:         (params.match.params || {}).groupuid || null,
      section:          'loading',
      method:           'upload'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCopyPaste = React.createRef();
  }
  componentDidMount = async () => {

    // set the groups
    document.title = '' + this.state.groupuid;

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups/' + this.state.groupuid,
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the groups
    document.title = result.data.name;

    this.setState({

      section:      'view',
      item:         result.data

    });

  }
  handleSubmit = async (e) => {
    if(e) e.preventDefault();
    if(this.state.submitting === true) return;
    let token = APIClient.getToken();
    this.setState({

      submitting:     true,
      errorMessage:   null,
      submissions:    this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({
        
        path:     '/groups/' + this.state.item.slug + '/imports',
        method:   'post',
        timeout:  60 * 2,
        form:     {
  
          token,
          method:   this.state.method,
          upload:   this.state.upload
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.props.history.push('/groups/' + this.state.item.slug + '/imports/' + result.data.uid);
  }
  onHandleFormSubmit = (e) => {
    if(e) e.preventDefault();
    if(this.state.method === 'copypaste') {
      this.onCopyPasteSubmit();
    } else {
      this.handleSubmit();
    }
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      uploading:      true,
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=groups.import&token=' + token);
      req.attach('file', files[0]);
      req.end((err, res) => {
        let data = JSON.parse(res.text);
        this.setState({
          
          uploading:  false,
          upload:     data.uid

        }, () => {
          this.handleSubmit()
        });
      })
    });

  }
  onCopyPasteSubmit = () => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      uploading:      true,
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=groups.import&token=' + token);
      let blob = new Blob([
        
        this.inputCopyPaste.current.value

      ], { type: "text/plain" });
      req.attach('file', blob);
      req.end((err, res) => {
        let data = JSON.parse(res.text);
        this.setState({
          
          uploading:  false,
          upload:     data.uid

        }, () => {
          
          this.handleSubmit();

        });
      })
    });

  }
  renderUploadMethod = () => {
    if(this.state.method !== 'upload') return;
    return (<div>
      <div className="details-box-control details-box-control">
        <div className="details-box-control-label">
          <p>Max 40mb - can be Excel, CSV or TXT</p>
          <label htmlFor="nickname">File to import </label>
        </div>
        <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
          {({getRootProps, getInputProps}) => (
            <div onClick={(e) => {
              e.stopPropagation();
            }} className="details-box-control-inputs">
              <div {...getRootProps()}>
                <input {...getInputProps()} type="file"  />
                <div className="groupimportadd-blank">
                  <div className="groupimportadd-blank-inner">
                    <span className="fas fa-file-upload"></span>
                    <p>drop your file here or click to upload</p>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="clearfix"></div>
      </div>
    </div>);
  }
  renderCopyPasteMethod = () => {
    if(this.state.method !== 'copypaste') return;
    return (<div>
      <div className="details-box-control details-box-control">
        <div className="details-box-control-label">
          <label htmlFor="nickname">Details to import </label>
        </div>
        <div className="details-box-control-inputs">
          <textarea style={{
            minHeight: 300
          }} ref={this.inputCopyPaste}></textarea>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>);
  }
  render() {
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'error') {
      return (<div className="wrapper">
                <div className="groupimportadd">
                  <div className="container">
                    <div className="groupimportadd-inner">
                      <p>Something went wrong</p>
                    </div>
                  </div>
                </div>
              </div>);
    } else if(this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="groupimportadd">
            <div className="container">
              <div className="groupimportadd-inner">

                <p>No such pet or you are not allowed to access</p>

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    let banner = null;
    if(this.state.errorMessage) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <GroupMenuView user={this.state.user} group={this.state.item}></GroupMenuView>
        <div className="wrapper">
          {banner}
          <div className="groupimportadd">

            <div className="hero-box">
              <h2 className="hero-heading">Bulk Import</h2>
              <h2 className="hero-slogan">Import thousands of microchips in a instant</h2>
            </div>
            <div className="container">
              <div className="groupimportadd-inner">

                <div className="details-box">
                    <div className="details-box-meta details-box-meta-full">

                      <form onSubmit={this.onHandleFormSubmit} className="details-box-controls">

                        <div className="add-details-box-control">
                          <div className="add-details-box-control-label">
                            <p>Select how you will input the numbers</p>
                            <label htmlFor="nickname">Method of Import</label>
                          </div>
                          <div className="add-details-box-control-inputs">
                            <div className="add-details-box-control-selectables">
                              <Link onClick={() => {
                                this.setState({
                                  method: 'upload'
                                })
                              }} className={"add-details-box-control-selectable" + (this.state.method === "upload" ? " active" : "")}>UPLOAD FILE</Link>
                              <Link onClick={() => {
                                this.setState({
                                  method: 'copypaste'
                                })
                              }} className={"add-details-box-control-selectable" + (this.state.method === "copypaste" ? " active" : "")}>COPY/PASTE</Link>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>

                        <div className="clearfix"></div>

                        {this.renderCopyPasteMethod()}
                        {this.renderUploadMethod()}

                        <div className="clearfix"></div>

                      </form>
                        
                      <div className="details-box-toolbar">
                        <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={this.onHandleFormSubmit}>CREATE</Link>
                        <p className="details-box-toolbar-text"></p>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

              </div>
            </div>

          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;