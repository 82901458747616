import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
const CONSTANTS = require('../../constants');

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      user:           params.user,
      errorMessage:   null,
      submitting:     false,
      showConfirm:    false

    };
    this.handleUserChange = params.handleUserChange;
    this.inputName = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPassword = React.createRef();
    this.inputCaptcha = React.createRef();
  }
  componentDidMount = () => {
    
    this.handleUserChange(null);
    this.setState({ user: null })
    document.title = "Register";

  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  startChallenge = (e) => {

    if(e) e.preventDefault();
    this.inputCaptcha.current.execute();

  }
  handleSubmit = async (value) => {
    if(this.state.submitting === true) return;
    this.setState({

      submitting:   true

    })
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/register',
        method:   'post',
        form:     {
  
          code:  value,
          name: this.inputName.current.value,
          email: this.inputEmail.current.value,
          password: this.inputPassword.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }
    
    APIClient.setToken(result.data.token);
    this.handleUserChange(result.data.user);
    this.props.history.push('/searches');
  }
  render() {
    let banner = null;
    if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }

    return (

      <div>
        <HeaderView {...this.props} section='register' locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="">
          <div className="login">
            {banner}
            <div className="container">
              <div className="login-inner">

                <div className="login-dialog">

                <div className="cat">
                  <div className="body"></div>
                  <div className="head">
                    <div className="ear"></div>
                  <div className="ear"></div>
                  </div>
                  <div className="face">
                    <div className="nose"></div>
                  <div className="whisker-container">
                    <div className="whisker"></div>
                    <div className="whisker"></div>
                  </div>
                  <div className="whisker-container">
                    <div className="whisker"></div>
                    <div className="whisker"></div>
                  </div>
                  </div>
                  <div className="tail-container">
                    <div className="tail">
                      <div className="tail">
                        <div className="tail">
                          <div className="tail">
                            <div className="tail">
                              <div className="tail">
                                <div className="tail"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                  <form onSubmit={this.handleSubmit} className="container">
                    <div className="clearfix"></div>
                    <div className="login-form">
                      <div className="login-form-control">
                        <label htmlFor="email">Name</label>
                        <input ref={ this.inputName } name="name" type="text" placeholder="your name" />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <label htmlFor="email">Email</label>
                        <input ref={ this.inputEmail } name="email" type="email" placeholder="your email" />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <label htmlFor="password">Password</label>
                        <input ref={ this.inputPassword } name="password" type="password" placeholder="your password" />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <ReCAPTCHA
                          ref={this.inputCaptcha} 
                          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
                          theme="light" 
                          size="invisible"
                          onChange={this.handleSubmit}
                          onExpired={this.onChallengeExpired} 
                          onErrored={this.onChallengeErrored} 
                        />
                        <div className="clearfix"></div>
                      </div>
                      <div className="login-form-control">
                        <Link to="/login">Login instead</Link>
                        <button onClick={this.startChallenge}>Register</button>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </form>

                </div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>

    );
  }
}

export default View;