import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import S from 'string';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import Constants from '../../constants';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ProviderItem from '../../widgets/provideritem';
import './style.css';

const DatabaseMarker = ({ place }) => <span className="fas fa-circle" style={{
  color: '#7F3584',
  // color: 'red',
  fontSize: 34,
  cursor: 'pointer',
  opacity: 1
}}></span>;

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      item: null,
      locality: params.locality,
      user: params.user,
      groupslug: (params.match.params || {}).groupslug || null,
      section: 'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the animals
    document.title = 'Provider';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let itemdata = null;

    // handle errors
    try {
      itemdata = await APIClient.call({

        path: '/providers/' + this.state.groupslug,
        query: {
          token
        }

      });
    } catch (err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if (itemdata.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    // set the animals
    document.title = itemdata.data.name;

    // get the providers in the continent
    let providersdata = null;

    let engines = [];
    let otherproviders = [];

    try {
      // load all the other options
      let results = await Promise.all([
        APIClient.call({

          path: '/engines',
          query: {
            token,
            limit: 6,
            provider: itemdata.data.uid
          }

        }),
        APIClient.call({

          path: '/providers',
          query: {
            token,
            limit: 9,
            continent: itemdata.data.continent.code
          }

        })
      ]);
      if (results[0].ok === true) {
        engines = results[0].data || [];
      }
      if (results[1].ok === true) {
        otherproviders = results[1].data || [];
      }
    } catch (err) {
      console.error(err)
    }

    this.setState({

      section: 'view',
      engines: engines,
      otherproviders: otherproviders,
      item: itemdata.data,
      items: (providersdata || {}).data || []

    });

  }
  formatURL = (text) => {
    text = text.toLowerCase();
    text = text.replace(/http:\/\/www\./gi, '');
    text = text.replace(/https:\/\/www\./gi, '');
    text = text.replace(/http:\/\//gi, '');
    text = text.replace(/https:\/\//gi, '');
    text = text.split('/')[0];
    return S(text).truncate(25, '..').s;
  }
  formatDescription = (item) => {
    if (item.website) {
      let text = item.website;
      text = text.toLowerCase();
      text = text.replace(/http:\/\/www\./gi, '');
      text = text.replace(/https:\/\/www\./gi, '');
      text = text.replace(/http:\/\//gi, '');
      text = text.replace(/https:\/\//gi, '');
      text = text.split('/')[0];
      return S(text).truncate(18, '..').s;
    } else {
      return 'since ' + moment(item.created).format('LLL')
    }
  }
  renderEngines = () => {
    if ((this.state.engines || []).length === 0) return;
    return (<div className="database-engines">
      <h3 className="database-content-heading">Other Search Engines for this provider:</h3>
      {
        this.state.engines.map((localitem) => {
          return <div className="database-engine">
            <a href={localitem.link} className="database-engine-inner">
              <h3 className="database-engine-heading">{localitem.name}</h3>
              <p className="database-engine-slogan">Maintained by {localitem.maintainer}</p>
              <p className="database-engine-desc">{localitem.description}</p>
            </a>
          </div>
        })
      }
    </div>);
  }
  renderOtherProviders = () => {
    if ((this.state.otherproviders || []).length === 0) return;
    return (<div>
      <div className="clearfix"></div>
      <h3 className="database-listing-heading">Browse more providers from the region:</h3>
      <div className="clearfix"></div>
      <div className="database-listing-items">
        {
          this.state.otherproviders.map((localitem) => {
            if (localitem.uid === this.state.item.uid) return;
            return <ProviderItem size="third" item={localitem} />
          })
        }
        <div className="clearfix"></div>
      </div>
    </div>)
  }
  renderMap = () => {
    return null
    const item = this.state.item;
    return (<div className="details-box details-box-top">
      <div className="details-box-meta details-box-meta-full">

        <div className="database-map">
          <GoogleMapReact
            bootstrapURLKeys={{

              key: Constants.GOOGLE_MAPS_API_KEY,
              libraries: [],

            }}
            defaultCenter={{

              lat: item.lat,
              lng: item.lng,

            }}
            options={{

              panControl: false,
              mapTypeControl: false,
              disableDefaultUI: true,
              disableZoomControl: false,
              styles: Constants.MAP_STYLES,
              minZoom: 8,
              maxZoom: 13,
              backgroundColor: 'none',

            }}
            defaultZoom={8}
            draggable={false}

          >
            <DatabaseMarker key={item.uid} lat={item.lat} lng={item.lng} item={item} />
          </GoogleMapReact>
        </div>

      </div>
    </div>)
  }
  render() {
    if (this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if (this.state.section === 'error') {
      return (<div className="wrapper">
        <div className="animal">
          <div className="container">
            <div className="animal-inner">
              <p>Something went wrong</p>
            </div>
          </div>
        </div>
      </div>);
    } else if (this.state.section === 'notfound') {
      return (<div>
        <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="animal">
            <div className="container">
              <div className="animal-inner">
                <p>No such registered database</p>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="database">
            <div className="hero-box">
              <h2 className="hero-heading">{this.state.item.name}</h2>
              <h2 className="hero-slogan">One of the databases we attempt to search over {this.state.item.continent.name}</h2>
            </div>
            <div className="container">
              <div className="profile-inner">

                <div className="details-box">
                  <div className="details-box-preview">
                    <img alt="Database Logo" src={this.state.item.logo} />
                  </div>
                  <div className="details-box-meta">

                    {

                      this.state.item.alert
                        ?
                        <div className="details-box-toolbar details-box-toolbar-bad">
                          <p className="details-box-toolbar-text">
                            <span className="fas fa-info-circle"></span>
                            {this.state.item.alert}
                          </p>
                          <div className="clearfix"></div>
                        </div>
                        :
                        null

                    }

                    <div className="database-text">

                      <h1 className="database-heading">{this.state.item.name}</h1>

                      <p className="database-desc">{this.state.item.description}</p>
                      <div className="clearfix"></div>

                      {

                        this.state.item.prefixes && this.state.item.prefixes.length > 0
                          ?
                          <p className="database-prefixes">Known registered prefixes include: <strong>{this.state.item.prefixes.join(',')}</strong>. If your microchip is registered with a code prefixed like this there is a big chance this company sold it.</p>
                          : null

                      }

                      <div className="clearfix"></div>
                      {this.renderEngines()}
                      <div className="clearfix"></div>

                      <h3 className="database-content-heading">Registered Details:</h3>

                      <div className="database-actions">

                        <a href={this.state.item.website} target="_blank" rel="noopener noreferrer" className="database-action">
                          <div className="database-action-icon">
                            <span className="fas fa-link"></span>
                          </div>
                          <div className="database-action-meta">
                            <h4>{this.formatURL(this.state.item.website)}</h4>
                            <p>Visit their website for more information</p>
                          </div>
                          <div className="clearfix"></div>
                        </a>

                        <div className="clearfix"></div>

                        <a href={'tel:' + this.state.item.phonenumber} className="database-action">
                          <div className="database-action-icon">
                            <span className="fas fa-phone"></span>
                          </div>
                          <div className="database-action-meta">
                            <h4>{this.state.item.phonenumber}</h4>
                            <p>Give them a call</p>
                          </div>
                          <div className="clearfix"></div>
                        </a>

                        <div className="clearfix"></div>


                        {

                          this.state.item.email
                            ?
                            <a href={'mailto:' + this.state.item.email} className="database-action">
                              <div className="database-action-icon">
                                <span className="fas fa-envelope-open-text"></span>
                              </div>
                              <div className="database-action-meta">
                                <h4>{S(this.state.item.email).truncate(24, '...').s.toLowerCase()}</h4>
                                <p>Email enquiries here</p>
                              </div>
                              <div className="clearfix"></div>
                            </a>
                            : null

                        }

                        <div className="clearfix"></div>

                        <Link to={'/providers?country=' + this.state.item.country.code} className="database-action">
                          <div className="database-action-icon">
                            <img alt={this.state.item.country.name + ' Flag'} src={'https://cdn.chipndoodle.com/flags/' + this.state.item.country.code + '.png'} />
                          </div>
                          <div className="database-action-meta">
                            <h4>{this.state.item.country.name}</h4>
                            <p>Service Country</p>
                          </div>
                          <div className="clearfix"></div>
                        </Link>

                        <div className="clearfix"></div>

                        <Link to={'/providers?continent=' + this.state.item.continent.code} className="database-action">
                          <div className="database-action-icon">
                            <img alt={this.state.item.continent.name + ' landmass'} src={'https://cdn.chipndoodle.com/continents/' + this.state.item.continent.code + '.dark.svg'} />
                          </div>
                          <div className="database-action-meta">
                            <h4>{this.state.item.continent.name}</h4>
                            <p>Service Area</p>
                          </div>
                          <div className="clearfix"></div>
                        </Link>

                        <div className="clearfix"></div>

                      </div>
                      <div className="clearfix"></div>

                    </div>

                  </div>
                  <div className="clearfix"></div>
                </div>
                {this.renderMap()}
                {this.renderOtherProviders()}

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;