import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import S from 'string';
import accounting from 'accounting';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import UserMenuView from '../../widgets/usermenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      items:            [],
      locality:         params.locality,
      user:             params.user,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the animals
    document.title = 'Groups';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/groups',
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    this.setState({

      section:      'loaded',
      items:        result.data

    });

  }
  render() {
    let content = null;
    let items = this.state.items || [];
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(items.length > 0) {
      content = (<div>
        <UserMenuView user={this.state.user} section="groups"></UserMenuView>
        <div className="wrapper">
          <div className="groups">
            <div className="container">
              <div className="groups-inner">

                <div className="groups-controlbar">

                  <div className="groups-controlbar-meta">
                    <h2 className="groups-controlbar-heading">Groups</h2>
                    <p className="groups-controlbar-slogan">View the managed groups</p>
                  </div>
                  <div className="groups-controlbar-actions">
                    {/* <Link className="groups-cta groups-controlbar-cta" to={"/congregate"}>New</Link> */}
                  </div>
                  <div className="clearfix"></div>

                </div>

                <div className="groups-listing-items">
                  {
                    items.map((item) => {
                      let locationlabels = [];
                      if(item.country && item.country.name) locationlabels.push(item.country.name);
                      if(item.region && item.region.name) locationlabels.push(item.region.name);
                      if(item.city && item.city.name) locationlabels.push(item.city.name);
                      return <div className="groups-listing-item">
                        <Link title={item.name} className="groups-listing-item-inner" to={"/groups/" + item.slug}>
                          <div className="groups-listing-item-preview">
                            <img alt="Group Logo" className="groups-listing-item-image" src={item.logo || 'https://cdn.chipndoodle.com/defaults/record.png'} />
                          </div>
                          <div className="groups-listing-item-meta">
                            <div className="groups-listing-item-meta-labels">

                              <h3>{S(item.name).truncate(20, '...').s}</h3>
                              <p>
                                <img alt={item.country.name + ' flag'} src={item.country.flag} />
                                {S(locationlabels.join(', ')).truncate(30, '...').s}
                              </p>

                            </div>
                            <div className="groups-listing-item-meta-column">
                              <p className="groups-listing-item-meta-column-counter">{accounting.formatNumber(item.records)}</p>
                              <p className="groups-listing-item-meta-column-label">Records Managed on ChipnDoodle</p>
                              <div className="clearfix"></div>
                            </div>
                            <div className="groups-listing-item-meta-column">
                              <p className="groups-listing-item-meta-column-counter">{accounting.formatNumber(item.found)}</p>
                              <p className="groups-listing-item-meta-column-label">Successful searches from us</p>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </Link>
                      </div>;
                    })
                  }
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    } else {
      content = (<div>
        <UserMenuView user={this.state.user} section="groups"></UserMenuView>
        <div className="wrapper">
          <div className="groups">
            <div className="container">
              <div className="groups-inner">

                <div className="groups-items-blank">
                  <span className="fas fa-users"></span>
                  <p>Build your own database of microchips in seconds and join a growing community, register your own group to manage your microchips and make the numbers visible in all our searches. Contact us at info@chipndoodle.com to register your database today for easy management!</p>
                  <div className="clearfix"></div>
                  {/* <Link className="groups-cta groups-items-blank-cta" to={"/congregate"}>start here and create your own</Link> */}
                  <div className="clearfix"></div>
                </div>
                
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} section="me"  user={this.state.user} state={this.state} />
        {content}
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;