import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      locality:         params.locality,
      user:             params.user,

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = () => {
    document.title = 'Branding Resources';
  }
  render() {
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="brand">
            <div className="hero-box">
              <h2 className="hero-heading">Brand Guide</h2>
              <h2 className="hero-slogan">Assets for your site when talking about ChipnDoodle</h2>
            </div>
            <div className="container">
              <div className="profile-inner">

                <div className="details-box">
                  <div className="details-box-toolbar details-box-toolbar-bad">
                    <p className="details-box-toolbar-text">
                      <span className="fas fa-info-circle"></span>
                      Writting or building something about/with us? Please do get in touch we'd love to hear what you are busy with at info@chipndoodle.com
                    </p>
                    <div className="clearfix"></div>
                  </div>
                  <div className="details-box-meta details-box-meta-full">
                    <div className="brand-content">
                      <h1 className="brand-heading">Branding Resources</h1>
                      <p className="brand-text">We love when the community is engaging with us and wanted to provide all the assets that might be usefull along with explaining our goals/mission. What follows is all our logos/slogans for easy download and reference. <strong>You have our commitment to keep these image links always working if you link directly.</strong></p>
                      <h2 className="brand-subheading">Name</h2>
                      <p className="brand-text">ChipnDoodle should always be written as <code>ChipnDoodle</code> to keep it consistent for our users. Our logos and all external communications will be written this way, you are free to lowercase if needed but please keep in mind the capitalization when writing a name.</p>
                      <h2 className="brand-subheading">Color Palette</h2>
                      <p className="brand-text">Our service makes use of a few colors that all have a specific reason in our styling:</p>

                      <table className="brand-table">
                        <tbody>
                          <tr>
                            <td style={{
                              width: '10%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-block" style={{
                                backgroundColor: '#7F3584'
                              }}></div>
                              <div className="clearfix"></div>
                            </td>
                            <td style={{
                              width: '7%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-hex" style={{
                                color: '#7F3584'
                              }}>#7F3584</div>
                              <div className="clearfix"></div>
                            </td>
                            <td>Our primary branding color, the purple you'll probably see anytime you see our logo and use our site.</td>
                          </tr>

                          <tr>
                            <td style={{
                              width: '10%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-block" style={{
                                backgroundColor: '#faed85'
                              }}></div>
                              <div className="clearfix"></div>
                            </td>
                            <td style={{
                              width: '7%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-hex" style={{
                                color: '#faed85'
                              }}>#faed85</div>
                              <div className="clearfix"></div>
                            </td>
                            <td>Warning message/block color when displaying user information</td>
                          </tr>

                          <tr>
                            <td style={{
                              width: '10%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-block" style={{
                                backgroundColor: '#9d0208'
                              }}></div>
                              <div className="clearfix"></div>
                            </td>
                            <td style={{
                              width: '7%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-hex" style={{
                                color: '#9d0208'
                              }}>#9d0208</div>
                              <div className="clearfix"></div>
                            </td>
                            <td>Error message/block color when stuff goes wrong</td>
                          </tr>

                          <tr>
                            <td style={{
                              width: '10%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-block" style={{
                                backgroundColor: '#F0F1F4'
                              }}></div>
                              <div className="clearfix"></div>
                            </td>
                            <td style={{
                              width: '7%',
                              textAlign: 'center'
                            }}>
                              <div className="brand-color-hex" style={{
                                color: '#666'
                              }}>#F0F1F4</div>
                              <div className="clearfix"></div>
                            </td>
                            <td>Background white used when applicable; for example on websites.</td>
                          </tr>
                        </tbody>
                      </table>

                      <h2 className="brand-subheading">Image Logos</h2>
                      <div className="brand-section-columns">
                        <div className="clearfix"></div>
                        <div className="brand-section-column brand-section-column-preview">

                          <img alt="ChipnDoodle Logo" className="brand-preview brand-height-restricted" src={"https://cdn.chipndoodle.com/logo.png"} />
                          <p className="brand-section-column-preview-usage">Use on a white background where contrast is not a issue</p>

                        </div>
                        <div className="brand-section-column brand-section-column-links">

                          <table className="brand-table">
                            <tbody>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>512px x 512px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo512.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>256px x 256px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo256.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>128px x 128px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo128.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>64px x 64px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo64.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="brand-section-columns">
                        <div className="clearfix"></div>
                        <div className="brand-section-column brand-section-column-links">

                          <table className="brand-table">
                            <tbody>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>512px x 512px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.light512.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>256px x 256px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.light256.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>128px x 128px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.light128.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>64px x 64px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.light64.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="brand-section-column brand-section-column-preview">

                          <img alt="ChipnDoodle Logo" className="brand-preview brand-height-restricted" src={"https://cdn.chipndoodle.com/logo.light.png"} />
                          <p className="brand-section-column-preview-usage">To be used when the background is not white/light</p>

                        </div>
                        <div className="clearfix"></div>
                      </div>
                      
                      <div className="brand-section-columns">
                        <div className="clearfix"></div>
                        <div className="brand-section-column brand-section-column-preview">

                          <img alt="ChipnDoodle Logo" className="brand-preview brand-height-restricted" src={"https://cdn.chipndoodle.com/logo.block.png"} />
                          <p className="brand-section-column-preview-usage">Use when background is required to ensure branding</p>

                        </div>
                        <div className="brand-section-column brand-section-column-links">

                          <table className="brand-table">
                            <tbody>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>512px x 512px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.block512.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>256px x 256px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.block256.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>128px x 128px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.block128.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>64px x 64px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/logo.block64.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="clearfix"></div>
                      </div>
                                
                      <h2 className="brand-subheading">Text Logos</h2>
                      
                      <div className="brand-section-columns">
                        <div className="clearfix"></div>
                        <div className="brand-section-column brand-section-column-preview">

                          <img alt="ChipnDoodle Logo" className="brand-preview brand-width-restricted" src={"https://cdn.chipndoodle.com/text.png"} />
                          <p className="brand-section-column-preview-usage">Use on a white background where contrast is not a issue</p>

                        </div>
                        <div className="brand-section-column brand-section-column-links">

                          <table className="brand-table">
                            <tbody>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>512px x 512px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text512.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>256px x 256px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text256.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>128px x 128px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text128.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>64px x 64px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text64.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="brand-section-columns">
                        <div className="clearfix"></div>
                        <div className="brand-section-column brand-section-column-links">

                          <table className="brand-table">
                            <tbody>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>512px x 512px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text.light512.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>256px x 256px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text.light256.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>128px x 128px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text.light128.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                              <tr>
                                <td style={{
                                  width: '5%',
                                }}></td>
                                <td>64px x 64px</td>
                                <td style={{
                                  width: '27%',
                                  textAlign: 'center'
                                }}>
                                  <a href="https://cdn.chipndoodle.com/text.light64.png" target="_blank">Download</a>
                                </td>
                                <td style={{
                                  width: '5%',
                                }}></td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div className="brand-section-column brand-section-column-preview">

                          <img alt="ChipnDoodle Logo" className="brand-preview brand-width-restricted" src={"https://cdn.chipndoodle.com/text.light.png"} />
                          <p className="brand-section-column-preview-usage">To be used when the background is not white/light</p>

                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <h2 className="brand-subheading">Usage</h2>
                      <p className="brand-text">ChipnDoodle and the people behind are trying to be a force for good and thus in this vain we are happy for anyone to use the branding but we ask that you do not:</p>
                      <table className="brand-table">
                        <tbody>
                          <tr>
                            <td>ChipnDoodle's logos and word marks are for non commercial use only. Usage of ChipnDoodle's logos and word marks for commercial sales, individual fundraising or organizational fundraising is strictly prohibited unless otherwise expressed in writing by an authorized agent of ChipnDoodle.</td>
                          </tr>
                          <tr>
                            <td>Do not display our branding in any manner that violates the trademarks, copyright, or any other intellectual property rights of others</td>
                          </tr>
                        </tbody>
                      </table>
                      
                    </div>
                    <p></p>
                    <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;