import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import moment from 'moment';
import _ from 'underscore';
import S from 'string';
import './style.css';
import APIClient from '../../client';
import SearchView from '../../widgets/searchheader';
import ProviderItem from '../../widgets/provideritem';
import querystring from 'querystring';

class View extends Component {
  constructor(params) {
    super(params);
    const parsedquery = querystring.parse(
      params.location.search.slice(1, params.location.search.length)
    );
    let chipnumber = parsedquery.chipnumber || parsedquery.uid || null;
    this.state = {

      providers:        [],
      upload:           null,
      continents:       [],
      locality:         params.locality,
      user:             params.user,
      errorMessage:     null,
      section:          'view',
      chipnumber:       chipnumber,
      loading:          false

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {
    
    // set the databases
    document.title = 'ChipnDoodle';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/providers/continents',
        query:  {
          token,
          featured: 1
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    let continents = result.data || [];

    // 
    let continent = null;

    // the code
    let countrycode = (this.state.locality.continent.code || '').toLowerCase();

    // try to find the local continent of the user first
    for(let i = 0; i < continents.length; i++) {
      let local_item = continents[i];
      if(local_item.code === countrycode) {
        continent = local_item.code;
        break;
      }
    }

    // did we find it?
    if(!continent) {
      continent = (continents[0] || {}).code || 'af';
    }

    this.setState({

      section:      'loaded',
      continents:    _.sortBy(result.data, 'name'),
      continent:     continent

    }, async () => {

      await this.fetchProviders(continent);

    });

  }
  fetchProviders = async (continent) => {

    // set in state
    this.setState({
      
      continent,
      loading: true

    }, async () => {

      // get the token
      let token = APIClient.getToken();

      // set the call
      let result = null;

      // handle errors
      try {
        result = await APIClient.call({

          path:   '/providers',
          query:  {
            token,
            continent,
            featured: 'yes'
          }
    
        });
      } catch(err) {
        this.setState({
          loading:      false,
          section:      'error'
        });
        return;
      }

      if(result.ok !== true) {
        this.setState({
          loading:      false,
          section:      'notfound'
        });
        return;
      }

      this.setState({

        loading:      false,
        providers:    result.data

      });

    })

  }
  formatDescription = (item) => {
    if(item.website) {
      let text = item.website;
      text = text.toLowerCase();
      text = text.replace(/http:\/\/www\./gi, '');
      text = text.replace(/https:\/\/www\./gi, '');
      text = text.replace(/http:\/\//gi, '');
      text = text.replace(/https:\/\//gi, '');
      text = text.split('/')[0];
      return S(text).truncate(18, '..').s;
    } else {
      return 'since ' + moment(item.created).format('LLL')
    }
  }
  render() {
    let banner = null;
    if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    let content = null;
    if(this.state.section === 'loading') {
      content = (
        <div className="wrapper">
          <div className="home">
            <div className="container">
              <div className="home-inner">
                <div className="initialoading">
                  <div className="dots">
                    <div className="dot dot1"></div>
                    <div className="dot dot2"></div>
                    <div className="dot dot3"></div>
                    <div className="dot dot4"></div>
                    <div className="dot5"></div>
                  </div>
                  <h2 className="initialoading-heading">One sec just loading...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>);
    } else {
        content = (<div className="wrapper">
        <div className="home">
          <SearchView user={this.state.user} {...this.props} ></SearchView>
          <div className="home-inner">
            <h2 className="home-providers-heading">Some highlights from our ever expanding catalog we try to search by region:</h2>
            <div className="home-providers-pills home-providers-pills-location">
            {
              this.state.continents.map((item, index) => {
                return (<a href={"/#" + item.code} key={"home-continents-" + item.code} onClick={async (e) => {
                  this.fetchProviders(item.code);
                }} className={[

                  "home-providers-pill",
                  index === 0 ? "home-providers-pill-left" : '',
                  index === (this.state.continents.length - 1) ? "home-providers-pill-right" : '',
                  item.code === this.state.continent ? 'active' : ''

                ].join(' ')}>
                  <div className="home-providers-pill-inner">
                    <img alt="Continent Landmass White" className="light" src={"https://cdn.chipndoodle.com/continents/" + item.code + ".svg"} />
                    <img alt="Continent Landmass Dark" className="color" src={"https://cdn.chipndoodle.com/continents/" + item.code + ".dark.svg"} />
                    <span className="home-providers-pill-inner-big">{item.name}</span>
                    <span className="home-providers-pill-inner-small">{item.code.toUpperCase()}</span>
                    <div className="clearfix"></div>
                  </div>
                </a>);
              })
            }
            </div>
            <div className="container">
              <div className="home-listing-items">
                {
                    this.state.loading === true
                  ?
                    <p>Loading providers for that continent, one sec...</p> 
                  :
                    this.state.providers.map((item, index) => {
                      return <ProviderItem item={item} />
                    })
                }
              </div>
              <div className="clearfix"></div>
              <Link className="home-cta" to="/providers">View our entire catalog</Link>
              <div className="clearfix"></div>
              <p className="home-providers-legal">the service is provided as is and only accessing public information from these providers with the goal to help people find and get their pets home. If the search fails, we point you to check directly at the providers' website.</p>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        {banner}
        {content}
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;