import React, { Component } from 'react';
import './style.css';
import APIClient from '../../client';
import S from 'string';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import Downloader from 'js-file-downloader';
import './certificates.css';

const PAGE_LIMIT = 10;

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      group:        params.group,
      item:         params.record,
      user:         params.user,
      locality:     params.locality,
      search:       '',
      offset:       0,
      hasMore:      true,
      section:      'loading'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputComment = React.createRef();
  }
  componentDidMount = async () => {

    document.title = 'Certificates for ' + this.state.item.uid
    await this.fetchItems();
    this.setState({
      section: 'loaded'
    })

  }
  fetchItems = async () => {

    // get the token
    let token = APIClient.getToken();

    // get the items
    let items = [];

    // set the items
    let query = {
      token,
      limit: PAGE_LIMIT,
      record: this.state.item.uid,
      group: this.state.group.uid,
    };
    
    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/certificates',
        query:  query
  
      });
      if(Array.isArray(result.data)) {
        items = result.data || [];
      }
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

    // set the item
    this.setState({
      items,
      hasMore: items.length === PAGE_LIMIT
    })

  }
  handleSearchQueryChange = () => {
    if(this.timer) clearTimeout(this.timer);
    this.setState({
      search: this.inputSearchQuery.current.value
    }, () => {
      this.timer = setTimeout(this.handleResetSearch, 100);
    });
  }
  handleResetSearch = () => {
    this.setState({
      
      limit:    PAGE_LIMIT,
      offset:   0,
      hasMore:  true

    }, this.fetchItems);
  }
  handleNext = async () => {
    let offset = this.state.offset + PAGE_LIMIT;

    // get the token
    let token = APIClient.getToken();

    // pulled items
    let pulleditems = [];

    // set the items
    let query = {
      token,
      offset: offset,
      limit: PAGE_LIMIT,
      record: this.state.item.uid,
      group: this.state.group.uid,
    };

    // add params
    if(S(this.state.search).isEmpty() === false) {
      query.q = this.state.search;
    }

    // handle errors
    try {
      let result = await APIClient.call({

        path:   '/certificates',
        query:  query
  
      });
      pulleditems = result.data || [];
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

    let items = (this.state.items || []).concat(pulleditems || []);

    // set the item
    this.setState({
      items:    items,
      offset:   offset,
      hasMore:  pulleditems.length === PAGE_LIMIT
    })
  }
  renderItems = () => {
    if(this.state.items.length > 0) {

      return (<div className="grouprecord-certificates-listing-items">
        {
          this.state.items.map((item) => {
            return (<div key={item.uid} className="grouprecord-certificates-listing-item">
              <a className="grouprecord-certificates-listing-item-inner" key={item.uid} href="/#" onClick={(e) => {
                if(e) e.preventDefault();
                let token = APIClient.getToken();
                new Downloader({ 
          
                  filename:   new Date().getTime() + '.pdf',
                  url:        APIClient.getBaseAPIURL() + '/certificates/' + item.uid + '/preview?token=' + token,
        
                });
              }}>
                <div className="grouprecord-certificates-listing-item-inner-action">
                  <p>Click to Download</p>
                </div>
                <h3 className="grouprecord-certificates-listing-item-heading">Certificate generated by {item.user.name}</h3>
                <p className="grouprecord-certificates-listing-item-slogan">{item.signature} - {moment(item.timestamp).format('LLL')}</p>
                <div className="clearfix"></div>
              </a>
            </div>);
          })
        }
      </div>);

    } else {

      return (<div className="grouprecord-items-blank">
        <span className="fas fa-search-minus"></span>
        <p>No lookups found matching that search :(</p>
        <div className="clearfix"></div>
      </div>);

    }
  }
  handleNewCertificate = async (e) => {

    // stop event
    if(e) e.preventDefault();

    // get the token
    let token = APIClient.getToken();

    // set the items
    let query = {};

    // handle errors
    try {
      let result = await APIClient.call({

        method: 'POST',
        path:   '/certificates',
        query:  query,
        form:   {
          token:    token,
          group:    this.state.group.slug,
          record:   this.state.item.uid,
          comment:  this.inputComment.current.value,
        }
  
      });

      this.setState({

        section: 'downloading'

      }, () => {

        new Downloader({ 
          
          filename:   this.state.item.uid + ' (' + moment().format('LLL') + ').pdf',
          url:        APIClient.getBaseAPIURL() + '/certificates/' + result.data.uid + '/preview?token=' + token,

        })
        .then(() => {
          
          this.setState({

            section: 'loaded',
            items:    [
              result.data
            ].concat(this.state.items || [])
    
          });

        })
        .catch((error) => {
          
          this.setState({

            section: 'loaded',
    
          });

        });

      })
      
    } catch(err) {
      this.setState({
        errorMessage: 'Problem getting the new items'
      })
    }

  }
  render() {
    if(this.state.section === 'loading') {
      return (<p>Loading...</p>);
    }
    if(this.state.section === 'downloading') {
      return (<p>Downloading...</p>);
    }
    if(this.state.section === 'new') {
      return (<div>

        <form onSubmit={this.handleSubmit} className="details-box-controls">

          <div className="details-box-control">
            <div className="details-box-control-label">
              <p>We will tweak the certificate based on needs</p>
              <label htmlFor="nickname">Reasons for needing certificate</label>
            </div>
            <div className="details-box-control-inputs">
              <div className="grouprecord-certificates-reasons">
                <a href="#" className="grouprecord-certificates-reason">
                  <h4>Test</h4>
                  <p>Testing</p>
                </a>
                <a href="#" className="grouprecord-certificates-reason">
                  <h4>Exporting</h4>
                  <p>The pet will be exported and this is to prove their existance</p>
                </a>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>

          <div className="details-box-control">
            <div className="details-box-control-label">
              <label htmlFor="nickname">Comments</label>
            </div>
            <div className="details-box-control-inputs">
            <textarea ref={this.inputComment} placeholder="Comment on why you are requesting this certificate, will be shown on actual certificate"></textarea>
            </div>
            <div className="clearfix"></div>
          </div>

        </form>
        <button onClick={this.handleNewCertificate}>Issue Certificate</button>
      </div>);
    }
    return (<div>

      <a href="/groups#" onClick={(e) => {
        if(e) e.preventDefault();
        this.setState({
          section: 'new'
        })
      }}>Issue a new Certificate</a>

      <InfiniteScroll 
        dataLength={this.state.items.length} //This is important field to render the next data
        next={this.handleNext}
        hasMore={this.state.hasMore} 
        style={{
          overflow: 'visible'
        }}
        loader={<h4 style={{textAlign: 'center', marginTop: 25}}>Loading more searches...</h4>}
        endMessage={
            this.state.items.length > PAGE_LIMIT
          ?
            <p style={{textAlign: 'center', marginTop: 25}}>
              <b>No more searces found :|</b>
            </p>
          : 
            null
        }
        // below props only if you need pull down functionality
        refreshFunction={this.handleResetSearch} >
        
        {this.renderItems()}
        <div className="clearfix"></div>

      </InfiniteScroll>
    </div>);
  }
}

export default App;
