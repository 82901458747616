const browser = {}

/**
 *
 */
browser.setBodyClass = function (className) {
  document.body.className = className ? className : ''
}

function fallbackCopyTextToClipboard (text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.debug('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

/**
 * Copies text to the clipboard using a few fallbacks if needed
 */
browser.copyTextToClipboard = function (text) {
  if (!navigator || !navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.debug('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

/**
 * Opens a new tab with the given url
 */
browser.openNewTab = function (url) {
  window.open(url, '_blank').focus()
}

/**
 *
 */
browser.formatQueryParams = function (givenParams) {
  const loc = window.location
  if (!loc) return
  const params = new URLSearchParams()
  for (let key in givenParams) {
    params.set(key, givenParams[key])
  }
  return params.toString()
}

/**
 *
 */
browser.setQueryParams = function (givenParams) {
  const loc = window.location
  if (!loc) return
  const params = new URLSearchParams()
  for (let key in givenParams) {
    params.set(key, givenParams[key])
  }
  if (!window.history) return
  const textQuery = params.toString()
  if (textQuery === '') {
    window.history.replaceState({}, '', `${loc.pathname}`)
    return
  }
  window.history.replaceState({}, '', `${loc.pathname}?${textQuery}`)
}

/**
 *
 */
browser.getQueryParams = function () {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(urlSearchParams.entries())
}

/**
 *
 */
browser.getQueryParam = function (key, defaultValue = null) {
  const params = browser.getQueryParams()
  return decodeURIComponent(params[key] || defaultValue)
}

/**
 *
 */
browser.scrollToTop = function (val = 0) {
  browser.scrollTo(val)
}

/**
 *
 * @param {*} topval
 */
browser.scrollTo = function (topval) {
  window.scrollTo({
    top: topval,
    behavior: 'smooth'
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  })
}

/**
 *
 */
browser.setTitle = function (text) {
  let newTitle = 'AuroraReach'
  if (text) {
    newTitle = text + ' | AuroraReach'
  }
  document.title = newTitle
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute('content', newTitle)
  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute('content', newTitle)
}

/**
 *
 */
browser.setDescription = function (text) {
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', text)
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute('content', text)
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute('content', text)
}

/**
 *
 */
browser.setKeyWords = function (keywords) {
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute('content', keywords.join(', '))
}

export default browser
