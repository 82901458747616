import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import querystring from 'querystring';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import './styles/reset.css';
import './styles/common.css';
import './styles/app.css';
import './styles/box.css';
import './styles/loading.css';

import * as serviceWorker from './serviceWorker';

import APIClient from './client';

import HomePage from './pages/home';
import AddPage from './pages/add';
import TagPage from './pages/tag';
import CapturePage from './pages/capture';
import SpeciesPage from './pages/species';
import BreedsPage from './pages/breeds';
import ProfilePage from './pages/profile';
import AppsPage from './pages/apps';
import AppPage from './pages/app';
import BuildPage from './pages/build';
import AnimalsPage from './pages/animals';
import AnimalPage from './pages/animal';
import SearchesPage from './pages/searches';
import SearchPage from './pages/search';
import GroupsPage from './pages/groups';
import GroupImportAddPage from './pages/groupimportadd';
import GroupImportPage from './pages/groupimport';
import GroupNewPage from './pages/groupnew';
import GroupOverviewPage from './pages/groupoverview';
import GroupRecordsPage from './pages/grouprecords';
import GroupRecordPage from './pages/grouprecord';
import GroupSearchesPage from './pages/groupsearches';
import GroupSearchPage from './pages/groupsearch';
import GroupSettingsPage from './pages/groupsettings';
import GroupIntegrationsPage from './pages/groupintegration';
import GroupMembersPage from './pages/groupmembers';
import DatabasesPage from './pages/databases';
import DatabasePage from './pages/database';
import CongregatePage from './pages/congregate';
import TermsPage from './pages/terms';
import PrivacyPage from './pages/privacy';
import DocsPage from './pages/docs';
import BrandPage from './pages/brand';
import VerifyPage from './pages/verify';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';

import SentPage from './pages/sent'
import RejectedPage from './pages/rejected'

import NotFoundPage from './pages/notfound';

const MAX_AMOUNT_OF_RETRIES = 5;

class AppView extends Component {
  constructor(params) {
    super(params);
    let searchstr = (document.location || {}).search || '';
    const parsedquery = querystring.parse(
      searchstr.slice(1, searchstr.length)
    );
    this.state = {

      locality:   null,
      user:       null,
      section:    'loading',
      retries:    0,
      query:      parsedquery

    };
  }
  handleUserChange = (user) => {
    this.setState({

      user:   user

    })
  }
  doRetry = () => {

    if(this.state.retries >= MAX_AMOUNT_OF_RETRIES)  {
      return this.setState({
        section: 'problems'
      });
    }
    this.setState({
      retries: this.state.retries+1
    });
    return setTimeout(this.fetchLoad, 1 * 1000);

  }
  fetchLoad = async () => {

    let token = APIClient.getToken();

    this.setState({

      section:    'loading'

    }, async () => {

      let tasks = [
        APIClient.call({
  
          timeout:  3,
          path:     '/locality'
    
        })
      ];
      
      if(token) {
        tasks.push(APIClient.call({
  
          timeout:  3,
          path:     '/user',
          query:    {
            token
          }
    
        }));
      }
      
      // first list of results
      let results = null;

      // try to execute the promises
      try {
        results = await Promise.all(tasks);
      } catch(err) {
        return this.doRetry();
      }

      // check if we found the user's ip here?
      if(results[0].ok !== true) return this.doRetry();

      // set the checks
      let locality = results[0].data  || null;
      let user = (results[1] || {}).data || null; 

      // locality is REALLY required
      if(!locality) return this.doRetry();

      // set our state
      this.setState({
        
        locality:     locality,
        user:         user,
        section:      'loaded',
  
      });

    });

  }
  componentDidMount = () => {

    // try to actually fetch the load
    this.fetchLoad();

  }
  render() {
    if(this.state.section === 'loading') {
      return (<div className="initialoading-wrapper">
        <div className="initialoading">
          <div className="dots">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div className="dot5"></div>
          </div>
          <h2 className="initialoading-heading">One sec just loading...</h2>
          {
              this.state.retries > 0 
            ?
              <p className="initialoading-extra-info">Retrying (try #{this.state.retries}/{MAX_AMOUNT_OF_RETRIES}) ...</p>
            :
              null
          }
        </div>
      </div>);
    }
    if(this.state.section === 'problems') {
      return (<div className="initialoading-wrapper">
        <div className="initialoading">
          <img alt="Shy Dog" className="initialoading-preview-image" src="https://cdn.chipndoodle.com/animations/shydog.gif" />
          <h2 className="initialoading-preview-message">Problem starting app, please try again in a minute or two</h2>
          <a onClick={(e) => {
            e.preventDefault();
            document.location = document.location.toString();
          }} href="/" className="initialoading-preview-cta">Reload Now</a>
        </div>
      </div>);
    }
    return (
      <div className="app-wrapper">
        <Router>
          <Switch>
            <Route state={this.state} exact path="/" render={(props) => <HomePage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/capture" render={(props) => <CapturePage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/tag" render={(props) => <TagPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/tag/:taguid" render={(props) => <TagPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/searches" render={(props) => <SearchesPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/searches/:searchuid" render={(props) => <SearchPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/brand" render={(props) => <BrandPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/sent" render={(props) => <SentPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/rejected" render={(props) => <RejectedPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/docs" render={(props) => <DocsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/docs/:page" render={(props) => <DocsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/apps" render={(props) => <AppsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} path="/apps/:appslug" render={(props) => <AppPage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/build" render={(props) => <BuildPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/groups" render={(props) => <GroupsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/import" render={(props) => <GroupImportAddPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/imports/:importuid" render={(props) => <GroupImportPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/new" render={(props) => <GroupNewPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records" render={(props) => <GroupRecordsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid" render={(props) => <GroupRecordPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/certificates" render={(props) => <GroupRecordPage selectedSection="certificates" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/history" render={(props) => <GroupRecordPage selectedSection="history" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/searches" render={(props) => <GroupRecordPage selectedSection="searches" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/dead" render={(props) => <GroupRecordPage selectedSection="dead" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/flag" render={(props) => <GroupRecordPage selectedSection="flag" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/records/:recorduid/gallery" render={(props) => <GroupRecordPage selectedSection="gallery" handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/searches" render={(props) => <GroupSearchesPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/searches/:responseuid" render={(props) => <GroupSearchPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/settings" render={(props) => <GroupSettingsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/integration" render={(props) => <GroupIntegrationsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid/members" render={(props) => <GroupMembersPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/groups/:groupuid" render={(props) => <GroupOverviewPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/congregate" render={(props) => <CongregatePage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/providers" render={(props) => <DatabasesPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/providers/:groupslug" render={(props) => <DatabasePage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/animals" render={(props) => <AnimalsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/animals/:animaluid" render={(props) => <AnimalPage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/verify" render={(props) => <VerifyPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/verify/:code" render={(props) => <VerifyPage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />

            <Route state={this.state} exact path="/species" render={(props) => <SpeciesPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/species/:specieskey/breeds" render={(props) => <BreedsPage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/species/:specieskey/breeds/:breedkey" render={(props) => <BreedsPage handleUserChange={this.handleUserChange} user={this.state.user} {...props} state={this.state} />} />
            
            <Route state={this.state} exact path="/register" render={(props) => <RegisterPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/profile" render={(props) => <ProfilePage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/add" render={(props) => <AddPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/docs" render={(props) => <DocsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/login" render={(props) => <LoginPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/terms" render={(props) => <TermsPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route state={this.state} exact path="/privacy" render={(props) => <PrivacyPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />} />
            <Route component={(props) => <NotFoundPage handleUserChange={this.handleUserChange} locality={this.state.locality} user={this.state.user} {...props} state={this.state} />}/>
          </Switch>
        </Router>
      </div>
    );
  }
}

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    
    dsn:      "https://a49eb7e5b2154b479c31ade23288d9a9@o434485.ingest.sentry.io/5391599",
    release:  'web@' + process.env.CHIPNDOODLE_BUILD_VERSION,

  });
}

ReactDOM.render(<AppView />, document.getElementById('root'));
serviceWorker.register();
