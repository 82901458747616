import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import SearchView from '../../widgets/searchheader';
import './style.css';
const CONSTANTS = require('../../constants');

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      user:           params.user,
      locality:       params.locality,
      errorMessage:   null,
      submitting:     false,
      gotoProfile:     false,
      submissions:    0,
      tagside:        'back'

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPassword = React.createRef();
  }
  componentDidMount = () => {

    this.handleUserChange(null);
    this.setState({ user: null })
    document.title = "Login";

  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  startChallenge = (e) => {
    if(e) e.preventDefault();
    if(this.state.submissions > 0) {
      this.inputCaptcha.current.reset();
    }
    this.inputCaptcha.current.execute();
    // const value = this.inputCaptcha.current.getValue();
  }
  handleSubmit = async (value) => {
    if(this.state.submitting === true) return;
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/login',
        method:   'post',
        form:     {
  
          code: value,
          email: this.inputEmail.current.value,
          password: this.inputPassword.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }
    
    APIClient.setToken(result.data.token);
    this.handleUserChange(result.data.user);
    this.props.history.push('/searches');
  }
  render() {
    let banner = null;
    if(this.state.submitting === true) {
      banner = (<div>
        <div className="progress-overlay"></div>
        <div className="progress-wrapper">
          <div className="progress-box-container">
            <div className="progress-box"></div>
            <h3 className="progress-text">Logging you in...</h3>
          </div>
        </div>
      </div>);
    } else if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }

    return (

      <div>
        <HeaderView {...this.props} section='tag' locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="">
          <div className="tag">
            {banner}
            <div className="container">
              <div className="tag-inner">

                <div className="tag-hero">

                  <h3 className="tag-hero-heading">Pet Tag</h3>
                  <div className="tag-hero-preview">
                    <img src={"https://cdn.chipndoodle.com/tags/" + this.state.tagside + ".svg"} />
                  </div>
                  <div className="tag-hero-pills">
                    <a onClick={() => {
                      this.setState({
                        tagside: 'front'
                      })
                    }} href="#front" className={"tag-hero-pill " + (this.state.tagside === 'front' ? 'active' : '')}>Front of Tag</a>
                    <a onClick={() => {
                      this.setState({
                        tagside: 'back'
                      })
                    }} href="#back" className={"tag-hero-pill " + (this.state.tagside === 'back' ? 'active' : '')}>Back of Tag</a>
                    <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
                  <p className="tag-hero-slogan">Seems you found a pet tag, hopefully the wearer is alright! On the back of the tag there should be a number, please type the number below and we'll check for their and their owners' details.</p>
                  <div className="clearfix"></div>

                </div>

                <SearchView page="tag" user={this.state.user} {...this.props} ></SearchView>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>

    );
  }
}

export default View;