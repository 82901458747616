import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import superagent from 'superagent';
import Dropzone from 'react-dropzone'
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import UserMenuView from '../../widgets/usermenu';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      user:           params.user,
      locality:       params.locality,
      errorMessage:   null,
      submitting:     false,
      gotoProfile:    false,
      uploading:      false,
      submissions:    0,
      name:           (params.user || {}).name || null,
      email:          (params.user || {}).email || null,
      avatar:         (params.user || {}).avatar || null,
      address:        (params.user || {}).address || null,
      phonenumber:    (params.user || {}).phonenumber || null,

    };
    this.handleUserChange = params.handleUserChange;
    this.inputCaptcha = React.createRef();
    this.inputName = React.createRef();
    this.inputEmail = React.createRef();
    this.inputPhoneNumber = React.createRef();
    this.inputAddress = React.createRef();
  }
  componentDidMount = () => {
    document.title = "Profile";
  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  startChallenge = (e) => {
    if(e) e.preventDefault();
    if(this.state.submissions > 0) {
      this.inputCaptcha.current.reset();
    }
    this.inputCaptcha.current.execute();
  }
  handleSubmit = async () => {
    if(this.state.submitting === true) return;
    this.setState({

      submitting:   true,
      submissions:  this.state.submissions + 1

    });
    let token = APIClient.getToken();
    let result = null;
    try {
      result = await APIClient.call({

        path:     '/user',
        method:   'post',
        form:     {
          
          token,
          name: this.inputName.current.value,
          email: this.inputEmail.current.value,
          phonenumber: this.inputPhoneNumber.current.value,
          address: this.inputAddress.current.value,
  
        }
  
      });
    } catch(err) {
      this.setState({
        submitting:     false,
        errorMessage:   'Something went wrong, try again in a few seconds...'
      })
      return;
    }

    if(result.ok === false) {
      this.setState({
        submitting:     false,
        errorMessage:   result.data.message
      })
      return;
    }

    this.setState({
      submitting:     false,
      errorMessage:   null
    }, () => {
      document.location = document.location;
      // this.handleUserChange(result.data.user);
    });
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      errorMessage:   null,
      uploading:      true

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=users.avatar&token=' + token);
      req.attach('file', files[0]);
      req.end(async (err, res) => {
        let data = JSON.parse(res.text);

        await APIClient.call({

          path:     '/user',
          method:   'post',
          form:     {
    
            token,
            avatar: data.uid || null
    
          }
    
        });

        this.setState({
          avatar:     data.url,
          upload:     data.uid,
          uploading:  false
        });
      })
    });

  }
  render() {
    let banner = null;
    if(this.state.submitting === true) {
      banner = (<div>
        <div className="progress-overlay"></div>
        <div className="progress-wrapper">
          <div className="progress-box-container">
            <div className="progress-box"></div>
            <h3 className="progress-text">Logging you in...</h3>
          </div>
        </div>
      </div>);
    } else if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }

    return (

      <div>
        <HeaderView {...this.props} section="me" locality={this.state.locality}  user={this.state.user} state={this.state} />
        <UserMenuView user={this.state.user} section="profile"></UserMenuView>
        {banner}
        <div className="wrapper">
          <div className="profile profile-alt">
            <div className="hero-box">
              <h2 className="hero-heading">{this.state.name}</h2>
              <h2 className="hero-slogan">Let's get to know each other :)</h2>
            </div>
            <div className="container">
              <div className="profile-inner">

                <div className="details-box">
                  <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
                    {({getRootProps, getInputProps}) => (
                      <div onClick={(e) => {
                        e.stopPropagation();
                      }} className="details-box-preview">
                        <div {...getRootProps()}>
                        {
                            this.state.uploading
                          ? 
                            <p className="details-box-preview-text">&nbsp;</p>
                          :
                            <p className="details-box-preview-text">Give us a smile!</p>
                        }
                        <img alt="User Avatar" src={this.state.avatar || 'https://cdn.chipndoodle.com/defaults/record.png'} className="details-box-preview-img" />
                        <input {...getInputProps()} />
                        {
                            this.state.uploading
                          ? 
                            <p className="details-box-preview-text">uploading, one sec ...</p>
                          :
                            <p className="details-box-preview-text">Click here to upload a profile pic</p>
                        }
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="details-box-meta">

                    <form onSubmit={this.handleSubmit} className="details-box-controls">

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Name</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.name} ref={this.inputName} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">EMail</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="email" defaultValue={this.state.email} ref={this.inputEmail} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <label htmlFor="nickname">Phonenumber</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="tel" defaultValue={this.state.phonenumber} ref={this.inputPhoneNumber} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="details-box-control">
                        <div className="details-box-control-label">
                          <p>We only ever show the city on our searches</p>
                          <label htmlFor="nickname">Address</label>
                        </div>
                        <div className="details-box-control-inputs">
                          <input type="text" defaultValue={this.state.address} ref={this.inputAddress} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                    </form>
                    <div className="details-box-toolbar">
                      <Link className="details-box-toolbar-action details-box-toolbar-action-right" onClick={() => {
                        this.handleSubmit();
                      }}>SAVE CHANGES</Link>
                      <p className="details-box-toolbar-text"></p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>

    );
  }
}

export default View;