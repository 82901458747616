import React, { Component } from 'react'
import HeaderView from '../../widgets/header'
import FooterView from '../../widgets/footer'
import './style.css'

class View extends Component {
  constructor (params) {
    super(params)
    this.state = {
      locality: params.locality,
      user: params.user
    }
    this.handleUserChange = params.handleUserChange
  }
  componentDidMount = () => {
    document.title = 'Certificate Sent'
  }
  render () {
    return (
      <div>
        <HeaderView
          {...this.props}
          locality={this.state.locality}
          user={this.state.user}
          state={this.state}
        />
        <div className='wrapper'>
          <div className='sent-page'>
            <div className='container'>
              <div className='sent-inner'>
                <span className='fas fa-check-circle iconpreview'></span>
                <h2 className='sentheading'>Certificate Sent</h2>
                <p className='sentslogan'>
                  Certificate was sent to your inbox of the email specified.
                  Give it a few minutes and please double check your spam
                  folder. If all else fails please reach out to us at{' '}
                  <a href='mailto:info@chipndoodle.com'>info@chipndoodle.com</a>
                  &nbsp; and we will help you get your certificate.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    )
  }
}

export default View
