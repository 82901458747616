import React, { Component } from 'react';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      locality:         params.locality,
      user:             params.user

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="notfound">
            <div className="container">
              <div className="notfound-inner">

                <div className="doggo">

                  <div className="dog">
                    <div className="dog-body">
                      <div className="dog-tail">
                        <div className="dog-tail">
                          <div className="dog-tail">
                            <div className="dog-tail">
                              <div className="dog-tail">
                                <div className="dog-tail">
                                  <div className="dog-tail">
                                    <div className="dog-tail"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dog-torso"></div>
                    <div className="dog-head">
                      <div className="dog-ears">
                        <div className="dog-ear"></div>
                        <div className="dog-ear"></div>
                      </div>
                      <div className="dog-eyes">
                        <div className="dog-eye"></div>
                        <div className="dog-eye"></div>
                      </div>
                      <div className="dog-muzzle">
                        <div className="dog-tongue"></div>
                      </div>
                    </div>
                  </div>

                  <div className="ball" tabindex="0"></div>

                </div>

                <h2 className="notfound-heading">Page N<span className="notfound-heading-o"></span>t Found</h2>
                <p className="notfound-slogan">The dog might have eaten our homework...</p>
                <p className="notfound-info">If you think is a mistake please tell us at <a href="mailto:info@chipndoodle.com">info@chipndoodle.com</a></p>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;