import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      user:         params.user,
      locality:     params.locality,

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    let locality = null;
    if(this.state.locality && 
        this.state.locality.country) {
      locality = (<div className="footer-locality">
        <img alt={this.state.locality.country.name + "'s flag"} className="footer-locality-flag" src={this.state.locality.country.flag} />
        <span className="footer-locality-text">{this.state.locality.country.name} (<strong>{this.state.locality.remoteip}</strong>)</span>
        <div className="clearfix"></div>
      </div>);
    }
    return (
      <footer className="footer">
        <div className="container">
          <p className="footer-copyright">Made with <span className="fas fa-heart"></span> by Pet Lovers</p>
          <ul className="footer-links footer-links-social">
            <li>
              <a target="_blank" title="Follow us on Facebook" href="https://facebook.com/chipndoodle">
                <span className="fab fa-facebook-square"></span>
                <div className="clearfix"></div>
              </a>
            </li>
            <li>
              <a target="_blank" title="Follow us on Twitter" href="https://twitter.com/thechipndoodle">
                <span className="fab fa-twitter-square"></span>
                <div className="clearfix"></div>
              </a>
            </li>
            <li>
              <a target="_blank" title="Follow us on Linkedin" href="https://linkedin.com/company/chipndoodle">
                <span className="fab fa-linkedin"></span>
                <div className="clearfix"></div>
              </a>
            </li>
            <li>
              <a title="Send us a email" href="mailto:info@chipndoodle.com"><span className="fas fa-envelope-open-text"></span></a>
            </li>
          </ul>
          <ul className="footer-links">
            <li>
              <Link to="/brand">Branding</Link>
            </li>
            <li>
              <Link to="/docs">Developers</Link>
            </li>
            <li>
              <Link to="/terms">Terms</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>
          {locality}
        </div>
      </footer>
    );
  }
}

export default App;
