import React, { Component } from 'react';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      locality:         params.locality,
      user:             params.user

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = () => {
    document.title = 'Certificate Sent';
  }
  render() {
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="sent-page">
            <div className="container">
              <div className="sent-inner">
                <h2>Payment Failed</h2>
                <p>Unable to process your payment</p>
              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;