import React, { Component } from 'react';
import superagent from 'superagent';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
import APIClient from '../../client';
import querystring from 'querystring';
const CONSTANTS = require('../../constants');

const CHIP_FORMAT_NUMBER  = 5;

class View extends Component {
  constructor(params) {
    super(params);
    const parsedquery = querystring.parse(
      document.location.search.slice(1, document.location.search.length)
    );
    let chipnumber = parsedquery.chipnumber || parsedquery.uid || null;
    this.state = {

      upload:           null,
      locality:         params.locality,
      user:             params.user,
      errorMessage:     null,
      section:          'view',
      loadingSecond:    0,
      loadingTotal:     0,
      submitting:       false,
      searching:        false,
      chipnumber:       chipnumber,
      chipnumberfmt:    this.formatChipNumber(chipnumber),
      submissions:      0,
      page:             params.page || 'home'

    };
    this.activePage = true;
    this.handleUserChange = params.handleUserChange;
    this.inputChipNumber = React.createRef();
    this.inputCaptcha = React.createRef();
    this.inputVideo = React.createRef();
  }
  formatChipNumber = (text) => {
    if(!text) return '';
    let batchsize = 1 * CHIP_FORMAT_NUMBER;
    if(text.toLowerCase().indexOf('c') === 0 && text.length > 3 && text.length <= 8) {
      batchsize = 4;
    }
    let groups = Math.ceil(text.length / batchsize);
    let groupings = [];
    for(let i = 0 ; i < groups; i++) {
      groupings.push(text.slice(i * batchsize, (i+1) * batchsize));
    }
    return groupings.join('-');
  }
  componentDidMount = async () => {
    document.title = "ChipnDoodle";
  }
  componentWillUnmount = () => {
    this.activePage = false;
  }
  performCaptha = async (e) => {
    if(e) e.preventDefault();
    this.inputCaptcha.current.execute();
    let value = null;
    let attempts = 0;
    while(!value) {
      attempts++;
      value = this.inputCaptcha.current.getValue();
      if(attempts > 15) {
        return false;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    this.inputCaptcha.current.reset();
    return value;
  }
  onDroppedFile = (files) => {
    this.setState({
      section:    'processing'
    })
    let baseurl = CONSTANTS.DEVELOPMENT_API_URL;
    if(process.env.NODE_ENV === 'production') {
      baseurl = CONSTANTS.PRODUCTION_API_URL;
    }
    const req = superagent.post(baseurl + '/uploads?reason=search.image');
    req.attach('file', files[0]);
    req.end((err, res) => {
      this.setState({
        section:  'processed',
        upload:   JSON.parse(res.text)
      })
    })
  }
  sleepSync = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  doProgressCounting = async () => {
    let step = 0;
    while(this.activePage === true && this.state.submitting === true) {
      step++;
      let total = 0;
      if(step < 10) {
        total = 9;
      } else {
        total = step+3;
      }
      this.setState({ 
        
        loadingSecond: step,
        loadingTotal:  total
      
      });
      await this.sleepSync(1000);
    }
  }
  handleSearch = async (e) => {
    if(e) e.preventDefault();
    let chipnumber = '' + this.state.chipnumber;
    if(this.state.submitting === true) return;
    this.setState({

      submitting:     true,
      submissions:    this.state.submissions + 1,
      loadingSecond:  0,

    }, async () => {

      let token = APIClient.getToken();
      let data = {
        uid: chipnumber,
        async: 'yes'
      };
      let result = null;
      if(!token) {
        data.code = await this.performCaptha();
      } else {
        data.token = token;
      }

      // handle errors
      try {

        result = await Promise.race([

          this.doProgressCounting(),
          APIClient.call({

            path:     '/searches',
            method:   'post',
            form:     data
      
          }),

        ]);

      } catch(err) {
        this.setState({
          submitting:     false,
          errorMessage:   'Something went wrong, try again in a few seconds...'
        })
        return;
      }

      if(this.activePage === false) {return;}

      if(result.ok !== true) {
        this.setState({
          submitting:     false,
          errorMessage:   result.data.message
        });
        return;
      }

      this.props.history.push('/searches/' + result.data.uid);

    });
  }
  handleMicrochipTextUpdate = async (text) => {
    let chipnumber = (this.inputChipNumber.current.value || '').split('-').join('').slice(0, 15);
    this.setState({
      chipnumber: chipnumber,
      chipnumberfmt: this.formatChipNumber(chipnumber)
    });
  }
  render() {
    let banner = null;
    if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    let submittingProgressBar = null;
    if(this.state.submitting === true && this.state.loadingSecond > 1) {
      let message = 'starting';
      submittingProgressBar = (
        <div className="search-header-progress">
          <div className="search-header-progress-bar" style={{
            width: (((this.state.loadingSecond/this.state.loadingTotal)*100)-10) + '%'
          }}>
            <p>{message}</p>
          </div>
          <div className="clearfix"></div>
        </div>);
    }
    let content = null;
    if(this.state.section === 'loading') {
      content = (
        <div className="wrapper">
          <div className="searchheader">
            <div className="container">
              <div className="searchheader-inner">
                <div className="initialoading">
                  <div className="dots">
                    <div className="dot dot1"></div>
                    <div className="dot dot2"></div>
                    <div className="dot dot3"></div>
                    <div className="dot dot4"></div>
                    <div className="dot5"></div>
                  </div>
                  <h2 className="initialoading-heading">One sec just loading...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.page === 'searches') {
      content = (<div className="wrapper">
        {banner}
      <div className="searchheader">
        <div className="searchheader-hero searchheader-hero-searches">
          <div className="searchheader-inner">
            <div className="container">
              
              <p className="searchheader-microchip-search-banner-heading">Do another search:</p>
              <form className="searchheader-microchip-search searchheader-microchip-search-center" onSubmit={this.handleSearch}>
                <input readOnly={this.state.submitting === true} type="text" ref={this.inputChipNumber} placeholder="8/10/15 digit number here" onChange={this.handleMicrochipTextUpdate} value={this.state.chipnumberfmt} />
                {
                    this.state.submitting === true 
                  ?
                    <button className="disabled">
                      <img alt="Loading Animation" src="https://cdn.chipndoodle.com/running.light.gif" />
                      <div className="clearfix"></div>
                    </button>
                  :
                    <button onClick={this.handleSearch}>
                      <span>SEARCH</span>
                      <div className="clearfix"></div>
                    </button>
                }
              </form>
              {submittingProgressBar}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  } else if(this.state.page === 'tag') {
    content = (<div className="wrapper">
      {banner}
    <div className="searchheader">
      <div className="searchheader-hero searchheader-hero-searches">
        <div className="searchheader-inner">
          <div className="container">
            
            <form className="searchheader-microchip-search searchheader-microchip-search-center searchheader-microchip-tag-center" onSubmit={this.handleSearch}>
              <input readOnly={this.state.submitting === true} type="text" ref={this.inputChipNumber} placeholder="tag number here" onChange={this.handleMicrochipTextUpdate} value={this.state.chipnumberfmt} />
              {
                  this.state.submitting === true 
                ?
                  <button className="disabled">
                    <img alt="Loading Animation" src="https://cdn.chipndoodle.com/running.light.gif" />
                    <div className="clearfix"></div>
                  </button>
                :
                  <button onClick={this.handleSearch}>
                    <span>SEARCH</span>
                    <div className="clearfix"></div>
                  </button>
              }
            </form>
            {submittingProgressBar}
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>);
} else {
        content = (<div className="wrapper">
          {banner}
        <div className="searchheader">
          <div className="searchheader-inner">
            <div className="container">
              
              <div className="searchheader-home-intro">
                <img alt="ChipnDoodle Logo" className="searchheader-home-logo" src="https://cdn.chipndoodle.com/logo512.png" />
                <h3 className="searchheader-home-headline">Universal Microchip Search</h3>
                <p className="searchheader-home-slogan">Search all registered providers for microchip details:</p>
                <div className="clearfix"></div>
                <form className="searchheader-microchip-search searchheader-microchip-search-home" onSubmit={this.handleSearch}>
                  <input readOnly={this.state.submitting === true} type="text" ref={this.inputChipNumber} placeholder="8/10/15 digit number here" onChange={this.handleMicrochipTextUpdate} value={this.state.chipnumberfmt} />
                  {
                      this.state.submitting === true 
                    ?
                      <button className="disabled">
                        <img alt="Loading Animation" src="https://cdn.chipndoodle.com/running.light.gif" />
                        <div className="clearfix"></div>
                      </button>
                    :
                      <button onClick={this.handleSearch}>
                        <span>SEARCH</span>
                        <div className="clearfix"></div>
                      </button>
                  }
                  <div className="clearfix"></div>
                </form>
                {submittingProgressBar}
                <div className="clearfix"></div>
              </div>
              
              <div className="searchheader-home-intro-actions">
                <div className="searchheader-home-intro-action">
                </div>
              </div>

              <div className="clearfix"></div>

            </div>
          </div>
        </div>
      </div>);
    }
    return (
      <div>
        {content}
        <ReCAPTCHA
          ref={this.inputCaptcha} 
          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
          theme="light" 
          size="invisible"
          onExpired={() => {}} 
          onErrored={() => {}} 
        />
      </div>
    );
  }
}

export default View;