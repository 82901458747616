import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      items:            [],
      locality:         params.locality,
      user:             params.user,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the call
    let response = await APIClient.call({

      path:   '/species',
      query:  {}

    });

    this.setState({

      section:      'loaded',
      items:        response.data || []

    });

  }
  render() {
    if(this.state.section === 'loading') {
      return (<div className="initialoading">
        <div className="dots">
          <div className="dot dot1"></div>
          <div className="dot dot2"></div>
          <div className="dot dot3"></div>
          <div className="dot dot4"></div>
          <div className="dot5"></div>
        </div>
        <h2 className="initialoading-heading">One sec just loading...</h2>
      </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
        <div className="wrapper">
          <div className="animals">
            <div className="container">
              <div className="animals-inner">

                {
                  this.state.items.map((item) => {
                    return <div>
                      <Link to={"/species/" + item.slug}>
                        <p>{item.name}</p>
                      </Link>
                    </div>;
                  })
                }

                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;