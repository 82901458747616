import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {
      
      user:         params.user,
      backToLogin:  false,
      menuSection:  params.section || '',
      section:      params.section || ''

    };
    this.handleUserChange = params.handleUserChange;
  }
  render() {
    /**
      <Link className={"usermenu-item" + (this.state.menuSection === 'animals' ? ' active' : '')} to="/animals">
        <span className="fas fa-paw"></span>
        <p>Animals</p>
        <div className="clearfix"></div>
      </Link>
     */
    const permissions = (this.state.user || {}).permissions || [];
    return (
      <div className="usermenu">
        <div className="container">
          <div className="usermenu-inner">
            <div className="usermenu-items">
              {
                  permissions.indexOf('admin') !== -1 || permissions.indexOf('features.animals') !== -1
                ?
                  <Link className={"usermenu-item" + (this.state.menuSection === 'animals' ? ' active' : '')} to="/animals">
                    <span className="fas fa-paw"></span>
                    <p>Animals</p>
                    <div className="clearfix"></div>
                  </Link>
                : 
                  null
              }
              <Link className={"usermenu-item" + (this.state.menuSection === 'searches' ? ' active' : '')} to="/searches">
                <span className="fas fa-search"></span>
                <p>Searches</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"usermenu-item" + (this.state.menuSection === 'groups' ? ' active' : '')} to="/groups">
                <span className="fas fa-user-friends"></span>
                <p>Groups</p>
                <div className="clearfix"></div>
              </Link>
              <Link className={"usermenu-item" + (this.state.menuSection === 'profile' ? ' active' : '')} to="/profile">
                <span className="fas fa-user"></span>
                <p>Profile</p>
                <div className="clearfix"></div>
              </Link>
              <div className="clearfix"></div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
