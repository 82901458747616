import React, { Component } from 'react'

import HeaderView from '../../widgets/header'
import FooterView from '../../widgets/footer'
import ReCAPTCHA from 'react-google-recaptcha'
import './style.css'
import browser from '../../browser'

import { RECAPTCHA_SITE_KEY } from '../../constants'
import APIClient from '../../client'

class View extends Component {
  constructor (params) {
    super(params)
    this.state = {
      locality: params.locality,
      user: params.user,
      item: null,
      signature: browser.getQueryParam('signature', '')
    }
    this.handleUserChange = params.handleUserChange
    this.pdfPreviewBlock = React.createRef()
    this.inputCaptcha = React.createRef()
  }
  componentDidMount = () => {
    document.title = 'Verify a Certificate'
  }
  performCaptha = async e => {
    if (e) e.preventDefault()
    this.inputCaptcha.current.execute()
    let value = null
    let attempts = 0
    while (!value) {
      attempts++
      value = this.inputCaptcha.current.getValue()
      if (attempts > 15) {
        return false
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
    this.inputCaptcha.current.reset()
    return value
  }
  handleVerifySubmit = async e => {
    if (e) e.preventDefault()
    if (this.state.submitting === true) return
    this.setState({ submitting: true })

    let captchacode = null
    try {
      captchacode = await this.performCaptha()
    } catch (err) {
      console.error(err)
      this.setState({
        submitting: false,
        errorMessage: 'Problem performing bot prevention checks'
      })
      return
    }

    this.setState({
      section: 'loading'
    })

    let postData = {
      code: captchacode,
      signature: this.state.signature
    }

    // get the token
    let token = APIClient.getToken()
    if (token) postData.token = token

    let result = null
    try {
      result = await APIClient.call({
        path: '/verify',
        method: 'post',
        form: postData
      })

      if (!result.ok) {
        this.setState({
          section: 'view',
          submitting: false,
          errorMessage: result.data.message || 'Something went wrong'
        })
        return
      }

      browser.openNewTab(result.data.url)

      this.setState({
        section: 'view',
        // item: result.data
      })
    } catch (err) {
      console.error(err)
      this.setState({
        section: 'view',
        submitting: false,
        errorMessage: 'Something went wrong, try again in a few seconds...'
      })
      return
    }
  }
  render () {
    if (this.state.section === 'preview') {
      return (
        <div>
          <HeaderView
            {...this.props}
            locality={this.state.locality}
            user={this.state.user}
            state={this.state}
          />
          <div className='wrapper'>
            <object
              data={this.state.item.url}
              type='application/pdf'
              ref={this.pdfPreviewBlock}
              title={'Preview of the certifcate'}
              className='verify-previewpdfframe'
            ></object>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>
      )
    }

    let content = null
    if (this.state.section === 'loading') {
      content = (
        <div className='initialoading-inside-wrapper'>
          <div className='initialoading'>
            <div className='dots'>
              <div className='dot dot1'></div>
              <div className='dot dot2'></div>
              <div className='dot dot3'></div>
              <div className='dot dot4'></div>
              <div className='dot5'></div>
            </div>
            <h2 className='initialoading-heading'>One sec...</h2>
          </div>
        </div>
      )
    } else {
      content = (
        <form method='POST' onSubmit={this.handleVerifySubmit}>
          <span className='fas fa-lock iconpreview'></span>
          <h2 className='verifyheading'>Verify a issued Certificate</h2>
          <input
            className='verifysiginput'
            placeholder={'Signature from the Certificate to check here ...'}
            type='text'
            value={this.state.signature}
            onChange={e => {
              this.setState({
                signature: e.target.value
              })
            }}
          />
          <button className='verifycta' onClick={this.handleVerifySubmit}>
            Check
          </button>
          <p className='verifyslogan'>
            Enter the Signature from the Certificate above and we will fetch the
            orginal as issued
          </p>
          <ReCAPTCHA
            ref={this.inputCaptcha}
            sitekey={RECAPTCHA_SITE_KEY}
            theme='light'
            size='invisible'
            onExpired={() => {}}
            onErrored={() => {}}
          />
        </form>
      )
    }
    return (
      <div>
        <HeaderView
          {...this.props}
          locality={this.state.locality}
          user={this.state.user}
          state={this.state}
        />
        <div className='wrapper'>
          {this.state.errorMessage ? (
            <div className='banner banner-hero'>
              <div className='container'>
                <p className='text'>{this.state.errorMessage}</p>
              </div>
            </div>
          ) : null}
          <div className='verify-page'>
            <div className='container'>
              <div className='verify-inner'>{content}</div>
            </div>
          </div>
        </div>
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    )
  }
}

export default View
