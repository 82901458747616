import React, { Component } from 'react';
import querystring from 'querystring';
import Dropzone from 'react-dropzone';
import superagent from 'superagent';
import './gallery.css';
import Select from 'react-select';
import CONSTANTS from '../../constants';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import moment from 'moment';

const CHIP_FORMAT_NUMBER = 5;

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: 44, borderRadius: 1, cursor: 'pointer' }),
  option: styles => ({ ...styles, cursor: 'pointer' }),
};

class App extends Component {
  constructor(params) {
    super(params);
    this.state = {

      group: params.group,
      item: params.record,
      user: params.user,
      locality: params.locality,
      section: 'loading',
      image: params.record.image || null,
      items: []

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {
    document.title = this.state.item.uid + ' Gallery';
    // set the call
    let uploadresults = null;

    // handle errors
    try {
      uploadresults = await APIClient.call({

        path: '/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/uploads',
        query: {
          token: await APIClient.getToken()
        }

      });
    } catch (err) {
      this.setState({
        section: 'error'
      })
      console.error(err);
      return;
    }

    this.setState({
      section: 'view',
      items: (uploadresults || {}).data || []
    })
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      errorMessage: null,
      section: 'uploading'

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?' + querystring.stringify({
        reason: 'records.image',
        token,
        record: this.state.item.uid,
        group: this.state.group.slug
      }));
      req.attach('file', files[0]);
      req.end(async (err, res) => {
        let data = JSON.parse(res.text);
        try {
          let response = await APIClient.call({
            path: '/groups/' + this.state.group.slug + '/records/' + this.state.item.uid + '/uploads',
            method: 'POST',
            form: {
              token: await APIClient.getToken(),
              upload: data.uid
            }
          })
        } catch (err) {
          console.error(err);
        }
        let uploaditems = [data].concat(this.state.items || []).slice(0, 9);
        const recorditem = this.state.item;
        recorditem.image = data.url;
        this.setState({
          image: data.url,
          items: uploaditems,
          section: 'view'
        })
      })
    });

  }
  renderHistory = () => {
    const items = this.state.items || [];
    return (<div className="grouprecord-photo-history">
      <h3 className="grouprecord-photo-history-heading">Previous {items.length} uploads</h3>
      <div className="clearfix"></div>
      <div className="grouprecord-photo-history-items">
        {
          items.map((item) => {
            return <a target="_blank" href={item.url} className="grouprecord-photo-history-item">
              <img src={item.url} />
              {/* <p>uploaded {moment.utc(item.created).local().format('LLL')}</p> */}
            </a>
          })
        }
        <div className="clearfix"></div>
      </div>
      <p className="grouprecord-photo-history-slogan">We will keep previous versions of the photos for easy access</p>
    </div>);
  }
  renderBlankHistory = () => {
    return (<div className="grouprecord-photo-history">
      <h3 className="grouprecord-photo-history-blank-heading">Start your gallery</h3>
      <p className="grouprecord-photo-history-blank-slogan">Upload any images that will allow users to see and keep track of their pet. These images are shown in the searches as well for easy identification.</p>
      <p className="grouprecord-photo-history-blank-slogan">Click on the image on the right to select your first image. The images cannot be delete for historical purposes.</p>
      <div className="clearfix"></div>
    </div>);
  }
  render() {
    if (this.state.section === 'loading') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-spinner"></span>
        <p>Loading...</p>
        <div className="clearfix"></div>
      </div>);
    }
    if (this.state.section === 'uploading') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-spinner"></span>
        <p>Uploading and processing, should be done in a second or two...</p>
        <div className="clearfix"></div>
      </div>);
    }
    if (this.state.section === 'error') {
      return (<div className="grouprecord-items-blank">
        <span className="fas fa-exclamation-triangle"></span>
        <p>Unable to pull gallery</p>
        <div className="clearfix"></div>
      </div>);
    }
    const items = this.state.items || [];
    return (<div className="grouprecord-photo-inner">
      <h2 className="grouprecord-photo-heading">Photo Gallery</h2>
      <div className="details-box-meta-column">
        <Dropzone ref="drop" multiple={false} onDrop={this.onDroppedFile}>
          {({ getRootProps, getInputProps }) => (
            <div onClick={(e) => {
              e.stopPropagation();
            }} className="grouprecord-photo-preview">
              <div {...getRootProps()}>
                <img alt="Image of Record" src={this.state.item.image || 'https://cdn.chipndoodle.com/defaults/record.png'} className="details-box-preview-img" />
                <p>Drop image or click to select image</p>
                <input {...getInputProps()} />
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="details-box-meta-column">
        {items.length > 0 ? this.renderHistory() : this.renderBlankHistory()}
      </div>
      <div className="clearfix"></div>

      <div className="clearfix"></div>
    </div>);
  }
}

export default App;
