import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import UserMenuView from '../../widgets/usermenu';
import FooterView from '../../widgets/footer';
import SearchView from '../../widgets/searchheader';
import './style.css';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {

      items:            [],
      locality:         params.locality,
      user:             params.user,
      section:          'loading'

    };
    this.handleUserChange = params.handleUserChange;
  }
  componentDidMount = async () => {

    // set the searches
    document.title = 'Searches';

    // get the token
    let token = APIClient.getToken();

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path:   '/searches',
        query:  {
          token
        }
  
      });
    } catch(err) {
      this.setState({
        section: 'error'
      });
      return;
    }

    if(result.ok !== true) {
      this.setState({
        section: 'notfound'
      });
      return;
    }

    this.setState({

      section:      'loaded',
      items:        result.data

    });

  }
  render() {
    let banner = null;
    let content = null;
    if(this.state.section === 'loading') {
      content = (<div className="initialoading-inside-wrapper">
        <div className="initialoading">
          <div className="dots">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div className="dot5"></div>
          </div>
          <h2 className="initialoading-heading">One sec just loading...</h2>
        </div>
      </div>);
    } else {
      let items = this.state.items || [];
      if(items.length > 0) {
        
        content = (<div>
          <SearchView user={this.state.user} {...this.props} page="searches"></SearchView>
          <div className="wrapper">
            <div className="searches">
              <div className="container">
                <div className="searches-inner">
                  <div className="searches-items">
                  {
                    items.map((item) => {
                      let address = [];
                      if(item.city) {
                        address.push(item.city.name);
                      }
                      if(item.region) {
                        address.push(item.region.name);
                      }
                      if(item.country) {
                        address.push(item.country.name);
                      }
                      return (<Link className="searches-item" key={item.uid} to={'/searches/' + item.uid}>
                        <div className="searches-item-meta">
                          <p className="searches-item-meta-number">{item.chipnumber}</p>
                          <p className="searches-item-meta-extra">Searched from {address.join(', ')}</p>
                        </div>
                        <div className="searches-item-counter">
                          <h2>{item.found}</h2>
                          <p>Results Found</p>
                        </div>
                        <div className="searches-item-counter">
                          <h2>{item.count}</h2>
                          <p>Databases Searched</p>
                        </div>
                        <div className="clearfix"></div>
                      </Link>);
                    })
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>);

      } else {

        content = (<div>
          <SearchView user={this.state.user} {...this.props} page="searches"></SearchView>
          <div className="wrapper">
            <div className="searches">
              <div className="container">
                <div className="searches-inner">
                  <p>No searches yet... Once you do your first search it will display here</p>
                </div>
              </div>
            </div>
          </div>
        </div>);

      }
    }
    return (
      <div>
        <HeaderView {...this.props} section='searches' locality={this.state.locality} user={this.state.user} state={this.state} />
        <UserMenuView user={this.state.user} section="searches"></UserMenuView>
        {banner}
        {content}
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;