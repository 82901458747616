import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIClient from '../../client';
import HeaderView from '../../widgets/header';
import FooterView from '../../widgets/footer';
import superagent from 'superagent';
import moment from 'moment';
import Dropzone from 'react-dropzone'
import ReCAPTCHA from 'react-google-recaptcha';
import JsBarcode from 'jsbarcode';
import {BrowserBarcodeReader} from '@zxing/library';
import './style.css';
const CONSTANTS = require('../../constants');

const DEFAULT_CHIPNUMBER = 'xxxxxxxxxxxxxx';

class View extends Component {
  constructor(params) {
    super(params);
    let isCameraFeatureAvailable = false;
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      isCameraFeatureAvailable = true;
    }
    this.state = {

      items:            [],
      locality:         params.locality,
      user:             params.user,
      section:          'loading',
      step:             'basic',
      code:             '',
      chipnumber:       '000000000000000',
      nickname:         '',
      sterilized:       false,
      injected:         new Date(),
      dob:              new Date(),
      name:             '',
      sex:              '',
      color:            '',
      species:          '',
      
      image:            null,
      portrait:         null,
      errorMessage:     null,
      featureCameras:   isCameraFeatureAvailable

    };
    this.handleUserChange = params.handleUserChange;
    this.inputChipNumber = React.createRef();
    this.inputCaptcha = React.createRef();
  }
  componentDidMount = async () => {

    // get the items
    // let tasks = [];

    // pull the species
    // tasks.push()

    // set the call
    // let result = await Promise.all(tasks);
    let aa = 1;
    let bb = 1;

    if(aa===bb) {

      this.setState({

        section:      'new',

      }, () => {
        this.renderBarCode();
      });

    } else {
      this.showDetails({
        "uid": "335251adec2d4ab28ff9bd00f4b67e1a",
        "url": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/preview.jpeg",
        "thumbnail": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/thumbnail.jpeg",
        "animals": [
          {
            "uid": "f1d8a23075d64d548d35aee37045efd7",
            "species": "dog",
            "breeds": [
              {
                "label": "beagle",
                "prob": 0.9169853925704956
              },
              {
                "label": "dachshund",
                "prob": 0.37709254026412964
              },
              {
                "label": "australian-cattle-dog",
                "prob": 0.08917909860610962
              },
              {
                "label": "harrier",
                "prob": 0.07679036259651184
              },
              {
                "label": "dalmatian",
                "prob": 0.07332426309585571
              }
            ],
            "sex": "female",
            "age": 93,
            "image": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/f1d8a23075d64d548d35aee37045efd7.jpeg"
          },
          {
            "uid": "01b96cd0a8ea4436a8dba9155dfa5253",
            "species": "dog",
            "breeds": [
              {
                "label": "beagle",
                "prob": 0.9997130036354065
              },
              {
                "label": "harrier",
                "prob": 0.08718594908714294
              },
              {
                "label": "basset-fauve-de-bretagne",
                "prob": 0.07935759425163269
              },
              {
                "label": "norman-artesien-basset",
                "prob": 0.07428792119026184
              },
              {
                "label": "redbone_coonhound",
                "prob": 0.06869031488895416
              }
            ],
            "sex": "male",
            "age": 78,
            "image": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/01b96cd0a8ea4436a8dba9155dfa5253.jpeg"
          },
          {
            "uid": "f47c377b4abd4054a02b6e7f7b9b5eb2",
            "species": "dog",
            "breeds": [
              {
                "label": "beagle",
                "prob": 0.9969730377197266
              },
              {
                "label": "harrier",
                "prob": 0.08214518427848816
              },
              {
                "label": "basset-fauve-de-bretagne",
                "prob": 0.07046547532081604
              },
              {
                "label": "biewer-terrier",
                "prob": 0.0682600736618042
              },
              {
                "label": "norman-artesien-basset",
                "prob": 0.06635302305221558
              }
            ],
            "sex": "female",
            "age": 78,
            "image": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/f47c377b4abd4054a02b6e7f7b9b5eb2.jpeg"
          },
          {
            "uid": "cb8eb97d450c4e99a575c3b20810c77e",
            "species": "dog",
            "breeds": [
              {
                "label": "beagle",
                "prob": 0.9989016652107239
              },
              {
                "label": "norman-artesien-basset",
                "prob": 0.09378188848495483
              },
              {
                "label": "harrier",
                "prob": 0.09198334813117981
              },
              {
                "label": "basset-fauve-de-bretagne",
                "prob": 0.08815369009971619
              },
              {
                "label": "redbone_coonhound",
                "prob": 0.07538047432899475
              }
            ],
            "sex": "male",
            "age": 78,
            "image": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/cb8eb97d450c4e99a575c3b20810c77e.jpeg"
          }
        ]
      }, {
        "uid": "f47c377b4abd4054a02b6e7f7b9b5eb2",
        "species": "dog",
        "breeds": [
          {
            "label": "beagle",
            "prob": 0.9969730377197266
          },
          {
            "label": "harrier",
            "prob": 0.08214518427848816
          },
          {
            "label": "basset-fauve-de-bretagne",
            "prob": 0.07046547532081604
          },
          {
            "label": "biewer-terrier",
            "prob": 0.0682600736618042
          },
          {
            "label": "norman-artesien-basset",
            "prob": 0.06635302305221558
          }
        ],
        "sex": "female",
        "age": 78,
        "image": "https://uploads.chipndoodle.com/2020/05/02/19/335251adec2d4ab28ff9bd00f4b67e1a/f47c377b4abd4054a02b6e7f7b9b5eb2.jpeg"
      });
    }
  }
  onChallengeLoaded = () => {
    this.setState({
      submitting: false
    })
  }
  onChallengeExpired = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge expired, try again'
    })

  }
  onChallengeErrored = () => {

    this.setState({
      submitting:     false,
      errorMessage:   'Challenge error, try again'
    })

  }
  performCaptha = async (e) => {
    if(e) e.preventDefault();
    this.inputCaptcha.current.execute();
    let value = null;
    let attempts = 0;
    while(!value) {
      attempts++;
      value = this.inputCaptcha.current.getValue();
      if(attempts > 10) {
        return false;
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
    this.inputCaptcha.current.reset();
    return value;
  }
  renderBarCode = () => {
    if(!this.refs.barcode) return;
    JsBarcode(this.refs.barcode, DEFAULT_CHIPNUMBER, {
      lineColor: "#111",
      width: 2.5,
      height: 40,
      displayValue: false
    });
  }
  showDetails = async (image, portrait) => {

    let species = portrait.species;
    let dob = moment().subtract(portrait.age || 0, 'days').toDate();
    let sex = portrait.sex || 'male';
    let breeds = [];
    let confidentbreeds = [];
    let allPossibleBreeds = [];

    for(let i = 0; i < portrait.breeds.length; i++) {
      let breedPrediction = portrait.breeds[i];
      if(breedPrediction.prob > 0.8) {
        breeds.push(breedPrediction.label);
      }
      if(breedPrediction.prob > 0.5) {
        confidentbreeds.push(breedPrediction.label);
      }
      allPossibleBreeds.push(breedPrediction.label);
    }
    if(confidentbreeds.length === 0) {
      for(let i = 0; i < portrait.breeds.length; i++) {
        confidentbreeds.push(portrait.breeds[i].label);
        if(i > 2) break;
      }
    }
    
    this.setState({
      species,
      dob,
      sex,
      confidentBreeds: confidentbreeds,
      allBreeds: allPossibleBreeds,
      breeds:   breeds,
      portrait,
      image,
      section: 'details',
    }, () => {
    })
  }
  onDroppedFile = (files) => {

    // get the token
    let token = APIClient.getToken();

    // set initial state
    this.setState({

      section:        'uploading',
      errorMessage:   null

    }, () => {

      const req = superagent.post(APIClient.getBaseAPIURL() + '/uploads?reason=animals.add&token=' + token);
      req.attach('file', files[0]);
      req.end((err, res) => {
        let data = JSON.parse(res.text);
        let animals = data.animals || [];
        if(animals.length === 0) {
          this.setState({
            errorMessage:   'No animals in that picture...',
            section:        'image'
          });
        } else if(animals.length > 1) {
          this.setState({
            section:    'portraits',
            image:      data,
            portrait:   null
          })
        } else {
          this.showDetails(data, animals[0]);
        }
      })
    });

  }
  handleOpenCameraScanner = async (e) => {
    if(e) e.preventDefault();
    // check we could list all the devices
    try {
      let videoInputDevices = await this.codeReader.listVideoInputDevices();
      if(videoInputDevices.length === 0) {
        this.setState({
          errorMessage: "No cameras found on device, please plug them in and try again"
        });
        return;
      }
    } catch(err) {}
    if(this.state.featureCameras === false) {
      this.setState({
        errorMessage: "Camera not supported on this device"
      });
      return;
    }
    this.setState({
      section: 'scan',
      errorMessage: null
    }, async () => {

      // reset the reader
      let codeReader = new BrowserBarcodeReader();
      codeReader
      .decodeOnceFromVideoDevice(null, this.refs.barcodescannervideo)
      .then((result) => {
        codeReader.reset();
        this.setState({
          chipnumber: result.text
        }, () => {
          this.handleNewChipSubmit(result.text);
        })
      })
      .catch((err) => {
        console.error(err);
        codeReader.reset();
        this.setState({
          errorMessage: "Please enable camera permission to use this feature",
          section: 'new'
        }, this.renderBarCode);
      });
    });
  }
  handleNewChipSubmit = async (chipnumber) => {

    // set to loading
    this.setState({
      section:        'loading',
      errorMessage:   null,
      chipnumber:     chipnumber || this.inputChipNumber.current.value || this.state.chipnumber
    }, async () => {

      // handle it
      const code = await this.performCaptha();
  
      // could we get the code
      if(code === false) {
        this.setState({
          section: 'new',
          errorMessage: 'Problem getting your verify code'
        });
        return;
      }

      // get the token
      let token = APIClient.getToken();

      // set the call
      let result = null;

      // handle errors
      try {
        result = await APIClient.call({
          method: 'post',
          path:   '/check',
          form:   {
            uid:    this.state.chipnumber,
            token:  token,
            code:   code
          },
        });
      } catch(err) {
        this.setState({
          section: 'new',
          errorMessage: err.message
        }, () => {
          this.renderBarCode();
        });
        return;
      }

      let section = 'image';
      let errorMessage = null;
      if(result.data.result === 'waiting') {
        section = 'waiting';
      } else if(result.data.result === 'registered') {
        section = 'registered';
      } else if(result.data.result === 'available') {
        section = 'image';
      } else {
        section = 'new';
        errorMessage = result.data.message || 'Something went wrong, please try again...';
      }

      this.setState({

        section, errorMessage

      }, () => {
        this.renderBarCode();
      });

    });

  }
  handleFinalSubmit = () => {
    // set to loading
    this.setState({
      section:        'loading',
      errorMessage:   null,
    }, async () => {

      // handle it
      const code = await this.performCaptha();
  
      // could we get the code
      if(code === false) {
        this.setState({
          section: 'details',
          errorMessage: 'Problem getting your verify code'
        });
        return;
      }

      // get the token
      let token = APIClient.getToken();

      // set the call
      let result = null;

      // handle errors
      try {
        result = await APIClient.call({
          method: 'post',
          path:   '/animals',
          form:   {
            token,
            code,
            uid: this.state.chipnumber,
            species: this.state.species,
            image: this.state.portrait.uid,
            breeds: this.state.breeds.join(','),
            dob: this.state.dob,
            color: this.state.color,
            sex: this.state.sex,
          },
        });
      } catch(err) {
        this.setState({
          section: 'details',
          errorMessage: err.message
        }, () => {
          this.renderBarCode();
        });
        return;
      }

      if(result.ok !== true) {
        this.setState({
          section: 'details',
          errorMessage: result.data.message || 'Could not create pet'
        })
      }
      
      this.props.history.push('/animals/' + this.state.chipnumber);

    });
  }
  render() {
    let banner = null;
    if(this.state.errorMessage !== null) {
      banner = (<div className="banner banner-hero">
        <div className="container">
          <p className="text">{this.state.errorMessage}</p>
        </div>
      </div>);
    }
    let content = null;
    if(this.state.section === 'loading') {
      return (
        <div className="wrapper initialoading-wrapper-bg">
          <HeaderView {...this.props} locality={this.state.locality}  user={this.state.user} state={this.state} />
          <div className="initialoading-inside-wrapper">
            <div className="initialoading">
              <div className="dots">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot5"></div>
              </div>
              <h2 className="initialoading-heading">One sec just loading...</h2>
            </div>
          </div>
          <FooterView locality={this.state.locality} state={this.state} />
        </div>);
    } else if(this.state.section === 'uploading') {
      content = (
        <div className="wrapper">
          <div className="add">
            <div className="container">
              <div className="add-inner">
                <div className="initialoading">
                  <div className="dots">
                    <div className="dot dot1"></div>
                    <div className="dot dot2"></div>
                    <div className="dot dot3"></div>
                    <div className="dot dot4"></div>
                    <div className="dot5"></div>
                  </div>
                  <h2 className="initialoading-heading">Uploading your picture, one moment...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'scan') {
      content = (
        <div className="wrapper wrapper-alt">
          <div className="add">
            <div className="container">
              <div className="add-inner">

                <h2 className="add-microchip-heading">Scan your Barcode</h2>
                <p className="add-microchip-slogan">Position your barcode in the middle of the video below</p>
                <video className="add-microchip-barcode-video" ref="barcodescannervideo"></video>
                <p className="add-microchip-tagline">We'll pick it up and go from there :)</p>

                <Link to="/add" onClick={() => {
                  this.setState({
                    section: 'new'
                  }, this.renderBarCode);
                }} className="add-microchip-cta-button">Back to Manual Entry</Link>

              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'new') {
      content = (
        <div className="wrapper wrapper-alt">
          <div className="add">
            <div className="container">
              <div className="add-inner">

                <h2 className="add-microchip-heading">Please supply your microchip number</h2>
                <p className="add-microchip-slogan">No matter where you bought your micrchip, it should have a barcode like the following:</p>

                <div onClick={this.handleOpenCameraScanner} className="add-microchip-package">
                  <div className="add-microchip-package-inner">
                    <p className="add-microchip-package-barcode-text">Microchip for Animal</p>
                    <svg className="add-microchip-package-barcode" ref="barcode"></svg>
                    <p className="add-microchip-package-barcode-text">{DEFAULT_CHIPNUMBER}</p>
                  </div>
                </div>
                <p className="add-microchip-tagline">Should either 10 or 15 numbers long</p>

                <div className="add-microchip-divider"></div>

                <form className="add-microchip-search">
                  <input type="text" ref={this.inputChipNumber} placeholder="10/15 digit number here" defaultValue={this.state.chipnumber} onChange={(text) => {}} />
                  <button onClick={this.handleOpenCameraScanner}>
                    <span className="fas fa-camera"></span>
                  </button>
                  <div className="clearfix"></div>
                </form>
                <div className="clearfix"></div>
                <Link to="/add" onClick={() => {
                  this.handleNewChipSubmit();
                }} className="add-microchip-cta-button">Check Availability</Link>
                <div className="clearfix"></div>

              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'portraits') {
      content = (
        <div className="wrapper">
          <div className="add">
            <div className="container">
              <div className="add-inner">

                <h2 className="add-microchip-heading">Looks like a wild party</h2>
                <p className="add-microchip-slogan">We found multiple pets, which one is implanted with this chip?</p>
                <div className="add-microchip-portraits">
                  {
                    this.state.image.animals.map((item) => {
                      return <Link className="add-microchip-portrait" onClick={() => {
                        this.showDetails(this.state.image, item);
                      }}>
                        <img alt={"Image of " + this.state.microchip} src={item.image || item.url} />
                      </Link>
                    })
                  }
                </div>
                <div className="clearfix"></div>
                <p className="add-microchip-tagline">Invite us next time too ;)</p>
                <Link to="/add" onClick={() => {
                  this.setState({
                    section: 'image'
                  }, this.renderBarCode);
                }} className="add-microchip-cta-button add-microchip-cta-button-alt">CHANGE PHOTO</Link>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'image') {
      content = (
        <div className="wrapper">
          <div className="add">
            <div className="container">
              <div className="add-inner">

                  <h2 className="add-microchip-heading">Upload or Take a photo</h2>
                  <p className="add-microchip-slogan">Upload a photo fo the pet in question</p>
                  <Dropzone multiple={false} onDrop={this.onDroppedFile}>
                    {({getRootProps, getInputProps}) => (
                      <div className="add-microchip-take-image">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <img alt="Default Animal" src="https://cdn.chipndoodle.com/defaults/provider.png" />
                          <p className="add-microchip-take-image-instructions">Drag 'n' drop some files here, or click to select files</p>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <p className="add-microchip-tagline">Tell them to smile big :)</p>

                  <Link style={{
                    visibility: 'hidden'
                  }} to="/add" onClick={() => {
                    if(window.confirm("Really skip? Pictures help people match the dog to the one they found when lost. Still continue without?") !== true) return;
                    this.setState({
                      section: 'details'
                    }, this.renderBarCode);
                  }} className="add-microchip-cta-button add-microchip-cta-button-alt">SKIP</Link>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'existing') {
      content = (
        <div className="wrapper">
          <div className="add">
            <div className="container">
              <div className="add-inner">
                <p>Existing Microchip, please request ownership if this is your chip</p>
                <Link to="/add" onClick={() => {
                  this.setState({
                  }, this.renderBarCode);
                }} className="add-microchip-cta-button add-microchip-cta-button-alt">REQUEST OWNER TRANSFER</Link>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'waiting') {
      content = (
        <div className="wrapper">
          <div className="add">
            <div className="container">
              <div className="add-inner">
                <p>You already have a pending request for ownership, please wait</p>
                <Link to="/add" onClick={() => {
                  this.setState({
                  }, this.renderBarCode);
                }} className="add-microchip-cta-button add-microchip-cta-button-alt">REQUEST OWNER TRANSFER</Link>
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'details' && this.state.step === 'basic') {
      content = (
        <div className="wrapper">
          <div className="add add-alt">
            <div className="add-hero">
              <h2 className="add-hero-heading">That's a good looking <strong>{this.state.species}</strong>!</h2>
              <h2 className="add-hero-slogan">We need a bit of information to get them registered</h2>
            </div>
            <div className="container">
              <div className="add-inner">

                <div className="add-details-box">
                  <div className="add-details-box-preview">
                    <p>Looking Good :)</p>
                    <img alt="Portrait of Animal" src={this.state.portrait.image} />
                    <p>#{this.state.chipnumber}</p>
                  </div>
                  <div className="add-details-box-meta">

                    <div className="add-details-box-controls">

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Nickname</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Sex</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <div className="add-details-box-control-selectables">
                            <Link onClick={() => {
                              this.setState({
                                sex: 'male'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.sex === "male" ? " active" : "")}>MALE</Link>
                            <Link onClick={() => {
                              this.setState({
                                sex: 'female'
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.sex === "female" ? " active" : "")}>FEMALE</Link>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control">
                        <div className="add-details-box-control-label">
                          <p>select all you think apply</p>
                          <label htmlFor="nickname">Breed</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <div className="add-details-box-control-selectables">
                            {
                              this.state.allBreeds.map((label) => {
                                let isActive = this.state.breeds.indexOf(label) !== -1;
                                return <Link key={"breed" + label} className={"add-details-box-control-selectable " + (isActive === true ? " active" : "")} onClick={() => {
                                  let selectedBreeds = [].concat(this.state.breeds);
                                  if(isActive === true) {
                                    selectedBreeds.splice(selectedBreeds.indexOf(label), 1)
                                  } else if(selectedBreeds.indexOf(label)) {
                                    selectedBreeds.push(label);
                                  }
                                  this.setState({
                                    breeds: selectedBreeds
                                  })
                                }}>{label}</Link>
                              })
                            }
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Describe their coat color</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" placeholder="" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Date of Birth</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                        <input type="date" defaultValue={moment(this.state.dob).format('YYYY-MM-DD')} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                    </div>
                    <div className="add-details-box-toolbar">
                      <Link className="add-details-box-toolbar-action add-details-box-toolbar-action-right" onClick={() => {
                        this.setState({
                          step: 'medical'
                        })
                      }}>NEXT</Link>
                      <p className="add-details-box-toolbar-text">Basic Information (1/{this.state.group ? '3' : '2'})</p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>);
    } else if(this.state.section === 'details' && this.state.step === 'medical') {
      content = (
        <div className="wrapper">
          <div className="add add-alt">
            <div className="add-hero">
              <h2 className="add-hero-heading">That's a good looking <strong>{this.state.species}</strong>!</h2>
              <h2 className="add-hero-slogan">We need a bit of information to get them registered</h2>
            </div>
            <div className="container">
              <div className="add-inner">

                <div className="add-details-box">
                  <div className="add-details-box-preview">
                    <p>Looking Good :)</p>
                    <img alt="Portrait of animal" src={this.state.portrait.image} />
                    <p>#{this.state.chipnumber}</p>
                  </div>
                  <div className="add-details-box-meta">

                    <div className="add-details-box-controls">

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Practice Name</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Name of Injector</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">E-Mail of Injector</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Tel of Injector</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <input type="text" ref={this.inputNickname} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>
                      
                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Date of Injection</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                        <input type="date" defaultValue={moment(this.state.injected).format('YYYY-MM-DD')} />
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="add-details-box-control add-details-box-control-half">
                        <div className="add-details-box-control-label">
                          <label htmlFor="nickname">Are they Sterilized?</label>
                        </div>
                        <div className="add-details-box-control-inputs">
                          <div className="add-details-box-control-selectables">
                            <Link onClick={() => {
                              this.setState({
                                sterilized: true
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.sterilized === true ? " active" : "")}>YES</Link>
                            <Link onClick={() => {
                              this.setState({
                                sterilized: false
                              })
                            }} className={"add-details-box-control-selectable" + (this.state.sterilized === false ? " active" : "")}>NO</Link>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>

                      <div className="clearfix"></div>

                      <div className="add-details-box-control">
                        <p>We either need the Injector's ID Number or Registered Practice Number of the VET or Member Organization No.</p>
                      </div>

                    </div>
                    <div className="add-details-box-toolbar">
                      {
                        this.state.group ? 
                        <Link className="add-details-box-toolbar-action add-details-box-toolbar-action-right" onClick={() => {
                          this.setState({
                            step: 'owner'
                          });
                        }}>NEXT</Link>
                        :
                        <Link className="add-details-box-toolbar-action add-details-box-toolbar-action-right" onClick={() => {
                          this.handleFinalSubmit();
                        }}>FINISH</Link>
                      }
                      
                      <Link className="add-details-box-toolbar-action add-details-box-toolbar-action-right" onClick={() => {
                        this.setState({
                          step: 'basic'
                        });
                      }}>BACK</Link>
                      <p className="add-details-box-toolbar-text">Medical Information (2/{this.state.group ? '3' : '2'})</p>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>);
    } else {
      content = (
        <div className="wrapper wrapper-alt">
          <div className="add">
            <div className="container">
              <div className="add-inner">

                <input type="text" />

              </div>
            </div>
          </div>
        </div>);
    }
    return (
      <div>
        <HeaderView {...this.props} locality={this.state.locality} section="add"  user={this.state.user} state={this.state} />
        {banner}
        {content}
        <ReCAPTCHA
          ref={this.inputCaptcha} 
          sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} 
          theme="light" 
          size="invisible"
          onExpired={this.onChallengeExpired} 
          onErrored={this.onChallengeErrored} 
        />
        <FooterView locality={this.state.locality} state={this.state} />
      </div>
    );
  }
}

export default View;